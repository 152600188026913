export const multiSelectStyle = {
  searchBox: {
    background: '#13132c',
    borderRadius: '8px',
    border: 'none',
  },
  inputField: {
    color: '#d6d6ff',
    margin: '2px 12px',
    fontSize: '20px',
  },
  chips: {
    background: '#363659',
    borderRadius: '8px',
    border: '1px solid #d6d6ff',
    color: '#d6d6ff',
    fontSize: '16px',
  },
  optionContainer: {
    background: '#13132c',
    border: '1.5px solid white',
  },
  option: {
    color: '#d6d6ff',
  },
};
