import React, { useEffect, useState } from 'react';
import ModalForm from '../../common/ModalForm';
import { User } from '../../../types/model/user';
import { TabData } from '../../common/Tab';
import LineDetailsForm from '../details/LineDetailsForm';
import LineBouquetsForm from '../bouquets/LineBouquetsForm';
import FormTab from '../../common/FormTab';
import LineSecurity from '../security/LineSecurityTab';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'Details',
    tabComponent: LineDetailsForm,
  },
  {
    id: 2,
    tabTitle: 'Bouquets',
    tabComponent: ({ ...props }) => LineBouquetsForm({ ...props, isEdit: true }),
  },
  {
    id: 3,
    tabTitle: 'Security',
    tabComponent: ({ ...props }) => LineSecurity({ ...props, isEdit: true }),
  },
];

interface Props {
  user: User;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (user: User) => void;
}

const EditLineModal = ({ user, isOpen, onClose, onSubmit }: Props) => {
  const [lineData, setLineData] = useState<User>(user);
  useEffect(() => {
    setLineData(user);
  }, [user]);

  const submitLineData = async (data: User) => {
    onSubmit({ ...data, outputs: [] });
  };
  return (
    <ModalForm className='narrow-modal-form' isOpen={isOpen} onClose={onClose} title={'Edit Line'}>
      <FormTab
        data={tabData}
        formData={lineData}
        currentTab={tabData[0].id}
        setFormData={setLineData}
        submitFormData={submitLineData}
        role='edit'
      />
    </ModalForm>
  );
};

export default EditLineModal;
