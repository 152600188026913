import React from 'react';
import Tab, { TabData } from './Tab';

interface Props {
  className?: string;
  data: TabData[];
  currentTab: number;
  onChangeTab?: (id: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFormData?: React.Dispatch<React.SetStateAction<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitFormData?: (data: any) => void;
  role?: 'add' | 'edit';
}

const FormTab = ({
  className,
  data,
  currentTab,
  onChangeTab,
  formData,
  setFormData,
  submitFormData,
  role = 'add',
}: Props) => {
  return (
    <div className={className}>
      <Tab
        data={data}
        currentTab={currentTab}
        onChangeTab={onChangeTab}
        formData={formData}
        setFormData={setFormData}
        submitFormData={submitFormData}
        className='w-full m-0 mt-[20px]'
        isAllowTabClick={true}
        role={role}
        tabTitleBarStyle='w-full rounded-lg bg-dark flex py-[1px] px-4 flex-row justify-around items-center text-center cursor-pointer [box-shadow:inset_0px_4px_4px_rgba(0,0,0,0.25)]'
        tabTitleStyle='py-[10px] px-[14px] text-[16px] rounded-lg hover:bg-[radial-gradient(100%_100%_at_50.63%_0%,rgba(151,71,255,0.16)_0%,rgba(151,71,255,0)_100%),radial-gradient(_45.01%_84.62%_at_50.63%_100%,rgba(151,_71,_255,_0.6)_0%,rgba(151,71,255,_0)_100%),rgba(255,255,255,0.1)] hover:[box-shadow:0px_4px_7px_rgba(0,0,0,0.47),_0px_5px_11px_rgba(151,71,255,0.22)] active:bg-[radial-gradient(50.08%_76.92%_at_50.63%_0%,#000000_0%,rgba(0,0,0,0)_100%),rgba(61,60,60,0.2)] active:[box-shadow:inset_0px_9px_10px_rgba(0,0,0,0.25)]'
        tabTitleActiveStyle='bg-[radial-gradient(_45.01%_84.62%_at_50.63%_100%,_rgba(151,71,255,0.6)_0%,_rgba(151,71,255,0)_100%_)] [filter:drop-shadow(0px_4px_4px_rgba(0,0,0,0.07))]'
        tabContentStyle='w-full m-0 mt-[8px]'
      />
    </div>
  );
};

export default FormTab;
