import { useEffect } from 'react';
import { startStreams } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { useStreams } from './useStreams';
import { isAxiosError } from 'axios';
import { useAtomValue } from 'jotai';
import { streamsAtom } from '../../store/jotai';

export const useStartStreams = () => {
  const streams = useAtomValue(streamsAtom);
  const { getAllStreams, loading: loadingStreams } = useStreams(false);
  const [_startStreams, loading, _startStreamsRes, _startStreamsErr] = useRequest(startStreams);

  useEffect(() => {
    if (_startStreamsRes) {
      console.log('_startStreamRes: ', _startStreamsRes);
      getAllStreams(streams.CurrentPage, streams.CountPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_startStreamsRes]);
  useEffect(() => {
    if (_startStreamsErr) {
      if (isAxiosError(_startStreamsErr)) {
        console.log('_startStreamErrText: ', _startStreamsErr.response?.data.error);
        console.log('_startStreamErrMessage: ', _startStreamsErr.response?.data.message);
      } else {
        console.log('_startStreamErr: ', _startStreamsErr);
      }
    }
  }, [_startStreamsErr]);
  return {
    startStreams: _startStreams,
    loading: loadingStreams || loading,
    startStreamsErr: _startStreamsErr,
  };
};
