import { useEffect } from 'react';
import { deleteUser } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { useLines } from './useLines';
import { isAxiosError } from 'axios';
import { useSetAtom } from 'jotai';
import { loadingAtom } from '../../store/jotai';

export const useDeleteLine = () => {
  const { getAllUsers } = useLines(false);
  const setLoading = useSetAtom(loadingAtom);
  const [_deleteUser, , _deleteUserRes, _deleteUserErr] = useRequest(deleteUser);

  useEffect(() => {
    if (_deleteUserRes) {
      // Refetch All users
      getAllUsers();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_deleteUserRes]);

  useEffect(() => {
    if (_deleteUserErr) {
      if (isAxiosError(_deleteUserErr)) {
        console.log('_deleteUserErrText: ', _deleteUserErr.response?.data.error);
        console.log('_deleteUserErrMessage: ', _deleteUserErr.response?.data.message);
      } else {
        console.log('_deleteUserErr: ', _deleteUserErr);
      }
    }
  }, [_deleteUserErr]);
  return {
    deleteLine: (data: unknown) => {
      setLoading(true);
      return _deleteUser(data);
    },
    deleteUserRes: _deleteUserRes,
    deleteUserErr: _deleteUserErr,
  };
};
