/* eslint-disable react-hooks/exhaustive-deps */
import { useSetAtom } from 'jotai';
import { connectionsAtom, loadingAtom } from '../../store/jotai';
import { useEffect, useRef } from 'react';
import useRequest from '../../lib/hooks/useRequest';
import { getAllConnections } from '../../lib/api';
import { isAxiosError } from 'axios';
import { Connection } from '../../types/model/user';

export const useConnections = (prefetch = true) => {
  const setConnections = useSetAtom(connectionsAtom);
  const pageRef = useRef(1);
  const limitRef = useRef(1);
  const setLoading = useSetAtom(loadingAtom);

  const [_getAllConnections, loading, _getAllConnectionsRes, _getAllConnectionsErr] = useRequest(
    (page = 1, limit = 10, params: Record<string, string> = {}) => {
      pageRef.current = page;
      limitRef.current = limit;
      setLoading(true);
      return getAllConnections(page, limit, params);
    },
  );

  useEffect(() => {
    if (_getAllConnectionsRes) {
      setLoading(false);
      setConnections({
        Records: _getAllConnectionsRes.Records as Connection[],
        TotalPages: _getAllConnectionsRes.TotalPages,
        TotalRecords: _getAllConnectionsRes.TotalRecords,
        CurrentPage: pageRef.current,
        CountPerPage: limitRef.current,
      });
    }
  }, [_getAllConnectionsRes, setConnections]);

  useEffect(() => {
    if (prefetch) _getAllConnections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (_getAllConnectionsErr) {
      setLoading(false);
      if (isAxiosError(_getAllConnectionsErr)) {
        console.log('_getAllConnectionsErrText: ', _getAllConnectionsErr.response?.data.error);
        console.log('_getAllConnectionsErrMessage: ', _getAllConnectionsErr.response?.data.message);
      } else {
        console.log('_getAllConnectionsErr: ', _getAllConnectionsErr);
      }
    }
  }, [_getAllConnectionsErr]);
  return {
    getAllConnections: _getAllConnections,
    loading,
  };
};
