import React from 'react';
import ModalForm from '../../common/ModalForm';
import DownloadPlaylistForm from '../DownloadPlaylistForm';
import { User } from '../../../types/model/user';

interface Props {
  user: User;
  isOpen: boolean;
  onClose: () => void;
}

const DownloadPlaylistModal = ({ user, isOpen, onClose }: Props) => {
  return (
    <ModalForm
      className='max-w-[35%] w-[600px] overflow-visible'
      isOpen={isOpen}
      onClose={onClose}
      title={'Download Playlist'}
    >
      <DownloadPlaylistForm user={user} />
    </ModalForm>
  );
};

export default DownloadPlaylistModal;
