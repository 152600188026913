import React from 'react';
import { NextArrowSvg, PrevArrowSvg } from '../../assets/svg';

interface Props {
  page: number;
  totalRecords: number;
  pageSize: number;
  setPage?: (page: number) => void;
}
const Paginator = ({ page, totalRecords, pageSize, setPage }: Props) => {
  const totalPages = Math.ceil(totalRecords / pageSize);
  const from = (page - 1) * pageSize + 1;
  const to = Math.min(page * pageSize, totalRecords);
  const onPrevClick = () => {
    setPage && setPage(Math.max(page - 1, 1));
  };
  const onNextClick = () => {
    setPage && setPage(Math.min(page + 1, totalPages));
  };
  return (
    <div className='w-full mt-6 flex justify-between items-center gap-4'>
      <div className='text-[16px] text-form-content'>
        Showing {from} to {to} of {totalRecords} entries
      </div>
      <div className='flex justify-between items-center gap-[6px]'>
        <button className='shrink-0 w-6 h-7 flex items-center justify-center cursor-pointer bg-table-odd-row rounded-lg hover:opacity-80 focus:[border:1px_solid_white] active:opacity-70 active:[box-shadow:0px_2px_13px_0px_#1e1e3f]' onClick={onPrevClick}>
          <PrevArrowSvg />
        </button>
        <div className='shrink-0 h-7 py-0 px-[22px] flex justify-center items-center bg-table-odd-row rounded-lg text-[16px]'>
          {page} of {totalPages}
        </div>
        <button className='shrink-0 w-6 h-7 flex items-center justify-center cursor-pointer bg-table-odd-row rounded-lg hover:opacity-80 focus:[border:1px_solid_white] active:opacity-70 active:[box-shadow:0px_2px_13px_0px_#1e1e3f]' onClick={onNextClick}>
          <NextArrowSvg />
        </button>
      </div>
    </div>
  );
};


export default Paginator;
