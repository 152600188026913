/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import { CommonSelectItem } from '../../types/model/commonSelectItem';
import FormTitle from '../common/FormTitle';
import ManageTable from '../common/ManageTable';
import { CellProps, Column } from 'react-table';
import { headerSelectStyles } from '../../styles/reactSelect';
import Popup from 'reactjs-popup';
import {
  AudioSvg,
  FpsSvg,
  RunPlayerSvg,
  SpeedSvg,
  StopPlayerSvg,
  VideoSvg,
} from '../../assets/svg';
import { MiddleButton } from '../common/Buttons';
import Paginator from '../common/Paginator';
import { Stream } from '../../types/model/stream';
import ModalWarning from '../common/ModalWarning';
import { PopupActions } from 'reactjs-popup/dist/types';
import { getCategoriesOptions } from './details/Categories';
import HLSPlayerModal from './modal/HLSPlayerModal';
import { useAtomValue } from 'jotai';
import { categoriesAtom, streamsAtom } from '../../store/jotai';
import EditStreamModal from './modal/EditStreamModal';
import { useStreams } from '../../hooks/stream/useStreams';
import { useStartStream } from '../../hooks/stream/useStartStream';
import { useStopStream } from '../../hooks/stream/useStopStream';
import { useDeleteStream } from '../../hooks/stream/useDeleteStream';
import { useEditStream } from '../../hooks/stream/useEditStream';
import toast from 'react-hot-toast';
import { useDeleteStreams, useStartStreams, useStopStreams } from '../../hooks/stream';
import { cn } from '../../utils/tw-merge';
import { baseURL } from '../../lib/api';
import intervalToDurationStr from '../../utils/intervalToDurationStr';

const deleteMessage =
  'Are you sure you want to delete this stream? All connections will be deleted.';

const serverFilterOptions: CommonSelectItem[] = [
  { value: 'allServers', label: 'All Servers' },
  { value: 'noServers', label: 'No Servers' },
  { value: 'mainServers', label: 'Main Servers' },
];

const onlineFilterOptions: CommonSelectItem[] = [
  { value: '0', label: 'No Filter' },
  { value: 'running', label: 'Live' },
  { value: 'failed', label: 'Down' },
  { value: 'stopped', label: 'Stopped' },
  { value: 'starting', label: 'Starting' },
  { value: 'queueing', label: 'Queueing' },
];

const audioFilterOptions: CommonSelectItem[] = [
  { value: '0', label: 'All' },
  { value: 'aac', label: 'aac' },
  { value: 'mac', label: 'mac' },
  { value: 'mp2', label: 'mp2' },
];
const videoFilterOptions: CommonSelectItem[] = [
  { value: '0', label: 'All' },
  { value: 'h264', label: 'h264' },
  { value: 'mpeg2video', label: 'mpeg2video' },
  { value: 'hvec', label: 'hvec' },
  { value: 'av1', label: 'av1' },
  { value: 'vp9', label: 'vp9' },
];
const qualityFilterOptions: CommonSelectItem[] = [
  { value: '320x240', label: '320x240' },
  { value: '640x480', label: '640x480' },
  { value: '720x480', label: '720x480' },
  { value: '720x576', label: '720x576' },
  { value: '1280x720', label: '1280x720' },
  { value: '1440x1080', label: '1440x1080' },
  { value: '1920x1080', label: '1920x1080' },
  { value: '2048x1080', label: '2048x1080' },
  { value: '2560x1440', label: '2560x1440' },
  { value: '3840x2160', label: '3840x2160' },
  { value: '4096x2160', label: '4096x2160' },
  { value: '7680x4320', label: '7680x4320' },
  { value: '8192x4320', label: '8192x4320' },
  { value: '15360x8640', label: '15360x8640' },
];

const fpsOptions: CommonSelectItem[] = [
  { value: 'high', label: 'High to Low' },
  { value: 'low', label: 'Low to High' },
];

const showOptions: CommonSelectItem[] = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '250', label: '250' },
  { value: '500', label: '500' },
  { value: '1000', label: '1000' },
];

interface Props {
  className?: string;
}

const ManageStreamsForm = ({ className }: Props) => {
  const streams = useAtomValue(streamsAtom);
  const [params, setParams] = useState<Record<string, string>>();
  const modifyParams = useCallback((param: string, value?: string, order?: 'asc' | 'desc') => {
    setParams(prevParams => {
      const updatedParams = { ...prevParams };
      if (order) {
        if (value === '' || value === undefined || (value === 'id' && order === 'asc')) {
          delete updatedParams.sortBy;
          delete updatedParams.order;
        } else {
          updatedParams.sortBy = value;
          updatedParams.order = order;
        }
      } else if (value === '0' || !value || value === '') {
        if (param === 'search' && value === '0') {
          updatedParams[param] = value;
        } else {
          delete updatedParams[param];
        }
      } else {
        updatedParams[param] = value;
      }
      return updatedParams;
    });
  }, []);
  const { getAllStreams } = useStreams(false);
  const { startStream } = useStartStream();
  const { stopStream } = useStopStream();
  const { deleteStream } = useDeleteStream();
  const { editStream } = useEditStream();

  const categories = useAtomValue(categoriesAtom);
  const [showNumber, setShowNumber] = useState<CommonSelectItem | null>(showOptions[0]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [page, setPage] = useState(1);

  const selectedStreamRef = useRef<Stream | null>(null);

  useEffect(() => {
    const timer = setInterval(() => {
      getAllStreams(page, Number(showNumber?.value ?? 10), params);
    }, 5000);
    getAllStreams(page, Number(showNumber?.value ?? 10), params);
    setShowEditModal(false);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, showNumber, params]);

  const onEditStream = () => {
    setShowEditModal(true);
  };

  const onStopStream = () => {
    stopStream(selectedStreamRef.current?.id ?? '');
  };

  const onRestartStream = () => {
    startStream(selectedStreamRef.current?.id ?? '');
  };

  const onKillConnections = () => {
    // TODO Kill Connections
  };

  const onDeleteStream = () => {
    // Show Confirm Modal to Delete Stream
    setShowDeleteModal(true);
  };

  const onConfirmDeleteUser = () => {
    setShowDeleteModal(false);
    // Call delete user api
    deleteStream(selectedStreamRef.current?.id ?? '');
  };

  const onPlayer = () => {
    setShowPlayerModal(true);
  };

  const onSubmitStream = (stream: Stream) => {
    //  onCloseEditModal();
    editStream(stream);
  };

  const onCloseEditModal = () => {
    setShowEditModal(false);
  };

  const columns: Column<Stream>[] = useMemo(
    () =>
      getStreamsHeader(
        (stream: Stream) => (selectedStreamRef.current = stream),
        onStopStream,
        onRestartStream,
        onKillConnections,
        onEditStream,
        onDeleteStream,
        onPlayer,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const checks = ['Check All', 'Uncheck All', 'Check All On Page'];
  const options = ['Stop', 'Restart', 'Start', 'Delete'];
  const [initChecks, setInitChecks] = useState(true);
  const { startStreams, loading: loadingStartStreams, startStreamsErr } = useStartStreams();
  const { stopStreams, loading: loadingStopStreams, stopStreamsErr } = useStopStreams();
  const { deleteStreams, loading: loadingDeleteStreams, deleteStreamsErr } = useDeleteStreams();
  const [selectedStreamsCount, setSelectedStreamsCount] = useState(0);
  const [option, setOption] = useState<string | undefined>();
  useEffect(() => {
    toast.dismiss();
    if (!selectedStreamsCount || !option) return;
    const streamPlural = selectedStreamsCount > 1 ? 's' : '';
    const streamsText = `${selectedStreamsCount} stream${streamPlural}`;
    if (loadingStartStreams || loadingStopStreams || loadingDeleteStreams) {
      toast.loading(`${option.toLocaleLowerCase()}ing ${streamsText}`);
    } else if (startStreamsErr || stopStreamsErr || deleteStreamsErr) {
      toast.error(`Error while ${option.toLocaleLowerCase()}ing ${streamsText}`);
      setOption(undefined);
      setInitChecks(true);
    } else {
      toast.success(`${streamsText} ${option.toLocaleLowerCase()}ed Successfully`);
      setOption(undefined);
      setInitChecks(true);
    }
  }, [
    loadingStartStreams,
    loadingStopStreams,
    loadingDeleteStreams,
    startStreamsErr,
    stopStreamsErr,
    deleteStreamsErr,
    selectedStreamsCount,
    option,
  ]);
  const getRequestHandlerByOption = (option: string) => {
    setOption(option);
    setInitChecks(false);
    switch (option) {
      case 'Start':
      case 'ReStart':
        return startStreams;
      case 'Delete':
        return deleteStreams;
      case 'Stop':
        return stopStreams;
      default:
        return startStreams;
    }
  };
  const categoriesOptions = [{ id: 0, name: 'All Categories' }, ...categories].map(category => {
    return { value: category.id, label: category.name } as unknown as CommonSelectItem;
  });
  return (
    <div className={cn('w-full max-w-[2500px] mt-[30px] mb-0 mx-auto', className)}>
      <FormTitle>Manage Streams</FormTitle>
      <div className='flex justify-between items-center gap-[25px] mx-0 my-10'>
        <div className='flex-auto max-w-[1000px] flex justify-between items-center gap-1 [&:nth-child(-n+4)]:min-w-[150px] [&:nth-child(n+5)]:min-w-[130px]'>
          <input
            type='search'
            className='grow max-w-[280px] min-w-[200px] bg-dark text-sm text-form-content px-3 py-[11px] rounded-[19px]'
            autoComplete='on'
            placeholder='Search Streams...'
            onChange={e => {
              modifyParams('search', e.target.value);
            }}
          />

          {[
            { name: 'server', array: serverFilterOptions, placeholder: 'Servers...' },
            {
              name: 'category',
              array: categoriesOptions,
              placeholder: 'Categories...',
            },
            {
              name: 'status',
              array: onlineFilterOptions,
              placeholder: 'Online...',
            },
            {
              name: 'audio',
              array: audioFilterOptions,
              placeholder: 'Audio...',
            },
            {
              name: 'video',
              array: videoFilterOptions,
              placeholder: 'Video...',
            },
            {
              name: 'quality',
              array: qualityFilterOptions,
              placeholder: 'Quality...',
            },
            {
              name: 'fps',
              array: fpsOptions,
              placeholder: 'FPS...',
            },
          ].map((selecter, index) => (
            <Select<CommonSelectItem>
              key={index}
              isClearable
              className='grow max-w-[280px] text-sm text-form-content'
              options={selecter.array}
              styles={headerSelectStyles}
              classNames={{
                dropdownIndicator: ({ hasValue }) => (hasValue ? '!hidden' : ''),
              }}
              placeholder={selecter.placeholder}
              onChange={value => {
                modifyParams(selecter.name, value?.value);
              }}
            />
          ))}
        </div>
        <div className='flex items-center gap-4'>
          <span className='form-header-name'>Show</span>
          <Select<CommonSelectItem>
            className='shrink-0 w-[100px] text-sm text-form-content'
            options={showOptions}
            styles={headerSelectStyles}
            value={showNumber}
            onChange={value => {
              setPage(1);
              setShowNumber(value);
            }}
          />
        </div>
      </div>
      <div className='w-full overflow-auto'>
        <ManageTable<Stream>
          columns={columns}
          data={streams.Records ?? []}
          totalRecords={streams.TotalRecords}
          checks={checks}
          initChecks={initChecks}
          options={options}
          setSelectedItemsCount={setSelectedStreamsCount}
          getRequestHandlerByOption={getRequestHandlerByOption}
          onSort={(columnId, isDesc) => {
            modifyParams('sortBy', columnId, isDesc ? 'desc' : 'asc');
          }}
          type='Stream'
        />
      </div>
      <Paginator
        page={page}
        totalRecords={streams.TotalRecords}
        pageSize={Number(showNumber?.value ?? 10)}
        setPage={setPage}
      />
      <ModalWarning
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        noIconOnOk
        nextButtonTitle='Delete'
        handleCancel={() => setShowDeleteModal(false)}
        handleOk={onConfirmDeleteUser}
      >
        {deleteMessage}
      </ModalWarning>
      <HLSPlayerModal
        sourceURL={
          selectedStreamRef.current?.id
            ? `${baseURL}/stream/preview/${selectedStreamRef.current?.id}/output.m3u8`
            : ''
        } //selectedStreamRef.current?.url ?? ''
        isOpen={showPlayerModal}
        onClose={() => {
          setShowPlayerModal(false);
          selectedStreamRef.current = null;
        }}
      />
      {selectedStreamRef.current && (
        <EditStreamModal
          stream={selectedStreamRef.current}
          isOpen={showEditModal}
          onClose={onCloseEditModal}
          onSubmit={onSubmitStream}
        />
      )}
    </div>
  );
};

export default ManageStreamsForm;

const renderIcon = (cell: CellProps<Stream, string>): JSX.Element => (
  <img src={cell.value} alt='icon' height={'100%'} className='h-[24px] rounded-lg mx-auto' />
);
const RenderClients = (cell: CellProps<Stream, number>): JSX.Element => {
  return (
    <div
      className={`my-0 mx-auto h-7 wfit flex py-0 px-[9px] rounded-lg justify-center items-center text-[16px] text-[#13132c] ${
        cell.value ? 'bg-[#7bffa5]' : 'bg-white'
      }`}
    >
      {cell.value}
    </div>
  );
};
const RenderUptime = (cell: CellProps<Stream, number>): JSX.Element => {
  const status = cell.row.original.status;
  const upTime = cell.row.original.start_time;
  let statusLabel = '';
  let statusBgColor = '';
  if (status === 'stopped') {
    statusLabel = 'STOPPED';
    statusBgColor = 'red';
  } else if (status === 'running') {
    const uptimeStr = intervalToDurationStr(upTime);
    statusLabel = uptimeStr;
    statusBgColor = '#7bffa5';
  } else if (status === 'starting') {
    statusLabel = 'STARTING';
    statusBgColor = 'yellow';
  } else if (status === 'queueing') {
    statusLabel = 'QUEUEING';
    statusBgColor = '#239172';
  } else {
    statusLabel = 'DOWN';
    statusBgColor = '#ff7b7b';
  }
  return (
    <div
      style={{
        margin: '0 auto',
        height: '28px',
        width: '88px',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: statusBgColor,
        fontSize: '12px',
        color: '#13132c',
      }}
    >
      {statusLabel}
    </div>
  );
};

const RenderName = (cell: CellProps<Stream, string>): JSX.Element => {
  const categories = useAtomValue(categoriesAtom);
  return (
    <div>
      <div style={{ fontSize: '14px' }}>{cell.value}</div>
      <div style={{ fontSize: '11px' }}>
        {getCategoriesOptions(categories, cell.row.original.categories)[0]?.name ?? 'No Category'}
      </div>
    </div>
  );
};
const renderServers = (cell: CellProps<Stream, string>): JSX.Element => (
  <div>
    <div style={{ fontSize: '16px' }}>Main Server</div>
    <div style={{ fontSize: '11px' }}>{cell.value}</div>
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const popupBody: any = (
  onStopStream: () => void,
  onRestartPlaylist: () => void,
  onKillConnections: () => void,
  onEditStream: () => void,
  onDeleteStream: () => void,
) => (
  <div className='popup-menu'>
    <div className='popup-menu-item' onClick={onStopStream}>
      Stop
    </div>
    <div className='popup-menu-item' onClick={onRestartPlaylist}>
      Restart
    </div>
    {/* <div className='popup-menu-item' onClick={onKillConnections}>
      Kill Connections
    </div> */}
    <div className='popup-menu-item' onClick={onEditStream}>
      Edit
    </div>
    <div className='popup-menu-item' onClick={onDeleteStream}>
      Delete
    </div>
  </div>
);

const RenderPopup = (
  cell: CellProps<Stream, number>,
  onSelectStream: (stream: Stream) => void,
  onStopStream: () => void,
  onRestartPlaylist: () => void,
  onKillConnections: () => void,
  onEditStream: () => void,
  onDeleteStream: () => void,
): JSX.Element => {
  const stream = cell.row.original;
  const popupRef = useRef<PopupActions | null>(null);
  const _onStopStream = () => {
    popupRef.current?.close();
    onStopStream();
  };
  const _onRestartPlaylist = () => {
    popupRef.current?.close();
    onRestartPlaylist();
  };
  const _onKillConnections = () => {
    popupRef.current?.close();
    onKillConnections();
  };
  const _onEditStream = () => {
    popupRef.current?.close();
    onEditStream();
  };
  const _onDeleteStream = () => {
    popupRef.current?.close();
    onDeleteStream();
  };
  return (
    <Popup
      ref={popupRef}
      trigger={
        <div className='text-base shadow-[0px_2px_13px_1px_#1e1e3f] px-0 py-1.5 rounded-2xl hover:brightness-200 hover:cursor-pointer focus:outline-offset-2 active:shadow-[0px_2px_13px_0px_#1e1e3f] active:opacity-70 bg-dark focus:[outline:white_solid_2px]'>
          Actions
        </div>
      }
      position='left center'
      on='click'
      onOpen={() => onSelectStream(stream)}
      closeOnDocumentClick
      closeOnEscape
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      arrow={false}
    >
      {popupBody(
        _onStopStream,
        _onRestartPlaylist,
        _onKillConnections,
        _onEditStream,
        _onDeleteStream,
      )}
    </Popup>
  );
};

const renderPlayer = (
  cell: CellProps<Stream, number>,
  onSelectStream: (stream: Stream) => void,
  onPlayer: () => void,
): JSX.Element => {
  const stream = cell.row.original;

  const handleOnClick = () => {
    onSelectStream(stream);
    onPlayer();
  };

  return (
    <MiddleButton
      bgColor={'#7bffa5'}
      className='mx-auto my-0 px-2.5 py-[7px]'
      onClick={handleOnClick}
    >
      <RunPlayerSvg />
    </MiddleButton>
  );
};
const renderEpg = (): JSX.Element => (
  <MiddleButton bgColor={'#7bffa5'} className='mx-auto my-0 px-2.5 py-[7px]'>
    <StopPlayerSvg />
  </MiddleButton>
);

const renderStreamInfo = (
  cell: CellProps<
    Stream,
    {
      width?: string;
      height?: string;
      speed?: string;
      video_codec?: string;
      fps?: number;
      audio_codec?: string;
      sample_rate?: string;
    }
  >,
): JSX.Element => {
  const status = cell.row.original.status;
  if (status !== 'running') {
    return (
      <div className='h-full w-full max-w-[400px] bg-dark border-b-placeholder-dark flex justify-around items-center gap-2.5 text-sm border-b border-solid'>
        -
      </div>
    );
  }

  return (
    <div className='h-full w-full max-w-[400px] bg-dark border-b-placeholder-dark flex justify-around items-center gap-2.5 text-sm border-b border-solid'>
      <div className='flex flex-col justify-between items-center gap-2 pt-2.5 grow-[3] p-0'>
        <div> {`${cell.value?.sample_rate ?? '-'}`}</div>
        <div>
          {cell.value?.width ?? '-'}&times;{cell.value?.height ?? '-'}
        </div>
      </div>
      <div className='grow flex flex-col justify-between items-center gap-2 pt-2.5'>
        <VideoSvg />
        <div> {cell.value?.video_codec ?? '-'}</div>
      </div>
      <div className='grow flex flex-col justify-between items-center gap-2 pt-2.5'>
        <AudioSvg />
        <div> {cell.value?.audio_codec ?? '-'}</div>
      </div>
      <div className='grow flex flex-col justify-between items-center gap-2 pt-2.5'>
        <SpeedSvg />
        <div> {`${cell.value?.speed ?? 0}`}</div>
      </div>
      <div className='grow flex flex-col justify-between items-center gap-2 pt-2.5'>
        <FpsSvg />
        <div> {`${cell.value?.fps ?? 0} FPS`}</div>
      </div>
    </div>
  );
};

const getStreamsHeader: (
  onSelectStream: (stream: Stream) => void,
  onStopStream: () => void,
  onRestartPlaylist: () => void,
  onKillConnections: () => void,
  onEditStream: () => void,
  onDeleteStream: () => void,
  onPlayer: () => void,
) => Column<Stream>[] = (
  onSelectStream,
  onStopStream,
  onRestartPlaylist,
  onKillConnections,
  onEditStream,
  onDeleteStream,
  onPlayer,
) => [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Icon',
    accessor: 'stream_icon',
    Cell: renderIcon,
    disableSortBy: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: RenderName,
  },
  {
    Header: 'Servers',
    accessor: 'url',
    Cell: renderServers,
    disableSortBy: true,
  },
  {
    Header: 'Clients',
    accessor: 'clients',
    Cell: RenderClients,
  },
  {
    Header: 'Uptime',
    accessor: 'start_time',
    Cell: RenderUptime,
    id: 'uptime',
  },
  {
    Header: 'Actions',
    Cell: (cell: CellProps<Stream, number>) =>
      RenderPopup(
        cell,
        onSelectStream,
        onStopStream,
        onRestartPlaylist,
        onKillConnections,
        onEditStream,
        onDeleteStream,
      ),
    disableSortBy: true,
  },
  {
    Header: 'Player',
    Cell: (cell: CellProps<Stream, number>) => renderPlayer(cell, onSelectStream, onPlayer),
    disableSortBy: true,
  },
  {
    Header: 'EPG',
    Cell: renderEpg,
    disableSortBy: true,
  },
  {
    Header: 'Stream Info',
    accessor: 'info',
    id: 'fps',
    Cell: renderStreamInfo,
    disableSortBy: true,
  },
];
