/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { logout, setSessionId } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { isAxiosError } from 'axios';
import { useSetAtom } from 'jotai';
import { loadingAtom } from '../../store/jotai';

export const useLogout = () => {
  const [_logout, , _logoutRes, _logoutErr] = useRequest(logout);
  const setLoading = useSetAtom(loadingAtom);
  useEffect(() => {
    if (_logoutRes) {
      setLoading(false);
      // TODO check data object
      console.log('logoutRes: ', _logoutRes);
      setSessionId(undefined);
    }
  }, [_logoutRes]);

  useEffect(() => {
    if (_logoutErr) {
      setLoading(false);
      if (isAxiosError(_logoutErr)) {
        console.log('logoutErrText: ', _logoutErr.response?.data.error);
        console.log('logoutErrMessage: ', _logoutErr.response?.data.message);
      } else {
        console.log('logoutErr: ', _logoutErr);
      }
    }
  }, [_logoutErr]);
  return {
    logout: (data: unknown) => {
      setLoading(true);
      return _logout(data);
    },
    logoutRes: _logoutRes,
    logoutErr: _logoutErr,
  };
};
