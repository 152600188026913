import React, { useEffect } from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import UrlInput from './Urls';
import FormFooter from '../../common/FormFooter';
import { Stream } from '../../../types/model/stream';
import SlideToggle from '../../common/SlideToggle';

export interface StreamSourcesFormInput {
  url: string;
  isStart: boolean;
}
interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
  formData?: Stream;
  setFormData?: React.Dispatch<React.SetStateAction<Stream>>;
  submitFormData?: (data: Stream) => void;
  isEdit?: boolean;
}

const StreamSourcesForm = ({
  className,
  setVisibleTab,
  formData,
  setFormData,
  submitFormData,
  isEdit = false,
}: Props) => {
  const { handleSubmit, control, setFocus, getValues } = useForm<StreamSourcesFormInput>({
    defaultValues: {
      url: formData?.url,
      isStart: formData?.is_start ?? false,
    },
  });

  const handlePrev = () => {
    if (setVisibleTab) setVisibleTab(1);
  };

  // If setFormData before unmount
  useEffect(() => {
    return () => {
      const data = getValues();
      if (setFormData) {
        setFormData(currentLine => ({
          ...currentLine,
          url: data.url,
          is_start: data.isStart,
        }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<StreamSourcesFormInput> = data => {
    if (data.url.length === 0) {
      setFocus('url');
      return;
    }
    const newStream = {
      ...formData,
      url: data.url,
      is_start: data.isStart,
    } as Stream;
    if (submitFormData && newStream) submitFormData(newStream);
    // if (setVisibleTab) setVisibleTab(3);
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-card element-card'>
          <div className='my-3 mx-0 flex justify-between items-center gap-8'>
            <p className='text-[24px] text-form-content'>URL</p>
          </div>
          <Controller
            control={control}
            name='url'
            rules={{ required: false }}
            render={({ field }) => <UrlInput field={field} isEdit={isEdit}/>}
          />
          <Controller
            control={control}
            name='isStart'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item'>
                <span className='form-item-name'>Start Stream Now</span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
        </div>

        <FormFooter handlePrev={handlePrev} nextButtonTitle={isEdit ? 'Edit' : 'Add'} />
      </form>
    </div>
  );
};

export default StreamSourcesForm;
