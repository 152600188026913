import { StylesConfig } from 'react-select';
import { CommonSelectItem } from '../types/model/commonSelectItem';

export const selectStyles: StylesConfig<CommonSelectItem> = {
  container: styles => ({ ...styles }),
  control: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    borderRadius: '8px',
    backgroundColor: isDisabled ? '#1f1f41' : '#13132C',
    border: 'none',
    outline: isFocused ? 'white solid 1.5px' : 'none',
    boxShadow: 'none',
    ':hover': { boxShadow: 'none', outline: isFocused ? 'white solid 1.5px' : 'none' },
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? '#2B2B4B' : isFocused ? '#4C4C6C' : '#13132C',
  }),
  input: styles => ({ ...styles }),
  menuList: styles => ({
    ...styles,
    color: '#d6d6ff',
    backgroundColor: '#13132C',
    border: '1.5px solid white',
  }),
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? '#6d6d87' : '#d6d6ff',
    padding: '5px 8px',
  }),
  placeholder: styles => ({ ...styles, color: '#4C4C6C', paddingLeft: '8px' }),
  indicatorSeparator: styles => ({ ...styles, visibility: 'hidden' }),
};

export const headerSelectStyles: StylesConfig<CommonSelectItem> = {
  ...selectStyles,
  control: (styles, { isFocused }) => ({
    ...styles,
    borderRadius: '19px',
    backgroundColor: '#13132C',
    border: 'none',
    outline: isFocused ? 'white solid 1.5px' : 'none',
    boxShadow: 'none',
    ':hover': { boxShadow: 'none', outline: isFocused ? 'white solid 1.5px' : 'none' },
  }),
  singleValue: styles => ({ ...styles, color: '#d6d6ff', padding: '2px' }),
  placeholder: styles => ({ ...styles, color: '#4C4C6C', paddingLeft: '2px' }),
};

export const settingSelectStyles: StylesConfig<CommonSelectItem> = {
  ...selectStyles,
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? '#6d6d87' : '#d6d6ff',
    padding: '5px 0',
  }),
  placeholder: styles => ({ ...styles, color: '#4C4C6C' }),
};
