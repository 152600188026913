import React from 'react';
import Popup from 'reactjs-popup';
import { QuestionMarkSvg } from '../../assets/svg';

interface Props {
  message: JSX.Element | string;
  trigger?: JSX.Element;
  disabled?: boolean;
}

const Tooltip = ({ message, trigger, disabled }: Props) => {
  return (
    <Popup
      trigger={trigger ?? <QuestionMarkSvg style={{ flexShrink: '0' }} />}
      on='hover'
      position='top center'
      closeOnDocumentClick
      disabled={disabled ?? false}
    >
      <div style={{ whiteSpace: 'pre-line' }}>{message}</div>
    </Popup>
  );
};

export default Tooltip;
