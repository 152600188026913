import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { DownArrowSmallSvg, UpArrowSmallSvg } from '../../assets/svg';
import { MenuItem } from '../../types/menuData';
import { cn } from '../../utils/tw-merge';
import { useLogout } from '../../hooks/auth/useLogout';
import { getSessionId } from '../../lib/api';

interface Props {
  menuItems: MenuItem[];
  className: string;
}

const DownMenu = ({ menuItems, className }: Props) => {
  const location = useLocation();
  const {logout, logoutRes} = useLogout();
  const navigate = useNavigate();
  const [activatedMenuItemId, setActivatedMenuItemId] = useState<string>();
  const [overedMenuItemId, setOveredMenuItemId] = useState<string>();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const path = pathSegments[1];
    setActivatedMenuItemId(path);
  }, [location]);

  useEffect(() => {
    if (logoutRes) {
      console.log('logoutRes: ', logoutRes);
      navigate('/');
    }
  }, [logoutRes, navigate]);

  const onClickItem = (menuItem: MenuItem) => {
    const link = menuItem.type === 'link' ? menuItem.route : '#'
    if (menuItem.id === 'logout') {
      logout(getSessionId());
      return;
    }
    navigate(link);
  }
  return (
    <div
      className={cn(
        'py-[17px] px-[7px] flex flex-col justify-center items-stretch gap-[10px]',
        className,
      )}
    >
      {menuItems.map(menuItem => {
        const activated = menuItem.id === activatedMenuItemId;
        const overed = menuItem.id === overedMenuItemId;
        return (
          <div
            key={menuItem.id}
            onMouseOver={() => setOveredMenuItemId(menuItem.id)}
            onMouseLeave={() => setOveredMenuItemId(undefined)}
          >
            <div onClick={() => onClickItem(menuItem)}>
              <div
                className={`h-[31px] py-0 px-[10px] flex flex-row justify-start items-center gap-5 cursor-pointer hover:[box-shadow:inset_0px_4px_4px_rgba(0,0,0,0.5)] ${
                  activated &&
                  '[border-width:2px_0] border-solid [border-image:linear-gradient(to_left,#ffffff00,#9747ff,#ffffff00)] [background:radial-gradient(46.63%_99.89%_at_47.59%_99.89%,rgba(122,51,173,0.48)_0%,rgba(151,71,255,0)_100%),radial-gradient(100%_100%_at_50.63%_0%,rgba(151,71,255,0.16)_0%,rgba(151,_71,_255,_0)_100%),#2b2b4b]'
                }`}
                style={{
                  borderImageSlice: 1,
                }}
              >
                <menuItem.icon className='h-[18px] w-[18px]' />
                <p className='w-full font-semibold text-[16px]'>{menuItem.name}</p>
                {menuItem.type === 'sub' &&
                  (activated ? <UpArrowSmallSvg /> : <DownArrowSmallSvg />)}
              </div>
            </div>
            {(activated || overed) && menuItem.subMenuItems && menuItem.subMenuItems.length > 0 && (
              <div className='flex flex-col justify-center items-stretch gap-0.5 ml-[35px] mr-0 mt-0.5 mb-[13px]'>
                {menuItem.subMenuItems.map(subMenuItem => (
                  <NavLink
                    key={subMenuItem.id}
                    className={({ isActive }) =>
                      `h-[29px] flex flex-row justify-start items-center gap-3 cursor-pointer animate-[dropdown-menu-item-animation_200ms_ease-in] px-2.5 py-0 rounded-lg hover:shadow-[inset_0px_4px_4px_rgba(0,0,0,0.5)] ${
                        isActive &&
                        '[background:radial-gradient(46.63%_99.89%_at_47.59%_99.89%,rgba(122,51,173,0.48)_0%,rgba(151,71,255,_0)_100%),#2b2b4b]'
                      }`
                    }
                    to={subMenuItem.route}
                  >
                    <subMenuItem.icon className='h-4 w-4' />
                    <p className='w-full font-semibold text-[14px]'>{subMenuItem.name}</p>
                  </NavLink>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DownMenu;
