import React, { useEffect, useState } from 'react';
import FormTab from '../common/FormTab';
import FormTitle from '../common/FormTitle';
import { TabData } from '../common/Tab';
import StreamDetailsForm from './details/StreamDetailsForm';
import StreamSourcesForm from './sources/StreamSourcesForm';
import { Stream } from '../../types/model/stream';
import { useNavigate } from 'react-router-dom';
import { useInsertStream } from '../../hooks/stream/useInsertStream';
import { cn } from '../../utils/tw-merge';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'Details',
    tabComponent: StreamDetailsForm,
  },
  {
    id: 2,
    tabTitle: 'Sources',
    tabComponent: StreamSourcesForm,
  },
  // {
  //   id: 3,
  //   tabTitle: 'Advanced',
  //   tabComponent: StreamAdvancedForm,
  // },
  // {
  //   id: 4,
  //   tabTitle: 'Map',
  //   tabComponent: StreamMapForm,
  // },
  // {
  //   id: 5,
  //   tabTitle: 'EPG',
  //   tabComponent: StreamEPGForm,
  // },
  // {
  //   id: 6,
  //   tabTitle: 'RTM Push',
  //   tabComponent: StreamRTMPushForm,
  // },
  // {
  //   id: 7,
  //   tabTitle: 'Servers',
  //   tabComponent: StreamServersForm,
  // },
];

interface Props {
  className?: string;
}

const AddStreamForm = ({ className }: Props) => {
  const navigate = useNavigate();
  const [streamData, setStreamData] = useState<Stream>();
  const { insertStream, completed } = useInsertStream();

  useEffect(() => {
    if (completed) navigate('/streams/manage-streams');
  }, [completed, navigate]);

  const submitStreamData = async (data: Stream) => {
    insertStream(data);
  };
  return (
    <div className={cn('w-[700px] max-w-full my-[2px] mx-auto', className)}>
      <FormTitle>Add Stream</FormTitle>
      <FormTab
        data={tabData}
        formData={streamData}
        currentTab={tabData[0].id}
        setFormData={setStreamData}
        submitFormData={submitStreamData}
      />
    </div>
  );
};

export default AddStreamForm;
