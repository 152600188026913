import { useEffect } from 'react';
import { login, setSessionId } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { isAxiosError } from 'axios';

export const useLogin = () => {
  const [_login, , _loginRes, _loginErr] = useRequest(login);
  useEffect(() => {
    if (_loginRes) {
      // TODO check data object
      setSessionId(_loginRes.session_id);
    }
  }, [_loginRes]);

  useEffect(() => {
    if (_loginErr) {
      if (isAxiosError(_loginErr)) {
        console.log('loginErrText: ', _loginErr.response?.data.error);
        console.log('loginErrMessage: ', _loginErr.response?.data.message);
      } else {
        console.log('loginErr: ', _loginErr);
      }
    }
  }, [_loginErr]);
  return { login: _login, loginRes: _loginRes, loginErr: _loginErr };
};
