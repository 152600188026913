import React, { Dispatch, SetStateAction, useState } from 'react';
import { IconButton } from './Buttons';
import { IoMdAdd, IoMdClose } from 'react-icons/io';
import toast from 'react-hot-toast';
import { cn } from '../../utils/tw-merge';

const AllowedInput = ({
  values,
  validation,
  setValues,
}: {
  values: string[];
  setValues: Dispatch<SetStateAction<string[]>>;
  validation?: RegExp;
}) => {
  const [value, setValue] = useState('');
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  return (
    <div className='flex flex-col w-full'>
      <div className='flex gap-2 items-center w-full'>
        <div className='form-item-content w-full'>
          <input
            className='form-item-input w-full'
            onChange={e => {
              setValue(e.target.value);
            }}
            value={value}
            autoComplete='off'
          />
        </div>
        <div className='flex'>
          <IconButton
            onClick={() => {
              if ((validation?.test(value) || !validation) && !values.includes(value)) {
                setValues([value, ...values]);
                setValue('');
              } else {
                toast.error("Can't input check the value.");
              }
            }}
            className='rounded-none shadow-none'
            color='#fe7133'
            size={40}
          >
            <IoMdAdd />
          </IconButton>
          <IconButton
            onClick={() => {
              setValues(values.filter(value => !selectedValues.includes(value)));
            }}
            className='rounded-none shadow-none'
            color='#4b88e3'
            size={40}
            disabled={selectedValues.length === 0}
          >
            <IoMdClose />
          </IconButton>
        </div>
      </div>
      <div className='overflow-y-auto p-2 bg-dark mt-2 rounded h-[10rem]'>
        {values.map(value => (
          <div
            key={value}
            onClick={() => {
              if (!selectedValues.includes(value)) {
                setSelectedValues([...selectedValues, value]);
              } else {
                setSelectedValues(selectedValues.filter(v => v !== value));
              }
            }}
            className={cn('cursor-pointer rounded', {
              ' bg-element-card': selectedValues.includes(value),
            })}
          >
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllowedInput;
