import React, { useEffect, useState } from 'react';
import FormTab from '../common/FormTab';
import FormTitle from '../common/FormTitle';
import { TabData } from '../common/Tab';
import BouquetsDetailsForm from './BouquetsDetailsForm';
import { Bouquet } from '../../types/model/bouquet';
import { useNavigate } from 'react-router-dom';
import { useInsertBouquet } from '../../hooks/bouquet/useInsertBouquet';
import { cn } from '../../utils/tw-merge';
import ChannelsForm from './ChannelsForm';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'Details',
    tabComponent: BouquetsDetailsForm,
  },
  {
    id: 2,
    tabTitle: 'Channels',
    tabComponent: ChannelsForm,
  },
];

interface Props {
  className?: string;
}

const AddBouquetForm = ({ className }: Props) => {
  const navigate = useNavigate();
  const [bouquetData, setBouqetData] = useState<Bouquet>();

  const { insertBouquet, completed } = useInsertBouquet();
  useEffect(() => {
    if (completed) {
      navigate('/management/bouquets/manage-bouquets');
    }
  }, [navigate, completed]);

  const submitBouquetData = async (data: { name: string; streams: number[] }) => {
    insertBouquet({
      name: data.name,
      channels: {
        streams: data.streams,
        movies: [],
        series: [],
      },
    });
  };

  return (
    <div className={cn('w-[755px] max-w-full my-[2px] mx-auto', className)}>
      <FormTitle>Add Bouquets</FormTitle>
      <FormTab
        data={tabData}
        formData={bouquetData}
        currentTab={tabData[0].id}
        setFormData={setBouqetData}
        submitFormData={submitBouquetData}
      />
    </div>
  );
};

export default AddBouquetForm;
