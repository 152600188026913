import React from 'react';
import { cn } from '../../utils/tw-merge';

interface Props {
  className?: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

const SlideToggle = ({ className, value, onChange }: Props) => {
  const handleClick = () => {
    onChange(!value);
  };

  return (
    <button
      type='button'
      onClick={handleClick}
      className={cn(
        `relative shrink-0 min-w-[50px] h-[18px] flex flex-row items-center justify-center rounded-[9px] [box-shadow:inset_0px_4px_4px_rgba(0,0,0,0.25)] cursor-pointer ${
          value ? 'bg-[#545488]' : 'bg-[#13132C]'
        } hover:opacity-80 focus:outline-offset-0 focus:outline-[white_solid_1px] active:opacity-70 `,
        className,
      )}
    >
      <div
        className={`absolute w-[18px] h-[18px] rounded-[50%] bg-[#d6d6ff] [box-shadow:inset_0px_4px_4px_rgba(0,0,0,0.25)] ${
          value ? 'right-0' : 'left-0'
        }`}
      ></div>
    </button>
  );
};

export default SlideToggle;
