import React, { useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { StreamSourcesFormInput } from './StreamSourcesForm';
import { WhiteBorderButton } from '../../common/Buttons';

interface Props {
  field: ControllerRenderProps<StreamSourcesFormInput, 'url'>;
  isEdit: boolean;
}

const UrlInput = ({ field, isEdit = false }: Props) => {
  const [disabled, setDisabled] = useState(isEdit);
  // useEffect(() =>{
  //   if (isEdit) {
  //     setDisabled(true);
  //   }
  // }, [isEdit])
  return (
    <div>
      <div className='form-item'>
        <div className='form-item-content w-full'>
          <input
            type='url'
            disabled={disabled}
            className='form-item-input'
            autoComplete='off'
            {...field}
          />
        </div>
      </div>
      <div className='flex gap-4'>
        <WhiteBorderButton
          onClick={() => {
            setDisabled(true);
          }}
        >
          Save
        </WhiteBorderButton>
        <WhiteBorderButton
          onClick={() => {
            setDisabled(false);
          }}
        >
          Edit
        </WhiteBorderButton>
      </div>
    </div>
  );
};

export default UrlInput;
