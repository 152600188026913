/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import useRequest from '../../lib/hooks/useRequest';
import { insertBouquet } from '../../lib/api';
import { isAxiosError } from 'axios';
import { useBouquets } from './useBouquets';
import { useSetAtom } from 'jotai';
import { loadingAtom } from '../../store/jotai';

export const useInsertBouquet = () => {
  const [_insertBouquet, , insertBouquetRes, insertBouquetErr] = useRequest(insertBouquet);
  const { getAllBouquets, completed } = useBouquets();
  const setLoading = useSetAtom(loadingAtom);

  useEffect(() => {
    if (insertBouquetRes) {
      setLoading(false);
      console.log('insertBouquetRes: ', insertBouquetRes);
      // Todo: go to categories page
      getAllBouquets();
    }
  }, [getAllBouquets, insertBouquetRes]);

  useEffect(() => {
    if (insertBouquetErr) {
      setLoading(false);
      if (isAxiosError(insertBouquetErr)) {
        console.log('insertBouquetErrText: ', insertBouquetErr.response?.data.error);
        console.log('insertBouquetErrMessage: ', insertBouquetErr.response?.data.message);
      } else {
        console.log('insertBouquetErr: ', insertBouquetErr);
      }
    }
  }, [insertBouquetErr]);
  return {
    insertBouquet: (data: unknown) => {
      setLoading(true);
      return _insertBouquet(data);
    },
    completed,
  };
};
