import React from 'react';
import ReactModal from 'react-modal';
import { XMarkSvg } from '../../assets/svg';
import { IconButton } from './Buttons';
import { cn } from '../../utils/tw-merge';

interface Props {
  className?: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

const ModalForm = ({ className, children, isOpen, onClose, title }: Props) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={cn('modal-form card', className)}
      ariaHideApp={false}
      overlayClassName='flex items-center justify-center bg-[rgba(0,0,0,0.75)] z-[1000] fixed inset-0'
    >
      <div className='py-5 px-[30px] flex justify-between items-center font-medium text-[32px]'>
        {title}
        <IconButton onClick={onClose} size={25}>
          <XMarkSvg />
        </IconButton>
      </div>
      <div className='flex-shrink-0 w-full h-[1px] bg-black'></div>
      <div className='py-5 px-[30px]'>{children}</div>
    </ReactModal>
  );
};

export default ModalForm;
