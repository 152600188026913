import React, { useEffect, useState } from 'react';
import ModalForm from '../../common/ModalForm';
import { TabData } from '../../common/Tab';
import FormTab from '../../common/FormTab';
import StreamDetailsForm from '../details/StreamDetailsForm';
import StreamSourcesForm from '../sources/StreamSourcesForm';
// import StreamAdvancedForm from '../advanced/StreamAdvancedForm';
// import StreamMapForm from '../map/StreamMapForm';
// import StreamEPGForm from '../epg/StreamEPGForm';
// import StreamRTMPushForm from '../rtmPush/StreamRTMPushForm';
// import StreamServersForm from '../servers/StreamServersForm';
import { Stream } from '../../../types/model/stream';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'Details',
    tabComponent: StreamDetailsForm,
  },
  {
    id: 2,
    tabTitle: 'Sources',
    tabComponent: ({ ...props }) => StreamSourcesForm({ ...props, isEdit: true }),
  },
  // {
  //   id: 3,
  //   tabTitle: 'Advanced',
  //   tabComponent: StreamAdvancedForm,
  // },
  // {
  //   id: 4,
  //   tabTitle: 'Map',
  //   tabComponent: StreamMapForm,
  // },
  // {
  //   id: 5,
  //   tabTitle: 'EPG',
  //   tabComponent: StreamEPGForm,
  // },
  // {
  //   id: 6,
  //   tabTitle: 'RTM Push',
  //   tabComponent: StreamRTMPushForm,
  // },
  // {
  //   id: 7,
  //   tabTitle: 'Servers',
  //   tabComponent: ({ ...props }) => StreamServersForm({ ...props, isEdit: true }),
  // },
];

interface Props {
  stream: Stream;
  isOpen: boolean;
  onSubmit: (stream: Stream) => void;
  onClose: () => void;
}

const EditStreamModal = ({ stream, isOpen, onSubmit, onClose }: Props) => {
  const [streamData, setStreamData] = useState<Stream>(stream);

  useEffect(() => {
    setStreamData(stream);
  }, [stream]);

  const submitStreamData = async (data: Stream) => {
    onSubmit(data);
    onClose();
  };
  return (
    <ModalForm
      className='narrow-modal-form'
      isOpen={isOpen}
      onClose={() => onClose()}
      title={'Edit Stream'}
    >
      <FormTab
        data={tabData}
        formData={streamData}
        currentTab={tabData[0].id}
        setFormData={setStreamData}
        submitFormData={submitStreamData}
      />
    </ModalForm>
  );
};

export default EditStreamModal;
