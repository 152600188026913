import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { SubmitFormButton } from '../../common/Buttons';
import SlideToggle from '../../common/SlideToggle';
import DefaultContainer from './DefaultContainer';
import DefaultAspectRatio from './DefaultAspectRatio';
import DefaultThemeType from './DefaultThemeType';
import LegacyTheme from './LegacyTheme';
import Tooltip from '../../common/Tooltip';
import { settingMAGTooltipMessage } from '../../../config/content/tooltipData';

export interface SettingsMAGFormInput {
  showAllCategories: boolean;
  defaultContainer: string;
  alwaysEnabledSubtitles: boolean;
  connectionProblemIndication: boolean;
  showChannelLogos: boolean;
  showPreviewChannelLogos: boolean;
  playbackLimit: number;
  defaultAspectRatio: string;
  defaultThemeType: string;
  legacyTheme: string;
  legacyUrlRedirect: boolean;
  keepUrlExtension: boolean;
  disableSsl: boolean;
  loadChannelsOnStartup: boolean;
  disableMagToken: boolean;
  allowedStbTypes: string;
  allowedStbRecording: string;
  speedTestUrl: string;
  informationMessage: string;
}
interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
}

const SettingsMAGForm = ({ className }: Props) => {
  const { handleSubmit, control } = useForm<SettingsMAGFormInput>({
    defaultValues: {
      showAllCategories: false,
      defaultContainer: 'TS',
      alwaysEnabledSubtitles: false,
      connectionProblemIndication: false,
      showChannelLogos: false,
      showPreviewChannelLogos: false,
      playbackLimit: 3,
      defaultAspectRatio: 'fit',
      defaultThemeType: 'modem',
      legacyTheme: 'default',
      legacyUrlRedirect: false,
      keepUrlExtension: false,
      disableSsl: false,
      loadChannelsOnStartup: false,
      disableMagToken: false,
      allowedStbTypes: '',
      allowedStbRecording: '',
      speedTestUrl: 'https://speed_hetzner.de/100MB.bin',
      informationMessage:
        'You can switch between the modern and legacy themes by using the <span class="label">Green</span> and <span class="label">Yellow</span> buttons on your remote control. Doing so will restart your device.',
    },
  });

  const onSubmit: SubmitHandler<SettingsMAGFormInput> = data => {
    console.log('data: ', data);
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-sub-title'>Preferences</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='showAllCategories'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name text-[20px]'>
                  Show All Categories
                  <Tooltip message={settingMAGTooltipMessage.showAllCategories} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='defaultContainer'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name text-[20px]'>
                  Default Container
                </span>
                <DefaultContainer
                  className='form-item-two-col-card-item-select'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='alwaysEnabledSubtitles'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name text-[20px]'>
                  Always Enabled Subtitles
                  <Tooltip message={settingMAGTooltipMessage.alwaysEnabledSubtitles} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='connectionProblemIndication'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name text-[20px]'>
                  Connection Problem Indication
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='showChannelLogos'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name text-[20px]'>
                  Show Channel Logos
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='showPreviewChannelLogos'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name text-[20px]'>
                  Show Preview Channel Logos
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='playbackLimit'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name text-[20px]'>
                  Playback Limit
                  <Tooltip message={settingMAGTooltipMessage.playbackLimit} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='defaultAspectRatio'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name text-[20px]'>
                  Default Aspect Ratio
                  <Tooltip message={settingMAGTooltipMessage.defaultAspectRatio} />
                </span>
                <DefaultAspectRatio
                  className='form-item-two-col-card-item-select'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='defaultThemeType'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name text-[20px]'>
                  Default Theme Type
                  <Tooltip message={settingMAGTooltipMessage.defaultThemeType} />
                </span>
                <DefaultThemeType
                  className='form-item-two-col-card-item-select'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='legacyTheme'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name text-[20px]'>
                  Legacy Theme
                  <Tooltip message={settingMAGTooltipMessage.legacyTheme} />
                </span>
                <LegacyTheme
                  className='form-item-two-col-card-item-select'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='legacyUrlRedirect'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name text-[20px]'>
                  Legacy URL Redirect
                  <Tooltip message={settingMAGTooltipMessage.legacyUrlRedirect} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='keepUrlExtension'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name text-[20px]'>
                  Keep URL Extension
                  <Tooltip message={settingMAGTooltipMessage.keepUrlExtension} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='disableSsl'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name text-[20px]'>
                  Disable SSL
                  <Tooltip message={settingMAGTooltipMessage.disableSsl} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='loadChannelsOnStartup'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name text-[20px]'>
                  Load Channels on Startup
                  <Tooltip message={settingMAGTooltipMessage.loadChannelsOnStartup} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='disableMagToken'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name text-[20px]'>
                  Disable MAG Token
                  <Tooltip message={settingMAGTooltipMessage.disableMagToken} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
        </div>
        <Controller
          control={control}
          name='allowedStbTypes'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Allowed STB Types</span>
              <div className='form-item-content w-1/2'>
                <input
                  type='text'
                  className='form-item-input text-[16px]'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='allowedStbRecording'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Allowed STB Recording</span>
              <div className='form-item-content w-1/2'>
                <input
                  type='text'
                  className='form-item-input text-[16px]'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='speedTestUrl'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                Speed Test URL
                <Tooltip message={settingMAGTooltipMessage.speedTestUrl} />
              </span>
              <div className='form-item-content w-1/2'>
                <input
                  type='text'
                  className='form-item-input text-[16px]'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='informationMessage'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                Information Message
                <Tooltip message={settingMAGTooltipMessage.informationMessage} />
              </span>
              <div className='form-item-content w-1/2'>
                <TextareaAutosize
                  className='form-item-input text-[16px]'
                  minRows={2}
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />

        <SubmitFormButton className='w-full'>Save</SubmitFormButton>
      </form>
    </div>
  );
};

export default SettingsMAGForm;
