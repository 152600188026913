import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageTab from '../components/common/PageTab';
import { TabData } from '../components/common/Tab';
import AddLineForm from '../components/user/AddLineForm';
import ManageLinesForm from '../components/user/ManageLinesForm';

const UserPage = () => {
  const tabData: TabData[] = [
    {
      id: 1,
      tabTitle: 'Add Line',
      tabComponent: AddLineForm,
    },
    {
      id: 2,
      tabTitle: 'Manage Lines',
      tabComponent: ManageLinesForm,
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = React.useState<number>();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const path = pathSegments[pathSegments.length - 1];
    if (path === 'add-line') setCurrentTab(1);
    if (path === 'manage-lines') setCurrentTab(2);
  }, [location]);

  const onChangeTab = (id: number) => {
    setCurrentTab(id);
    if (id == 1) navigate('/user/add-line');
    if (id == 2) navigate('/user/manage-lines');
  };

  return (
    <>
      {currentTab && <PageTab data={tabData} currentTab={currentTab} onChangeTab={onChangeTab} />}
    </>
  );
};
export default UserPage;
