/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { EyeSvg } from '../assets/svg';
import { SubmitFormButton, WhiteBorderButton } from '../components/common/Buttons';
import { useLogin } from '../hooks/auth/useLogin';
import { useSetAtom } from 'jotai';
import { loadingAtom } from '../store/jotai';

export interface LoginFormInput {
  userName: string;
  password: string;
}

const LoginPage = () => {
  const navigate = useNavigate();
  const { login, loginRes, loginErr } = useLogin();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const { handleSubmit, control } = useForm<LoginFormInput>({
    defaultValues: {
      userName: '',
      password: '',
    },
  });

  const setLoading = useSetAtom(loadingAtom);

  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (loginErr || loginRes) {
      setLoading(false);
    }
    if (loginRes) {
      const redirect = searchParams.get('redirect');
      if (redirect) {
        navigate(redirect);
      } else {
        navigate('/dashboard');
      }
    }
  }, [loginRes, navigate, loginErr, setLoading]);

  const onSubmit: SubmitHandler<LoginFormInput> = data => {
    setLoading(true);
    login(data.userName, data.password);
  };
  return (
    <div
      className={`absolute inset-0 p-[3%] flex flex-col justify-evenly items-center bg-blend-soft-light bg-[77%_auto] [background:linear-gradient(180deg,rgba(7,7,30,0.2)_0%,rgba(43,43,75,0.2)_100%),#13132c_url('/public/LOGO.png')_no-repeat_center_center]`}
    >
      <div className='flex justify-center items-center'>
        <img src='/LOGO.png' alt='logo' className='h-[72px]' />
      </div>
      <div className='card w-[625px] max-w-full px-[70px] py-[30px] flex flex-col'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name='userName'
            rules={{ required: true }}
            render={({ field }) => (
              <div className='form-item'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder='Username'
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='password'
            rules={{ required: true }}
            render={({ field }) => (
              <div className='form-item'>
                <input
                  type={isShowPassword ? 'text' : 'password'}
                  className='form-item-input'
                  autoComplete='off'
                  placeholder='Password'
                  {...field}
                />
                <WhiteBorderButton
                  onClick={() => {
                    setIsShowPassword(!isShowPassword);
                  }}
                >
                  <EyeSvg />
                </WhiteBorderButton>
              </div>
            )}
          />
          <div className='form-item'>
            <SubmitFormButton className='w-full gap-3 '>Login</SubmitFormButton>
          </div>
        </form>
      </div>
      <div className='h-[72px]'></div>
    </div>
  );
};

export default LoginPage;
