import React from 'react';
import Select from 'react-select';
import { settingSelectStyles } from '../../../styles/reactSelect';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';

const defaultAspectRatioOptions: CommonSelectItem[] = [
  { value: 'fit', label: 'fit' },
  { value: 'big', label: 'big' },
  { value: 'opt', label: 'opt' },
  { value: 'exp', label: 'exp' },
  { value: 'cmb', label: 'cmb' },
];

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const DefaultAspectRatio = ({ className, value, onChange }: Props) => {
  return (
    <div className={className}>
      <Select<CommonSelectItem>
        className='form-item-select'
        options={defaultAspectRatioOptions}
        styles={settingSelectStyles}
        value={
          defaultAspectRatioOptions[
            defaultAspectRatioOptions.findIndex(option => option.value === value)
          ]
        }
        onChange={newValue => onChange(newValue?.value ?? '')}
      />
    </div>
  );
};

export default DefaultAspectRatio;
