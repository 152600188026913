/* eslint-disable react-hooks/exhaustive-deps */
import useRequest from '../../lib/hooks/useRequest';
import { useEffect } from 'react';
import { useBouquets } from './useBouquets';
import { editBouquet } from '../../lib/api';
import { useSetAtom } from 'jotai';
import { loadingAtom } from '../../store/jotai';

export const useEditBouquet = () => {
  const [_editBouquet, loading, _editBouquetRes, _editBouquetErr] = useRequest(editBouquet);
  const { getAllBouquets: getAllBouquets, completed } = useBouquets();
  const setLoading = useSetAtom(loadingAtom);

  useEffect(() => {
    if (_editBouquetRes) {
      setLoading(false);
      getAllBouquets();
    }
  }, [_editBouquetRes, getAllBouquets]);

  useEffect(() => {
    if (_editBouquetErr) {
      setLoading(false);
      console.log('_getAllBouquetsErr: ', _editBouquetErr);
    }
  }, [_editBouquetErr]);
  return {
    editBouquet: (data: unknown) => {
      setLoading(true);
      return _editBouquet(data);
    },
    editBouquetRes: _editBouquetRes,
    loading,
    completed,
    editBouquetErr: _editBouquetErr
  };
};
