/* eslint-disable react-hooks/exhaustive-deps */
import useRequest from '../../lib/hooks/useRequest';
import { editCategory } from '../../lib/api';
import { useEffect } from 'react';
import { useCategories } from './useCategories';
import { useSetAtom } from 'jotai';
import { loadingAtom } from '../../store/jotai';

export const useEditCategory = () => {
  const [_editCategory, loading, _editCategoryRes, _editCategoryErr] = useRequest(editCategory);
  const { getAllCategories, completed } = useCategories();
  const setLoading = useSetAtom(loadingAtom);

  useEffect(() => {
    if (_editCategoryRes) {
      setLoading(false);
      getAllCategories();
    }
  }, [_editCategoryRes, getAllCategories, setLoading]);

  useEffect(() => {
    if (_editCategoryErr) {
      setLoading(false);
      console.log('_getAllCategoriesErr: ', _editCategoryErr);
    }
  }, [_editCategoryErr]);
  return {
    editCategory: (data: unknown) => {
      setLoading(true);
      return _editCategory(data);
    },
    editCategoryRes: _editCategoryRes,
    loading,
    completed,
  };
};
