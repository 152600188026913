import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { CommonSelectItem } from '../../types/model/commonSelectItem';
import FormTitle from '../common/FormTitle';
import ManageTable from '../common/ManageTable';
import { CellProps, Column } from 'react-table';
import { headerSelectStyles } from '../../styles/reactSelect';
import { CopySvg, EditSvg, ListUlSvg, XMarkCircleSvg } from '../../assets/svg';
import { IconSimpleButton } from '../common/Buttons';
import ModalWarning from '../common/ModalWarning';
import Paginator from '../common/Paginator';
import { useAtomValue } from 'jotai';
import { bouquetsAtom } from '../../store/jotai';
import { Bouquet } from '../../types/model/bouquet';
import EditBouquetModal from './modal/EditBouquetModal';
import { useDeleteBouquet } from '../../hooks/bouquet/useDeleteBouquet';
import { cn } from '../../utils/tw-merge';

const showOptions: CommonSelectItem[] = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '250', label: '250' },
  { value: '500', label: '500' },
  { value: '1000', label: '1000' },
];

interface Props {
  className?: string;
}

const ManageBouquetForm = ({ className }: Props) => {
  const [selectedBouquet, setSelectedBouquet] = useState<Bouquet>();
  const { deleteBouquet, deleteBouquetRes } = useDeleteBouquet();
  const bouquets = useAtomValue(bouquetsAtom);
  const [showEditMoal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNumber, setShowNumber] = useState<CommonSelectItem | null>(showOptions[0]);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<Bouquet[]>(bouquets.slice(0, Number(showNumber?.value ?? 10)));
  useEffect(() => {
    setData(
      bouquets.slice(
        (page - 1) * Number(showNumber?.value ?? 10),
        page * Number(showNumber?.value ?? 10),
      ),
    );
  }, [page, bouquets, showNumber?.value]);
  useEffect(() => {
    if (deleteBouquetRes) {
      console.log('deleteBoquetRes: ', deleteBouquetRes);
    }
  }, [deleteBouquetRes]);

  const onConfirmDeleteBouquet = () => {
    setShowDeleteModal(false);
    deleteBouquet(selectedBouquet?.id ?? '');
  };

  const onCloseEditModal = () => {
    setShowEditModal(false);
  };
  const renderNumber = (cell: CellProps<Bouquet, number | undefined>): JSX.Element => (
    <div
      className={`text-dark items-center justify-center flex rounded-lg py-0 px-[9px] w-fit h-7 my-0 mx-auto text-[16px] ${
        cell.value ? 'bg-[#7bffa5]' : 'bg-white'
      }`}
    >
      {cell.value ?? 0}
    </div>
  );

  const renderActions = (cell: CellProps<Bouquet>): JSX.Element => {
    const bouquet = cell.row.original;
    return (
      <div className='h-full w-full max-w-[400px] bg-dark border-b border-solid border-placeholder-dark flex justify-around items-center gap-[10px]'>
        <IconSimpleButton size={28} onClick={() => console.log('clicked')}>
          <ListUlSvg />
        </IconSimpleButton>
        <IconSimpleButton size={28} onClick={() => openEditModal(bouquet)}>
          <EditSvg style={{ height: '18px', width: '18px' }} />
        </IconSimpleButton>
        <IconSimpleButton size={28} onClick={() => console.log('clicked')}>
          <CopySvg />
        </IconSimpleButton>
        <IconSimpleButton size={28} onClick={() => openDeleteModal(bouquet)}>
          <XMarkCircleSvg />
        </IconSimpleButton>
      </div>
    );
  };
  const bouquetHeader: Column<Bouquet>[] = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Bouquet Name',
      accessor: 'name',
    },
    {
      Header: 'Streams',
      accessor: 'streams',
      Cell: renderNumber,
    },
    {
      Header: 'Movies',
      accessor: 'movies',
      Cell: renderNumber,
    },
    {
      Header: 'Series',
      accessor: 'series',
      Cell: renderNumber,
    },
    {
      Header: 'Stations',
      accessor: 'stations',
      Cell: renderNumber,
    },
    {
      Header: 'Actions',
      Cell: renderActions,
    },
  ];

  const deleteMessage = 'Are you sure you want to delete this bouquet?';

  const columns: Column<Bouquet>[] = bouquetHeader;

  const openDeleteModal = (bouquet: Bouquet) => {
    setShowDeleteModal(true);
    setSelectedBouquet(bouquet);
  };

  const openEditModal = (bouquet: Bouquet) => {
    setShowEditModal(true);
    setSelectedBouquet(bouquet);
  };

  return (
    <div className={cn('w-full max-w-[2500px] mt-[30px] mb-0 mx-auto', className)}>
      <FormTitle>Manage Bouquets</FormTitle>
      <div className='my-10 mx-0 flex justify-between items-center gap-[25px]'>
        <div className='flex-auto max-w-[1000px] flex justify-between items-center gap-1'>
          <input
            type='search'
            className='grow max-w-[280px] min-w-[200px] py-[11px] px-3 rounded-[19px] bg-dark text-[14px] text-form-content'
            autoComplete='on'
            placeholder='Search Streams...'
          />
        </div>
        <div className='flex items-center gap-4'>
          <span className='text-[14px] text-form-content'>Show</span>
          <Select<CommonSelectItem>
            className='shrink-0 w-[100px] text-[14px] text-form-content grow max-w-[280px] [&:nth-child(-n+4)]:min-w-[220px] [&:nth-child(n+5)]:min-w-[170px]'
            options={showOptions}
            styles={headerSelectStyles}
            value={showNumber}
            onChange={value => {
              setShowNumber(value);
            }}
          />
        </div>
      </div>
      <div className='w-full overflow-auto'>
        <ManageTable<Bouquet> columns={columns} data={data} />
      </div>
      <Paginator
        page={page}
        totalRecords={bouquets.length}
        pageSize={Number(showNumber?.value ?? 10)}
        setPage={setPage}
      />

      <ModalWarning
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        noIconOnOk
        nextButtonTitle='Delete'
        handleCancel={() => setShowDeleteModal(false)}
        handleOk={onConfirmDeleteBouquet}
      >
        {deleteMessage}
      </ModalWarning>
      {selectedBouquet && (
        <EditBouquetModal
          bouquet={selectedBouquet}
          isOpen={showEditMoal}
          onClose={onCloseEditModal}
        />
      )}
    </div>
  );
};

export default ManageBouquetForm;
