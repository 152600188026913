import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '../utils/tw-merge';
import FormTitle from '../components/common/FormTitle';
import { CommonSelectItem } from '../types/model/commonSelectItem';
import { useAtomValue } from 'jotai';
import Flag from 'react-world-flags';
import { connectionsAtom } from '../store/jotai';
import { useConnections } from '../hooks/user/useConnections';
import Paginator from '../components/common/Paginator';
import ModalWarning from '../components/common/ModalWarning';
import { Connection } from '../types/model/user';
import { useKillConnection } from '../hooks/user/useKillConnection';
import { Column } from 'react-table';
import { CellProps } from 'react-table';
import { PopupActions } from 'reactjs-popup/dist/types';
import Popup from 'reactjs-popup';
import ManageTable from '../components/common/ManageTable';
import Select from 'react-select';
import { headerSelectStyles } from '../styles/reactSelect';
import intervalToDurationStr from '../utils/intervalToDurationStr';
import { countriesList } from '../config/constant/countriesList';
import moment from 'moment';

const killConnectionMessage =
  'Are you sure you want to kill this connctoin? Users using this connection will be disconnected.';

const showOptions: CommonSelectItem[] = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '250', label: '250' },
  { value: '500', label: '500' },
  { value: '1000', label: '1000' },
];

const ConnectionsPage = ({ className }: { className?: string }) => {
  const connections = useAtomValue(connectionsAtom);
  const [params, setParams] = useState<Record<string, string>>();

  const modifyParams = useCallback((param: string, value?: string, order?: 'asc' | 'desc') => {
    setParams(prevParams => {
      const updatedParams = { ...prevParams };
      if (order) {
        if (value === '' || value === undefined || (value === 'id' && order === 'asc')) {
          delete updatedParams.sortBy;
          delete updatedParams.order;
        } else {
          updatedParams.sortBy = value;
          updatedParams.order = order;
        }
      } else if (value === '0' || !value || value === '') {
        if (param === 'search' && value === '0') {
          updatedParams[param] = value;
        } else {
          delete updatedParams[param];
        }
      } else {
        updatedParams[param] = value;
      }
      return updatedParams;
    });
  }, []);

  const { getAllConnections } = useConnections(false);
  const { killConnection } = useKillConnection();
  const [showNumber, setShowNumber] = useState<CommonSelectItem | null>(showOptions[0]);
  const [page, setPage] = useState(1);
  const [showKillConnectionModal, setShowKillConnectionModal] = useState(false);
  useEffect(() => {
    const timer = setInterval(() => {
      getAllConnections(page, Number(showNumber?.value ?? 10), params);
    }, 10000);
    getAllConnections(page, Number(showNumber?.value ?? 10), params);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, showNumber, params]);

  const selectedConnectionRef = useRef<Connection | null>(null);

  const onConfirmKillConnection = () => {
    killConnection(selectedConnectionRef.current);
  };

  const columns: Column<Connection>[] = useMemo(
    () =>
      getConnectionsHeader(
        (connection: Connection) => (selectedConnectionRef.current = connection),
        onConfirmKillConnection,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div className='card px-5 py-[18px]'>
      <div className={cn('w-full max-w-[2500px] mt-[30px] mb-0 mx-auto', className)}>
        <FormTitle>Manage Connections</FormTitle>
        <div className='flex justify-between items-center gap-[25px] mx-0 my-10'>
          <div className='flex-auto max-w-[1000px] flex justify-between items-center gap-1'>
            <input
              type='search'
              className='grow max-w-[280px] min-w-[200px] bg-dark text-sm text-form-content px-3 py-[11px] rounded-[19px]'
              autoComplete='on'
              placeholder='Search Connections...'
              onChange={e => {
                modifyParams('search', e.target.value);
              }}
            />
          </div>
          <div className='flex items-center gap-4'>
            <span className='text-[14px] text-form-content'>Show</span>
            <Select<CommonSelectItem>
              className='shrink-0 w-[100px] text-[14px] text-form-content'
              options={showOptions}
              styles={headerSelectStyles}
              value={showNumber}
              onChange={value => {
                setShowNumber(value);
              }}
            />
          </div>
        </div>
        <div className='w-full overflow-auto'>
          <ManageTable<Connection>
            columns={columns}
            data={connections.Records ?? []}
            totalRecords={connections.TotalRecords}
            onSort={(columnId, isDesc) => {
              modifyParams('sortBy', columnId, isDesc ? 'desc' : 'asc');
            }}
            type='Connection'
          />
        </div>
        <Paginator
          page={page}
          totalRecords={connections.TotalRecords}
          pageSize={Number(showNumber?.value ?? 10)}
          setPage={setPage}
        />
        <ModalWarning
          isOpen={showKillConnectionModal}
          onClose={() => setShowKillConnectionModal(false)}
          noIconOnOk
          nextButtonTitle='Kill'
          handleCancel={() => setShowKillConnectionModal(false)}
          handleOk={onConfirmKillConnection}
        >
          {killConnectionMessage}
        </ModalWarning>
      </div>
    </div>
  );
};

const getConnectionsHeader: (
  onSelectConnection: (connection: Connection) => void,
  onKillConnection: () => void,
) => Column<Connection>[] = (onSelectConnecton, onKillConnecton) => [
  {
    Header: 'Username',
    accessor: 'username',
    disableSortBy: true,
  },
  {
    Header: 'ASN Name',
    accessor: 'asn_name',
    disableSortBy: true,
  },
  {
    Header: 'ASN Number',
    accessor: 'asn_number',
    id: 'asn',
  },
  {
    Header: 'User Agent',
    accessor: 'user_agent',
    id: 'useragent',
    maxWidth: 100,
    cell: (cell: CellProps<Connection, string>) => <div className='break-words'> {cell.value}</div>,
  },
  {
    Header: 'Channel',
    accessor: 'channel_name',
    disableSortBy: true,
  },
  {
    Header: 'IP',
    accessor: 'ip',
    disableSortBy: true,
  },
  {
    Header: 'Country',
    accessor: 'country',
    Cell: renderCountry,
  },
  {
    Header: 'ISP',
    accessor: 'isp',
  },
  {
    Header: <div className='whitespace-nowrap'>Start time</div>,
    accessor: 'start_time',
    id: 'start',
    Cell: (cell: CellProps<Connection, number>) => {
      const dateStr = moment(new Date(cell.value * 1000)).format('DD-MM-yyyy hh:mm');
      return <div className='w-20'>{dateStr}</div>;
    },
  },
  {
    Header: <div className='whitespace-nowrap'>Watch Time</div>,
    accessor: 'start_time',
    id: 'total',
    Cell: (cell: CellProps<Connection, number>) => (
      <div
        style={{
          margin: '0 auto',
          height: '28px',
          width: '88px',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#7bffa5',
          fontSize: '12px',
          color: '#13132c',
        }}
      >
        {intervalToDurationStr(cell.value)}
      </div>
    ),
  },
  {
    Header: 'Actions',
    disableSortBy: true,
    Cell: (cell: CellProps<Connection, string>) =>
      RenderPopup(cell, onSelectConnecton, onKillConnecton),
  },
];

const renderCountry = (cell: CellProps<Connection, string>) => {
  return (
    <div className='flex items-center ml-2 gap-1 font-bold'>
      <Flag
        className='h-6 w-8'
        code={
          countriesList.find(
            country =>
              country.value.toLowerCase() === cell.value.toLowerCase() ||
              country.otherName?.toLowerCase() === cell.value.toLowerCase() ||
              country.label.toLowerCase() === cell.value.toLowerCase(),
          )?.value ?? cell.value
        }
        fallback={<span>Unknown</span>}
      />
      <div className='whitespace-nowrap'>{cell.value}</div>
    </div>
  );
};

const RenderPopup = (
  cell: CellProps<Connection, string>,
  onSelectConnection: (user: Connection) => void,
  onKillConnection: () => void,
): JSX.Element => {
  const connection = cell.row.original;
  const popupRef = useRef<PopupActions | null>(null);

  const _onKillConnection = () => {
    popupRef.current?.close();
    onKillConnection();
  };

  return (
    <Popup
      ref={popupRef}
      trigger={
        <div className='py-1.5 px-0 rounded-[16px] bg-dark text-[16px] [box-shadow:0px_2px_13px_1px_#1e1e3f] hover:[filter:brightness(2)] cursor-pointer focus:outline-offset-0 focus:[white_solid_2px] active:[box-shadow:0px_2px_13px_0px_#1e1e3f] active:opacity-70'>
          {' '}
          Actions{' '}
        </div>
      }
      position='left center'
      on='click'
      closeOnDocumentClick
      onOpen={() => onSelectConnection(connection)}
      closeOnEscape
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      arrow={false}
    >
      {popupBody(_onKillConnection)}
    </Popup>
  );
};

const popupBody = (onKillConnection: () => void) => {
  return (
    <div className='popup-menu'>
      <div className='popup-menu-item' onClick={onKillConnection}>
        Kill Connection
      </div>
    </div>
  );
};

export default ConnectionsPage;
