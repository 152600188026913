/* eslint-disable react-hooks/exhaustive-deps */
import useRequest from '../../lib/hooks/useRequest';
import { killConnection } from '../../lib/api';
import { isAxiosError } from 'axios';
import { useEffect } from 'react';
import { useConnections } from './useConnections';

export const useKillConnection = () => {
  const { getAllConnections } = useConnections(false);
  const [_killConnection, , _killConnectionRes, _killConnectionErr] = useRequest(killConnection);

  // success killing of connection
  useEffect(() => {
    if (_killConnectionRes) {
      getAllConnections();
    }
  }, [_killConnectionRes]);

  // checking for the error
  useEffect(() => {
    if (_killConnectionErr) {
      if (isAxiosError(_killConnectionErr)) {
        console.log('_killConnectionErrText: ', _killConnectionErr.response?.data.error);
        console.log('_killConnectionErrMessage: ', _killConnectionErr.response?.data.message);
      } else {
        console.log('_killConnectionErr: ', _killConnectionErr);
      }
    }
  }, [_killConnectionErr]);

  return {
    killConnection: _killConnection,
    killConnectionErr: _killConnectionErr,
    killConnectionRes: _killConnectionRes,
  };
};
