/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, useEffect } from 'react';
import { AxiosError, AxiosPromise, isAxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

type PromiseCreator<R> = (...params: any[]) => AxiosPromise<R>;

type UseRequestReturnType<R> = [
  (...args: any[]) => void,
  boolean,
  R | null,
  Error | AxiosError | null,
  any | null,
  () => void,
];

export default function useRequest<R = any>(
  promiseCreator: PromiseCreator<R>,
): UseRequestReturnType<R> {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<R | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [status, setStatus] = useState<any | null>(null);

  const navigate = useNavigate();
  const onRequest = useCallback(
    async (...params: any[]) => {
      try {
        setLoading(true);
        const response = await promiseCreator(...params);
        setData(response.data);
        setStatus(response.status);
      } catch (e) {
        setError(e as any);
        setStatus(null);
        // Disable throwing error
        // throw e;
      } finally {
        setLoading(false);
      }
    },
    [promiseCreator],
  );

  const currentLocation = window.location.pathname;

  useEffect(() => {
    if (isAxiosError(error) && error.response?.status === 401) {
      const redirectPath = currentLocation !== '/dashboard' ? `?redirect=${currentLocation}` : '';
      navigate(`/${redirectPath}`);
    }
  }, [error, currentLocation]);

  const onReset = () => {
    setLoading(false);
    setData(null);
    setError(null);
    setStatus(null);
  };

  return [onRequest, loading, data, error, status, onReset];
}
