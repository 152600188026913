import { useAtomValue } from 'jotai';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import { bouquetsAtom, categoriesAtom } from '../../../store/jotai';
import FormFooter from '../../common/FormFooter';
import Bouquets, { getBouquetsOptions } from './Bouquets';
import Categories, { getCategoriesOptions } from './Categories';
import { StreamDetailsFormInput as ImportStreamDetailsFormInput } from './StreamDetailsForm';
import { ImportStreamFormData } from '../../../types/model/stream';

interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
  formData?: ImportStreamFormData;
  setFormData?: React.Dispatch<React.SetStateAction<ImportStreamFormData>>;
}

const ImportStreamDetailsForm = ({ className, setVisibleTab, formData, setFormData }: Props) => {
  const categories = useAtomValue(categoriesAtom);
  const bouquets = useAtomValue(bouquetsAtom);
  const { handleSubmit, control, getValues } = useForm<ImportStreamDetailsFormInput>({
    defaultValues: {
      file: formData?.file ?? undefined,
      categories: formData?.categories ? getCategoriesOptions(categories, formData.categories) : [],
      bouquets: formData?.bouquets ? getBouquetsOptions(bouquets, formData.bouquets) : [],
      notes: formData?.notes ?? '',
    },
  });

  // If setFormData before unmount
  useEffect(() => {
    return () => {
      const data = getValues();
      if (setFormData) {
        setFormData(currentLine => ({
          ...currentLine,
          file: data.file,
          notes: data.notes,
          categories: data.categories.map(category => category.id),
          bouquets: data.bouquets.map(bouquet => bouquet.id),
        }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<ImportStreamDetailsFormInput> = (
    data: ImportStreamDetailsFormInput,
  ) => {
    if (setVisibleTab) setVisibleTab(2);
    console.log(data);
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name='file'
          rules={{ required: true }}
          render={({ field: { onChange, ...field } }) => (
            <div className='form-item'>
              <span className='form-item-name'>M3U</span>
              <div className='form-item-content'>
                <input
                  {...field}
                  type='file'
                  className='w-full p-2 rounded text-[20px] [transition:border-color_0.15s_ease-in-out_box] focus:outline-none focus:border-[#80bdff] focus:shadow-[0_0_0_0.2rem_rgb(0_123_255_/_25%)]'
                  value={undefined}
                  onChange={event => {
                    onChange(event.target.files ? event.target.files['0'] : null);
                  }}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='categories'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Categories</span>
              <Categories className='form-item-content [&>div]:w-full' field={field} />
            </div>
          )}
        />
        <Controller
          control={control}
          name='bouquets'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Bouquets</span>
              <Bouquets className='form-item-content [&>div]:w-full' field={field} />
            </div>
          )}
        />
        <Controller
          control={control}
          name='notes'
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Notes</span>
              <div className='form-item-content'>
                <TextareaAutosize
                  className='form-item-input'
                  minRows={2}
                  autoComplete='off'
                  placeholder='_'
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <FormFooter hidePrev />
      </form>
    </div>
  );
};

export default ImportStreamDetailsForm;
