import React, { useEffect, useState, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CircleExclamationSvg } from '../../assets/svg';
import FormFooter from '../common/FormFooter';
import DragAndDropCard, { DndData } from '../common/DragAndDropCard';
import { useAtomValue } from 'jotai';
import { categoriesAtom } from '../../store/jotai';
import { Category } from '../../types/model/category';
import { useDeleteCategory } from '../../hooks/category/useDeleteCategory';
import EditCategoryModal from './modal/EditCategoryModal';

export interface CategoriesStreamsFormInput {
  streams: DndData[];
}

interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
}

const CategoriesStreamsForm = ({ className }: Props) => {
  const categories = useAtomValue(categoriesAtom);
  const dndData: DndData[] = useMemo(
    () =>
      categories?.map((category: Category) => ({
        id: `${category.id}`,
        label: category.name,
        description: category.type,
      })),
    [categories],
  );

  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [showEditMoal, setShowEditModal] = useState(false);
  const { deleteCategory, deleteCategoryRes } = useDeleteCategory();
  const deleteMessage =
    'Are you sure you want to delete this category? All streams attached will be uncategorized.';
  const warningMessage = (
    <p>
      To re-order a category, drag it up or down the list using the &#8803; icon. Click Save Changes
      at the bottom once finished.
    </p>
  );

  const { handleSubmit, control, setValue } = useForm<CategoriesStreamsFormInput>({
    defaultValues: {
      streams: dndData,
    },
  });

  const onSubmit: SubmitHandler<CategoriesStreamsFormInput> = data => {
    console.log('data: ', data);
    // Todo: update categories order
  };

  useEffect(() => {
    if (deleteCategoryRes) {
      setValue('streams', dndData);
    }
  }, [deleteCategoryRes, dndData, setValue]);

  const onDelete = (selectedItemId: string) => {
    deleteCategory(Number(selectedItemId));
  };

  const onEdit = (selectedItem: DndData) => {
    setSelectedCategory(categories.find(category => category.id === Number(selectedItem.id)));
    setShowEditModal(true);
  };

  const onCloseEditModal = () => {
    setShowEditModal(false);
    setValue('streams', dndData);
  };
  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-warning element-card'>
          <CircleExclamationSvg style={{ flexShrink: 0 }} />
          {warningMessage}
        </div>

        <Controller
          control={control}
          name='streams'
          rules={{ required: false }}
          render={({ field }) => (
            <DragAndDropCard
              value={field.value}
              onChange={field.onChange}
              deleteMessage={deleteMessage}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          )}
        />

        <FormFooter hidePrev noIconOnNext nextButtonTitle='Save Changes' />
      </form>
      {selectedCategory && (
        <EditCategoryModal
          category={selectedCategory}
          isOpen={showEditMoal}
          onClose={onCloseEditModal}
        />
      )}
    </div>
  );
};

export default CategoriesStreamsForm;
