import React from 'react';
import { dashboardSettingItems } from '../config/data/settingData';
import { stateItems } from '../config/data/stateData';
import DashboardHeader from '../components/dashboard/DashboardHeader';
import SettingItem from '../components/dashboard/SettingItem';
import DashboardStateItem from '../components/dashboard/DashboardStateItem';
import { serverStatusItems } from '../config/data/serverStatusData';
import StatusTableCard from '../components/dashboard/StatusTableCard';

const Dashboard = () => {
  return (
    <div className='w-full'>
      <div className='w-full py-[17px] px-[25px] grid [grid-template-columns:1fr] xl:[grid-template-columns:repeat(2,1fr)] 2xl:[grid-template-columns:repeat(4,1fr)] gap-y-5 gap-x-[30px] card'>
        {dashboardSettingItems.map((settingItem, index) => (
          <SettingItem key={index} data={settingItem} />
        ))}
      </div>
      <div className='mt-5 py-5 px-[30px] flex flex-col justify-start items-stretch gap-[27px] card'>
        <DashboardHeader />
        <div className='w-full p-0 grid xl:[grid-template-columns:repeat(3,1fr)] lg:[grid-template-columns:repeat(2,1fr)] [grid-template-columns:1fr] 2xl:[grid-template-columns:repeat(6,1fr)] gap-5'>
          {stateItems.map((stateItem, index) => (
            <DashboardStateItem
              key={index}
              data={stateItem}
              borderColor={stateItemBorderColor[index]}
            />
          ))}
        </div>
        <div className='w-full p-0 grid gap-y-5 gap-x-[30px] [grid-template-columns:1fr] 2xl:[grid-template-columns:repeat(2,1fr)]'>
          {/* <StatusChartCard className='status-item' /> */}
          {serverStatusItems.map((serverStatusItem, index) => (
            <StatusTableCard
              key={index}
              data={serverStatusItem}
              className='w-full h-[250px] rounded-lg [background:linear-gradient(269.58deg,#1e1e3f_0.41%,#232349_97.34%)]'
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const stateItemBorderColor: [string, string][] = [
  ['#63aafdc0', '#63aafd40'],
  ['#6F47EAc0', '#63AAFD40'],
  ['#B73BF5c0', '#FB8CBD40'],
  ['#B538F7c0', '#63AAFD40'],
  ['#FF9696c0', '#FF2D2D40'],
  ['#FF2E2Cc0', '#FFAB2D40'],
];

export default Dashboard;
