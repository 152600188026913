import React from 'react';
import { CheckGreenSvg, XMarkRedSvg } from '../../assets/svg';
import { DashboardSettingItem } from '../../types/settingData';

interface Props {
  data: DashboardSettingItem;
}

const SettingItem = ({ data }: Props) => {
  return (
    <div
      className='w-full h-[90px] rounded-md p-5 flex flex-row justify-between items-center gap-[2px]'
      style={{
        background: data.background,
      }}
    >
      <div
        className='shrink-0 w-[42px] h-[42px] rounded-[50%] flex justify-center items-center'
        style={{
          background: data.color,
        }}
      >
        <data.icon />
      </div>
      <div className='flex flex-col justify-between items-start'>
        <p className='text-[20px]'>{data.title}</p>
        <p className='text-[12px]'>{data.content}</p>
      </div>
      <div className='flex flex-row justify-between items-center gap-[1px]'>
        <div className='w-[33px] h-[27px] pl-[10px] rounded-l-[13.5px] bg-white flex justify-start items-center'>
          <XMarkRedSvg />
        </div>
        <div className='w-[33px] h-[27px] pr-[10px] rounded-r-[13.5px] bg-[#f4f4f4] flex justify-end items-center'>
          <CheckGreenSvg />
        </div>
      </div>
    </div>
  );
};


export default SettingItem;
