import React from 'react';

const Page404 = () => {
  return (
    <div className='h-screen w-full items-center justify-center flex text-lg font-semibold'>
      Page404
    </div>
  );
};

export default Page404;
