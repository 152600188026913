import React from 'react';
import Select from 'react-select';
import { settingSelectStyles } from '../../../styles/reactSelect';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';

const channelSortingTypeOptions: CommonSelectItem[] = [
  { value: 'bouquet', label: 'Bouquet' },
  { value: 'legacy', label: 'Legacy' },
  { value: 'manual', label: 'Manual' },
];

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const ChannelSortingType = ({ className, value, onChange }: Props) => {
  return (
    <div className={className}>
      <Select<CommonSelectItem>
        className='form-item-select'
        options={channelSortingTypeOptions}
        styles={settingSelectStyles}
        value={
          channelSortingTypeOptions[
            channelSortingTypeOptions.findIndex(option => option.value === value)
          ]
        }
        onChange={newValue => onChange(newValue?.value ?? '')}
      />
    </div>
  );
};

export default ChannelSortingType;
