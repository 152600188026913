import React from 'react';
import { StateItem } from '../types/stateData';
import { serverStatsAtom } from '../store/jotai';
import { useAtomValue } from 'jotai';

interface Props {
  className?: string;
  stateItem: StateItem;
}

const StateItemElement = ({ className, stateItem }: Props) => {
  const serverStats = useAtomValue(serverStatsAtom);

  let value: number;
  let label: string;
  switch (stateItem.id) {
    case 'onlineConnections':
      value = serverStats.total_connections ?? -1;
      label = 'CONNECTIONS';
      break;
    case 'activeLines':
      value = serverStats.connected_users ?? -1;
      label = 'ACTIVE LINES';
      break;
    case 'liveStreams':
      value = serverStats.total_running_streams ?? -1;
      label = 'LIVE STREAMS';
      break;
    case 'downStreams':
      value = (serverStats.total_streams ?? 0) - (serverStats.total_running_streams ?? 0);
      label = 'DOWN STREAMS';
      break;
    case 'networkInput':
      value = serverStats.input_speed ?? -1;
      label = 'NETWORK UP';
      break;
    case 'networkOutput':
      value = serverStats.output_speed ?? -1;
      label = 'NETWORK DOWN';
      break;
    default:
      value = -1;
      label = '';
  }
  return (
    <div className={className}>
      <div className='flex flex-row justify-center items-center gap-5'>
        <stateItem.icon className='h-5' />
        <p className='w-[30px] text-center'>{value}</p>
      </div>
      <p className='text-[10px] text-[#4c4c6c] text-center pt-[5px]'>{label}</p>
    </div>
  );
};

export default StateItemElement;
