/* eslint-disable react-hooks/exhaustive-deps */
import useRequest from '../../lib/hooks/useRequest';
import { deleteCategory } from '../../lib/api';
import { useEffect } from 'react';
import { useCategories } from './useCategories';
import { useSetAtom } from 'jotai';
import { loadingAtom } from '../../store/jotai';

export const useDeleteCategory = () => {
  const [_deleteCategory, loading, _deleteCategoryRes, _deleteCategoryErr] =
    useRequest(deleteCategory);
  const { getAllCategories, getAllCategoriesRes } = useCategories();
  const setLoading = useSetAtom(loadingAtom);

  useEffect(() => {
    if (_deleteCategoryRes) {
      getAllCategories();
      setLoading(false);
    }
  }, [_deleteCategoryRes, getAllCategories]);

  useEffect(() => {
    if (_deleteCategoryErr) {
      setLoading(false);
      console.log('_getAllCategoriesErr: ', _deleteCategoryErr);
    }
  }, [_deleteCategoryErr]);
  return {
    deleteCategory: (data: unknown) => {
      setLoading(true);
      return _deleteCategory(data);
    },
    deleteCategoryRes: getAllCategoriesRes,
    loading,
  };
};
