import React from 'react';
import Tab, { TabData } from './Tab';

interface Props {
  data: TabData[];
  currentTab: number;
  onChangeTab: (id: number) => void;
}

const PageTab = ({ data, currentTab, onChangeTab }: Props) => {
  return (
    <div className='card w-full min-h-full'>
      <Tab
        data={data}
        currentTab={currentTab}
        onChangeTab={onChangeTab}
        className='py-[18px] px-5'
        tabTitleBarStyle='w-fit rounded-lg bg-dark p-[2px] flex flex-row justify-start items-center text-center cursor-pointer [box-shadow:inset_0px_4px_4px_rgba(0,0,0,0.25)]'
        tabTitleStyle='rounded-lg py-[10px] px-[14px] text-[16px] hover:[background:radial-gradient(100%_100%_at_50.63%_0%,rgba(151,71,255,0.16)_0%,rgba(151,71,255,0)_100%),radial-gradient(45.01%_84.62%_at_50.63%_100%,rgba(151,71,255,0.6)_0%,rgba(151,71,255,0)_100%),rgba(255,255,255,0.1)] hover:[box-shadow:0px_4px_7px_rgba(0,0,0,0.47),0px_5px_11px_rgba(151,71,255,0.22)] active:bg-[radial-gradient(50.08%_76.92%_at_50.63%_0%,_#000000_0%,_rgba(0,0,0,0)_100%),rgba(61,60,60,0.2)] active:[box-shadow:inset_0px_9px_10px_rgba(0,0,0,0.25)]'
        tabTitleActiveStyle='[background:radial-gradient(_45.01%_84.62%_at_50.63%_100%,_rgba(151,_71,_255,_0.6)_0%,_rgba(151,_71,_255,_0)_100%_)] [filter:drop-shadow(0px_4px_4px_rgba(0,_0,_0,_0.07))]'
        tabContentStyle=''
      />
    </div>
  );
};

export default PageTab;
