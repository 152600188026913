import React from 'react';
import Select from 'react-select';
import { settingSelectStyles } from '../../../styles/reactSelect';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';

const ffmpegVersionOptions: CommonSelectItem[] = [
  { value: 'v4.4', label: 'v4.4' },
  { value: 'v4.3', label: 'v4.3' },
  { value: 'v4.0', label: 'v4.0' },
];

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const FfmpegVersion = ({ className, value, onChange }: Props) => {
  return (
    <div className={className}>
      <Select<CommonSelectItem>
        className='form-item-select'
        options={ffmpegVersionOptions}
        styles={settingSelectStyles}
        value={
          ffmpegVersionOptions[ffmpegVersionOptions.findIndex(option => option.value === value)]
        }
        onChange={newValue => onChange(newValue?.value ?? '')}
      />
    </div>
  );
};

export default FfmpegVersion;
