/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { getAllUsers } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { useSetAtom } from 'jotai';
import { loadingAtom, usersAtom } from '../../store/jotai';
import { User } from '../../types/model/user';
import { isAxiosError } from 'axios';

export const useLines = (prefetch = true) => {
  const setUsers = useSetAtom(usersAtom);
  const setLoading = useSetAtom(loadingAtom);
  const pageRef = useRef(1);
  const limitRef = useRef(1);
  const [_getAllUsers, loading, _getAllUsersRes, _getAllUsersErr] = useRequest(
    (page = 1, limit = 10, params: Record<string, string> = {}) => {
      pageRef.current = page;
      limitRef.current = limit;
      setLoading(true);
      return getAllUsers(page, limit, params);
    },
  );
  useEffect(() => {
    if (_getAllUsersRes) {
      setLoading(false);
      setUsers({
        Records: (_getAllUsersRes.Records as User[]).map(user => {
          return {
            ...user,
            online: user.connections?.length > 0 ? true : false,
          };
        }),
        TotalPages: _getAllUsersRes.TotalPages,
        TotalRecords: _getAllUsersRes.TotalRecords,
        CurrentPage: pageRef.current,
        CountPerPage: limitRef.current,
      });
    }
  }, [_getAllUsersRes]);

  useEffect(() => {
    if (prefetch) _getAllUsers();
  }, []);

  useEffect(() => {
    if (_getAllUsersErr) {
      setLoading(false);
      if (isAxiosError(_getAllUsersErr)) {
        console.log('_getAllUsersErrText: ', _getAllUsersErr.response?.data.error);
        console.log('_getAllUsersErrMessage: ', _getAllUsersErr.response?.data.message);
      } else {
        console.log('_getAllUsersErr: ', _getAllUsersErr);
      }
    }
  }, [_getAllUsersErr]);
  return {
    getAllUsers: _getAllUsers,
    loading,
  };
};
