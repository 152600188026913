import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ImportStreamFormData } from '../../../types/model/stream';
import FormFooter from '../../common/FormFooter';
import SlideToggle from '../../common/SlideToggle';

export interface StreamServersFormInput {
  isStart: boolean;
}
interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
  formData?: ImportStreamFormData;
  setFormData?: React.Dispatch<React.SetStateAction<ImportStreamFormData>>;
  submitFormData?: (data: ImportStreamFormData) => void;
  isEdit?: boolean;
}

const ImportStreamsServersForm = ({
  className,
  setVisibleTab,
  setFormData,
  submitFormData,
  isEdit = false,
}: Props) => {
  const { handleSubmit, control } = useForm<StreamServersFormInput>({
    defaultValues: {
      isStart: false,
    },
  });

  const handlePrev = () => {
    console.log('prev clicked');
    if (setVisibleTab) setVisibleTab(1);
  };

  const onSubmit: SubmitHandler<StreamServersFormInput> = data => {
    if (setFormData) {
      setFormData(currentStream => {
        const newStream = {
          ...currentStream,
          isStart: data.isStart,
        };
        if (submitFormData) submitFormData(newStream);
        return newStream;
      });
    }
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name='isStart'
          rules={{ required: false }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Start Stream Now</span>
              <SlideToggle value={field.value} onChange={field.onChange} />
            </div>
          )}
        />

        <FormFooter
          noIconOnNext
          nextButtonTitle={isEdit ? 'Edit' : 'Add'}
          handlePrev={handlePrev}
        />
      </form>
    </div>
  );
};

export default ImportStreamsServersForm;
