import React from 'react';
import Select from 'react-select';
import { settingSelectStyles } from '../../../styles/reactSelect';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';

const legacyThemeOptions: CommonSelectItem[] = [
  { value: 'default', label: 'Default' },
  { value: 'digital', label: 'Digital' },
  { value: 'emerald', label: 'Emerald' },
  { value: 'cappucino', label: 'Cappucino' },
  { value: 'oceanBlue', label: 'Ocean Blue' },
];

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const LegacyTheme = ({ className, value, onChange }: Props) => {
  return (
    <div className={className}>
      <Select<CommonSelectItem>
        className='form-item-select'
        options={legacyThemeOptions}
        styles={settingSelectStyles}
        value={legacyThemeOptions[legacyThemeOptions.findIndex(option => option.value === value)]}
        onChange={newValue => onChange(newValue?.value ?? '')}
      />
    </div>
  );
};

export default LegacyTheme;
