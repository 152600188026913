import React, { useEffect, useState } from 'react';
import CategoriesDetailsForm from '../CategoriesDetailsForm';
import { TabData } from '../../common/Tab';
import { Category } from '../../../types/model/category';
import FormTab from '../../common/FormTab';
import ModalForm from '../../common/ModalForm';
import { useEditCategory } from '../../../hooks/category/useEditCategory';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'Details',
    tabComponent: ({ ...props }) => CategoriesDetailsForm({ ...props, isEdit: true }),
  },
];

interface Props {
  category: Category;
  isOpen: boolean;
  onClose: () => void;
}

const EditCategoryModal = ({ category, isOpen, onClose }: Props) => {
  const [categoryData, setCategoryData] = useState<Category>(category);
  useEffect(() => {
    setCategoryData(category);
  }, [category]);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { editCategory, completed } = useEditCategory();
  const submitCategoryData = async (data: Category) => {
    editCategory(data);
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted && completed) {
      onClose();
      setSubmitted(false);
    }
  }, [completed, onClose, submitted]);

  return (
    <ModalForm
      className='narrow-modal-form'
      isOpen={isOpen}
      onClose={onClose}
      title={'Edit Category'}
    >
      <FormTab
        data={tabData}
        formData={categoryData}
        setFormData={setCategoryData}
        currentTab={tabData[0].id}
        submitFormData={submitCategoryData}
      />
    </ModalForm>
  );
};

export default EditCategoryModal;
