/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import { insertStream } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { isAxiosError } from 'axios';
import { useCategories } from '../category/useCategories';
import { useBouquets } from '../bouquet/useBouquets';
import { useSetAtom } from 'jotai';
import { loadingAtom } from '../../store/jotai';

export const useInsertStream = () => {
  const { getAllCategories, getAllCategoriesRes } = useCategories();
  const { getAllBouquets, getAllBouquetsRes } = useBouquets();
  const setLoading = useSetAtom(loadingAtom);
  const completed = useMemo(
    () => (getAllCategoriesRes && getAllBouquetsRes) as boolean,
    [getAllCategoriesRes, getAllBouquetsRes],
  );
  const [_insertStream, , insertStreamRes, insertStreamErr] = useRequest(insertStream);
  useEffect(() => {
    if (insertStreamRes) {
      getAllCategories();
      getAllBouquets();
    }
  }, [getAllBouquets, getAllCategories, insertStreamRes]);
  useEffect(() => {
    if (insertStreamErr) {
      setLoading(false);
      if (isAxiosError(insertStreamErr)) {
        console.log('insertStreamErrText: ', insertStreamErr.response?.data.error);
        console.log('insertStreamErrMessage: ', insertStreamErr.response?.data.message);
      } else {
        console.log('insertStreamErr: ', insertStreamErr);
      }
    }
  }, [insertStreamErr]);
  useEffect(() => {
    if (completed) {
      setLoading(false);
    }
  }, [completed]);
  return {
    insertStream: (data: unknown) => {
      setLoading(true);
      return  _insertStream(data);
    },
    completed,
  };
};
