import React from 'react';
import { stateItems } from '../config/data/stateData';
import { MagnifyingGlassSvg } from '../assets/svg';
import { NavLink } from 'react-router-dom';
import { IconSimpleButton } from '../components/common/Buttons';
import StateItemElement from './StateItem';

const TopBar = () => {
  return (
    <header className='fixed top-0 left-0 w-full bg-card py-2.5 pl-[60px] pr-[20px] flex flex-row justify-between items-center gap-20 z-[100]'>
      <NavLink to={'/'}>
        <img src='/LOGO.png' alt='LOGO' className='h-9' />
      </NavLink>
      <div className='relative w-[700px] min-w-[115px] flex items-center'>
        <input
          type='search'
          placeholder='Search...'
          className='w-full text-[14px] rounded-[19px] py-[7px] px-3 bg-dark [box-shadow:inset_0px_4px_4px_rgba(0,0,0,0.25)] text-form-content [&::-webkit-search-cancel-button]:appearance-none [&::-webkit-search-cancel-button]:hidden [&::-webkit-search-decoration]:appearance-none [&::-webkit-search-decoration]:hidden'
        />
        <IconSimpleButton
          className='absolute right-2.5'
          onClick={() => {
            console.log('clicked');
          }}
        >
          <MagnifyingGlassSvg />
        </IconSimpleButton>
      </div>
      <div className='flex flex-row justify-center items-center p-0 gap-2.5'>
        {stateItems.map((stateItem, index) => (
          <StateItemElement
            key={index}
            className='p-3 bg-dark [box-shadow:inset_0px_4px_4px_rgba(0,0,0,0.25)] rounded-lg flex flex-col justify-center items-center [&>p]:font-semibold [&>p]:text-[12px]'
            stateItem={stateItem}
          />
        ))}
      </div>
    </header>
  );
};
export default TopBar;
