import { atom } from 'jotai';
import { ServerStats } from '../types/model/serverStats';
import { Category } from '../types/model/category';
import { Bouquet } from '../types/model/bouquet';
import { ConnectionResponse, UserResponse } from '../types/model/user';
import { StreamsResponse } from '../types/model/stream';

export const serverStatsAtom = atom<ServerStats>({});

export const categoriesAtom = atom<Category[]>([]);

export const bouquetsAtom = atom<Bouquet[]>([]);

export const usersAtom = atom<UserResponse>({
  Records: [],
  TotalPages: 0,
  TotalRecords: 0,
  CurrentPage: 0,
  CountPerPage: 10,
});

export const streamsAtom = atom<StreamsResponse>({
  Records: [],
  TotalPages: 0,
  TotalRecords: 0,
  CurrentPage: 0,
  CountPerPage: 10,
});

export const connectionsAtom = atom<ConnectionResponse>({
  Records: [  ],
  TotalPages: 0,
  TotalRecords: 0,
  CurrentPage: 0,
  CountPerPage: 10,
});

export const loadingAtom = atom<boolean>(false);
