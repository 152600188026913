import { useSetAtom } from 'jotai';
import { serverStatsAtom } from '../../store/jotai';
import useRequest from '../../lib/hooks/useRequest';
import { getServerStats } from '../../lib/api';
import { ServerStats } from '../../types/model/serverStats';
import { useEffect } from 'react';
import { isAxiosError } from 'axios';

export const useServerStats = () => {
  const [_getServerStats, , getServerStatsRes, getServerStatsErr] = useRequest(getServerStats);
  const setServerStats = useSetAtom(serverStatsAtom);
  useEffect(() => {
    if (getServerStatsRes) {
      const serverStats = {
        ...(getServerStatsRes as ServerStats),
        output_speed: Math.round(((getServerStatsRes as ServerStats).output_speed ?? 0) / 1056784), // to Mbps
        input_speed: Math.round(((getServerStatsRes as ServerStats).input_speed ?? 0) / 1056784), // to Mbps
      };
      setServerStats({...serverStats});
    }
  }, [getServerStatsRes, setServerStats]);

  useEffect(() => {
    if (getServerStatsErr) {
      if (isAxiosError(getServerStatsErr)) {
        console.log('getServerStatsErrText: ', getServerStatsErr.response?.data.error);
        console.log('getServerStatsErrMessage: ', getServerStatsErr.response?.data.message);
      } else {
        console.log('getServerStatsErr: ', getServerStatsErr);
      }
    }
  }, [getServerStatsErr]);
  useEffect(() => {
    _getServerStats();
    const intervalId = setInterval(() => {
      _getServerStats();
    }, 3000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
