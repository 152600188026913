import React from 'react';
import { NextArrowSvg, PrevArrowSvg } from '../../assets/svg';
import { FormButton, PrimaryFormButton, SubmitFormButton } from './Buttons';
import { cn } from '../../utils/tw-merge';

interface Props {
  className?: string;
  hidePrev?: boolean;
  notSubmit?: boolean;
  noIconOnPrev?: boolean;
  noIconOnNext?: boolean;
  prevButtonTitle?: string;
  nextButtonTitle?: string;
  handlePrev?: () => void;
  handleNext?: () => void;
  children?: React.ReactNode;
}

const FormFooter = ({
  className,
  hidePrev = false,
  notSubmit = false,
  noIconOnPrev = false,
  noIconOnNext = false,
  prevButtonTitle = 'Prev',
  nextButtonTitle = 'Next',
  handlePrev,
  handleNext,
  children,
}: Props) => {
  return (
    <div
      className={cn('my-3 mx-0 flex flex-row justify-between items-center gap-[10px]', className)}
    >
      <FormButton onClick={handlePrev} className={`${hidePrev ? 'hidden-button' : ''}`}>
        {!noIconOnPrev && <PrevArrowSvg />}
        {prevButtonTitle}
      </FormButton>
      <div className='w-full flex justify-end gap-[10px]'>{children}</div>
      {notSubmit ? (
        <PrimaryFormButton onClick={handleNext}>
          {nextButtonTitle}
          {!noIconOnNext && <NextArrowSvg />}
        </PrimaryFormButton>
      ) : (
        <SubmitFormButton>
          {nextButtonTitle}
          {!noIconOnNext && <NextArrowSvg />}
        </SubmitFormButton>
      )}
    </div>
  );
};

export default FormFooter;
