import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageTab from '../components/common/PageTab';
import { TabData } from '../components/common/Tab';
import AddStreamForm from '../components/streams/AddStreamForm';
import ManageStreamsForm from '../components/streams/ManageStreamsForm';
import ImportStreamsForm from '../components/streams/ImportStreamsForm';

const StreamsPage = () => {
  const tabData: TabData[] = [
    {
      id: 1,
      tabTitle: 'Add Stream',
      tabComponent: AddStreamForm,
    },
    {
      id: 2,
      tabTitle: 'Import Streams',
      tabComponent: ImportStreamsForm,
    },
    {
      id: 3,
      tabTitle: 'Manage Streams',
      tabComponent: ManageStreamsForm,
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = React.useState<number>();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const path = pathSegments[pathSegments.length - 1];
    if (path === 'add-stream') setCurrentTab(1);
    if (path === 'import-streams') setCurrentTab(2);
    if (path === 'manage-streams') setCurrentTab(3);
  }, [location]);

  const onChangeTab = (id: number) => {
    setCurrentTab(id);
    if (id == 1) navigate('/streams/add-stream');
    if (id == 2) navigate('/streams/import-streams');
    if (id == 3) navigate('/streams/manage-streams');
  };

  return (
    <>
      {currentTab && <PageTab data={tabData} currentTab={currentTab} onChangeTab={onChangeTab} />}
    </>
  );
};
export default StreamsPage;
