import React from 'react';
import { CheckBoxFalseSvg, CheckBoxTrueSvg } from '../../assets/svg';
import { cn } from '../../utils/tw-merge';

interface Props {
  className?: string;
  value: boolean;
  onClick: () => void;
}

const CheckBox = ({ className, value, onClick }: Props) => {
  return (
    <div className={cn('cursor-pointer', className)} onClick={onClick}>
      {value ? <CheckBoxTrueSvg /> : <CheckBoxFalseSvg />}
    </div>
  );
};

export default CheckBox;
