/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { Category } from '../../types/model/category';
import { Stream } from '../../types/model/stream';
import { Connection, User } from '../../types/model/user';
import { Bouquet, BouquetRequest } from './../../types/model/bouquet';
import {
  bouquetsURL,
  categoriesURL,
  channelsURL,
  connectionsURL,
  statsURL,
  streamsURL,
  usersURL,
} from './URL';

export const baseURL = process.env.REACT_APP_SERVER_URL || 'http://141.94.248.218:3000';
const apiClient = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: localStorage.getItem('session-id'),
  },
});
apiClient.interceptors.request.use(config => {
  const sessionId = getSessionId();
  if (sessionId) {
    config.headers.Authorization = `${sessionId}`;
  }
  return config;
});

let sessionId: string | undefined = undefined;

export const getSessionId = () => sessionId;

export const setSessionId = (newSessionId: string | undefined) => (sessionId = newSessionId);

export const login = (username: string, password: string) =>
  apiClient.post('/login', { username, password });

export const logout = (session_id: string) => apiClient.post('/logout', { session_id });

// Server stats APIs
export const getServerStats = () => apiClient.get(statsURL.getServerStats);

// Users by stream APIs
export const getUsersByStream = (streamId: string) =>
  apiClient.get(`${statsURL.getUsersByStream}/${streamId}/users`);

// Users APIs
export const insertUser = (user: User) => apiClient.post(usersURL.insertUser, user);

export const getUser = (username: string) => apiClient.get(`${usersURL.getUser}/${username}`);

export const updateUser = (user: User) => apiClient.patch(usersURL.updateUser, user);

export const deleteUser = (username: string) =>
  apiClient.delete(`${usersURL.deleteUser}/${username}`);

export const banUser = (user: User) => {
  return apiClient.patch(usersURL.banUser, user);
};

export const getAllUsers = (page = 1, limit = 10, params: Record<string, string> = {}) => {
  const query = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
    ...params,
  });
  return apiClient.get(`${usersURL.getAllUsers}?${query.toString()}`);
};

// connections APIs
export const getAllConnections = (page = 1, limit = 10, params: Record<string, string> = {}) => {
  const query = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
    ...params,
  });
  return apiClient.get(`${connectionsURL.getAllConnections}?${query.toString()}`);
};

export const killConnection = (connection: Connection) =>
  apiClient.post(`${connectionsURL.killConnection}/kill`, {
    connectionID: connection.connection_id,
  });

// Streams APIs
export const insertStream = (stream: Stream) => apiClient.post(streamsURL.insertStream, stream);

export const startStream = (streamId: string) =>
  apiClient.post(`${streamsURL.startStream}/${streamId}/start`);

export const startStreams = (stream_ids: number[], check_all: boolean) =>
  apiClient.post('/streams/start', {
    stream_ids,
    check_all,
  });

export const deleteStreams = (stream_ids: number[], check_all: boolean) => {
  return apiClient.delete('/streams', {
    data: {
      stream_ids,
      check_all,
    },
  });
};

export const stopStreams = (stream_ids: number[], check_all: boolean) => {
  return apiClient.post('/streams/stop', {
    stream_ids,
    check_all,
  });
};

export const stopStream = (streamId: string) =>
  apiClient.post(`${streamsURL.stopStream}/${streamId}/stop`);

export const getStream = (streamId: string) => apiClient.get(`${streamsURL.getStream}/${streamId}`);

export const updateStream = (params: Stream) => apiClient.patch(streamsURL.updateStream, params);

export const deleteStream = (streamId: string) =>
  apiClient.delete(`${streamsURL.deleteStream}/${streamId}`);

export const getAllStreams = (page = 1, limit = 10, params: Record<string, string> = {}) => {
  const query = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
    ...params,
  });
  return apiClient.get(`${streamsURL.getAllStreams}?${query.toString()}`);
};

// get channels by category
export const getChannelsByCategory = (categoryId: number) =>
  apiClient.get(`${channelsURL.getChannels}${categoriesURL.getCategory}/${categoryId}`);

// get channels by bouquet
export const getChannelsByBouquet = (bouquetId: number) =>
  apiClient.get(`${channelsURL.getChannels}${bouquetsURL.getBouquet}/${bouquetId}`);

export const multiPartFormCall = (url: string, formData: FormData) => {
  const multiPartConfig = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return apiClient.post(url, formData, multiPartConfig);
};
export const importStreams = (params: FormData) =>
  multiPartFormCall(streamsURL.importStreams, params);

// Categories APIs
export const insertCategory = (params: string) =>
  apiClient.post(categoriesURL.insertCategory, params);

export const getCategory = (categoryId: number) =>
  apiClient.get(`${categoriesURL.getCategory}/${categoryId}`);

export const editCategory = (params: Category) =>
  apiClient.patch(categoriesURL.editCategory, params);

export const deleteCategory = (categoryId: number) =>
  apiClient.delete(`${categoriesURL.deleteCategory}/${categoryId}`);

export const getAllCategories = () => apiClient.get(categoriesURL.getAllCategories);

// Bouquets APIs
export const insertBouquet = (params: BouquetRequest) =>
  apiClient.post(bouquetsURL.insertBouquet, params);

export const getBouquet = (bouquetId: number) =>
  apiClient.get(`${bouquetsURL.getBouquet}/${bouquetId}`);

export const editBouquet = (params: Bouquet) => apiClient.patch(bouquetsURL.editBouquet, params);

export const deleteBouquet = (bouquetId: number) =>
  apiClient.delete(`${bouquetsURL.deleteBouquet}/${bouquetId}`);

export const getAllBouquets = () => apiClient.get(bouquetsURL.getAllBouquets);
