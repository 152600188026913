import React, { useEffect, useState } from 'react';
import FormTab from '../../common/FormTab';
import { TabData } from '../../common/Tab';
import BouquetsDetailsForm from './../BouquetsDetailsForm';
import { Bouquet } from '../../../types/model/bouquet';
import ModalForm from '../../common/ModalForm';
import { useEditBouquet } from '../../../hooks/bouquet/useEditBouquet';
import BouquetChannelsForm from '../ChannelsForm';
import toast from 'react-hot-toast';
import { isAxiosError } from 'axios';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'Details',
    tabComponent: ({ ...props }) => BouquetsDetailsForm({ ...props }),
  },
  {
    id: 2,
    tabTitle: 'Channels',
    tabComponent: ({ ...props }) => BouquetChannelsForm({ ...props, isEdit: true }),
  },
];

interface Props {
  bouquet: Bouquet;
  isOpen: boolean;
  onClose: () => void;
}

const EditBouquetForm = ({ bouquet, isOpen, onClose }: Props) => {
  const [bouquetData, setBouquetData] = useState<Bouquet>(bouquet);
  const [submitted, setSubmitted] = useState(false);
  const { editBouquet, completed, editBouquetErr } = useEditBouquet();
  useEffect(() => {
    toast.dismiss();
    if (isAxiosError(editBouquetErr)) {
      toast.error(editBouquetErr.response?.data.message);
    }
  }, [editBouquetErr]);
  useEffect(() => {
    setBouquetData(bouquet);
  }, [bouquet]);

  useEffect(() => {
    if (submitted && completed) {
      setSubmitted(false);
      onClose();
    }
  }, [completed, onClose, submitted]);

  const submitBouquetData = async (data: { name: string; streams: number[] }) => {
    const newBoquet = {
      id: bouquet.id,
      name: data.name,
      channels: {
        streams: data.streams,
        movies: [],
        series: [],
      },
    };
    editBouquet(newBoquet);
    setSubmitted(true);
  };

  return (
    <ModalForm
      className='narrow-modal-form'
      isOpen={isOpen}
      onClose={() => onClose()}
      title={'Edit Bouquet'}
    >
      <FormTab
        data={tabData}
        formData={bouquetData}
        setFormData={setBouquetData}
        currentTab={tabData[0].id}
        submitFormData={submitBouquetData}
      />
    </ModalForm>
  );
};

export default EditBouquetForm;
