/* eslint-disable react-hooks/exhaustive-deps */
import { isAxiosError } from 'axios';
import { useEffect, useMemo } from 'react';
import { importStreams } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { useBouquets } from '../bouquet/useBouquets';
import { useCategories } from '../category/useCategories';
import { useSetAtom } from 'jotai';
import { loadingAtom } from '../../store/jotai';

export const useImportStreams = () => {
  const { getAllCategories, getAllCategoriesRes } = useCategories();
  const { getAllBouquets, getAllBouquetsRes } = useBouquets();
  const setLoading = useSetAtom(loadingAtom);

  const completed = useMemo(
    () => (getAllCategoriesRes && getAllBouquetsRes) as boolean,
    [getAllCategoriesRes, getAllBouquetsRes],
  );
  const [_importStreams, , importsStreamRes, importStreamsErr] = useRequest(importStreams);
  useEffect(() => {
    if (importsStreamRes) {
      getAllCategories();
      getAllBouquets();
    }
  }, [getAllBouquets, getAllCategories, importsStreamRes]);
  useEffect(() => {
    if (importStreamsErr) {
      setLoading(false);
      if (isAxiosError(importStreamsErr)) {
        console.log('importStreamsErrText: ', importStreamsErr.response?.data.error);
        console.log('importStreamsErrMessage: ', importStreamsErr.response?.data.message);
      } else {
        console.log('importStreamsErr: ', importStreamsErr);
      }
    }
  }, [importStreamsErr]);
  useEffect(() => {
    if (completed) {
      setLoading(false);
    }
  }, [completed]);
  return {
    importStreams: (data: unknown) => {
      setLoading(true);
      return _importStreams(data);
    },
    completed,
  };
};
