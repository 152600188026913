import { useEffect } from 'react';
import { stopStreams } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { useStreams } from './useStreams';
import { isAxiosError } from 'axios';
import { useAtomValue } from 'jotai';
import { streamsAtom } from '../../store/jotai';

export const useStopStreams = () => {
  const streams = useAtomValue(streamsAtom);
  const { getAllStreams, loading: loadingStreams } = useStreams(false);
  const [_stopStreams, loading, _stopStreamsRes, _stopStreamsErr] = useRequest(stopStreams);

  useEffect(() => {
    if (_stopStreamsRes) {
      console.log('_stopStreamRes: ', _stopStreamsRes);
      getAllStreams(streams.CurrentPage, streams.CountPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_stopStreamsRes]);
  useEffect(() => {
    if (_stopStreamsErr) {
      if (isAxiosError(_stopStreamsErr)) {
        console.log('_stopStreamErrText: ', _stopStreamsErr.response?.data.error);
        console.log('_stopStreamErrMessage: ', _stopStreamsErr.response?.data.message);
      } else {
        console.log('_stopStreamErr: ', _stopStreamsErr);
      }
    }
  }, [_stopStreamsErr]);
  return {
    stopStreams: _stopStreams,
    loading: loadingStreams || loading,
    stopStreamsErr: _stopStreamsErr,
  };
};
