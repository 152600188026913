import { useEffect, useRef } from 'react';
import useRequest from '../../lib/hooks/useRequest';
import { Stream } from '../../types/model/stream';
import { getAllStreams } from '../../lib/api';
import { useSetAtom } from 'jotai';
import { streamsAtom } from '../../store/jotai';
import { isAxiosError } from 'axios';

export const useStreams = (prefetch = true) => {
  const setStreams = useSetAtom(streamsAtom);
  const pageRef = useRef(1);
  const limitRef = useRef(1);
  const [_getAllStreams, loading, _getAllStreamsRes, _getAllStreamsErr] = useRequest(
    (page = 1, limit = 10, params: Record<string, string> = {}) => {
      pageRef.current = page;
      limitRef.current = limit;
      return getAllStreams(page, limit, params);
    },
  );
  useEffect(() => {
    if (_getAllStreamsRes)
      setStreams({
        Records: _getAllStreamsRes.Records as Stream[],
        TotalPages: _getAllStreamsRes.TotalPages,
        TotalRecords: _getAllStreamsRes.TotalRecords,
        CurrentPage: pageRef.current,
        CountPerPage: limitRef.current,
      });
  }, [_getAllStreamsRes, setStreams]);

  useEffect(() => {
    if (prefetch) _getAllStreams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (_getAllStreamsErr) {
      if (isAxiosError(_getAllStreamsErr)) {
        console.log('_getAllStreamsErrText: ', _getAllStreamsErr.response?.data.error);
        console.log('_getAllStreamsErrMessage: ', _getAllStreamsErr.response?.data.message);
      } else {
        console.log('_getAllStreamsErr: ', _getAllStreamsErr);
      }
    }
  }, [_getAllStreamsErr]);
  return {
    getAllStreams: _getAllStreams,
    loading,
  };
};
