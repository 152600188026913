/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useCategories } from './useCategories';
import useRequest from '../../lib/hooks/useRequest';
import { insertCategory } from '../../lib/api';
import { isAxiosError } from 'axios';
import { loadingAtom } from '../../store/jotai';
import { useSetAtom } from 'jotai';

export const useInsertCategory = () => {
  const [_insertCategory, , insertCategoryRes, insertCategoryErr] = useRequest(insertCategory);
  const { getAllCategories, completed } = useCategories();
  const setLoading = useSetAtom(loadingAtom);

  useEffect(() => {
    if (insertCategoryRes) {
      setLoading(false);
      console.log('insertCategoryRes: ', insertCategoryRes);
      // Todo: go to categories page
      getAllCategories();
    }
  }, [getAllCategories, insertCategoryRes]);

  useEffect(() => {
    if (insertCategoryErr) {
      setLoading(false);
      if (isAxiosError(insertCategoryErr)) {
        console.log('insertCategoryErrText: ', insertCategoryErr.response?.data.error);
        console.log('insertCategoryErrMessage: ', insertCategoryErr.response?.data.message);
      } else {
        console.log('insertCategoryErr: ', insertCategoryErr);
      }
    }
  }, [insertCategoryErr]);
  return {
    insertCategory: (data: unknown) => {
      setLoading(true);
      return _insertCategory(data);
    },
    completed,
  };
};
