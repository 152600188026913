/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import { updateStream } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { useStreams } from './useStreams';
import { isAxiosError } from 'axios';
import { useCategories } from '../category/useCategories';
import { useBouquets } from '../bouquet/useBouquets';
import { useAtomValue, useSetAtom } from 'jotai';
import { loadingAtom, streamsAtom } from '../../store/jotai';

export const useEditStream = () => {
  const streams = useAtomValue(streamsAtom);
  const setLoading = useSetAtom(loadingAtom);
  const { getAllCategories, getAllCategoriesRes } = useCategories();
  const { getAllBouquets, getAllBouquetsRes } = useBouquets();
  const completed = useMemo(
    () => (getAllCategoriesRes && getAllBouquetsRes) as boolean,
    [getAllCategoriesRes, getAllBouquetsRes],
  );
  const { getAllStreams } = useStreams(false);
  const [_updateStream, , _updateStreamRes, _updateStreamErr] = useRequest(updateStream);

  useEffect(() => {
    if (_updateStreamRes && getAllStreams) {
      // TODO check data object
      getAllStreams(streams.CurrentPage, streams.CountPerPage);
      getAllCategories();
      getAllBouquets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_updateStreamRes]);

  useEffect(() => {
    if (_updateStreamErr) {
      setLoading(false);
      if (isAxiosError(_updateStreamErr)) {
        console.log('updateStreamErrText: ', _updateStreamErr.response?.data.error);
        console.log('updateStreamErrMessage: ', _updateStreamErr.response?.data.message);
      } else {
        console.log('updateStreamErr: ', _updateStreamErr);
      }
    }
  }, [_updateStreamErr]);

  useEffect(() => {
    if (completed) {
      setLoading(false);
    }
  }, [completed]);

  return {
    editStream: (data: unknown) => {
      setLoading(true);
      return _updateStream(data);
    },
    completed,
  };
};
