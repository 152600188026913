import React from 'react';
import { ServerStatusItem } from '../../types/serverStatusData';
import { useAtomValue } from 'jotai';
import { serverStatsAtom } from '../../store/jotai';
import { ServerStats } from '../../types/model/serverStats';
import { intervalToDuration } from 'date-fns';
import { cn } from '../../utils/tw-merge';

interface Props {
  data: ServerStatusItem;
  className: string;
}

const StatusTableCard = ({ data, className }: Props) => {
  const serverStats = useAtomValue(serverStatsAtom);

  let value: ServerStats;
  switch (data.id) {
    case 'main':
      value = serverStats;
      break;
    default:
      value = {};
  }

  let uptimeStr = '';

  if (value.up_time) {
    const duration = intervalToDuration({ start: 0, end: value.up_time * 1000 });
    if (duration.years) uptimeStr += `${duration.years}y `;
    if (duration.months) uptimeStr += `${duration.months}m `;
    if (duration.days) uptimeStr += `${duration.days}d `;
    if (duration.hours) uptimeStr += `${duration.hours}h `;
    uptimeStr += `${duration.minutes ?? 0}m `;
  }

  return (
    <div className={cn('pt-5', className)}>
      <p className='pl-[30px] font-medium text-[16px]'>{data.serverName}</p>
      <div className='mt-5 w-full p-0'>
        <table className='w-full h-10 flex items-center'>
          <tbody className='w-full py-0 px-[30px] flex flex-row justify-between items-center gap-[10px] text-left'>
            <tr className='w-[145px]'>
              <th className='text-[13px] w-[100px]'>Connections</th>
              <td className='text-[10px]'>{value.total_connections ?? -1}</td>
            </tr>
            <tr className='w-[130px]'>
              <th className='text-[13px] w-[85px]'>Users</th>
              <td className='text-[10px]'>{value.connected_users ?? -1}</td>
            </tr>
            <tr className='w-1/2 flex justify-between items-center'>
              <th className='text-[13px] w-[45px] font-bold'>CPU</th>
              <td className='text-[10px] w-full'>
                <ProgressBar
                  value={value.cpu_percent ?? 0}
                  color='linear-gradient(180deg, #15B547 0%, #A8FFC5 100%)'
                />
              </td>
            </tr>
          </tbody>
        </table>
        <hr className='border border-solid border-card' />
        <table className='w-full h-10 flex items-center'>
          <tbody className='w-full py-0 px-[30px] flex flex-row justify-between items-center gap-[10px] text-left'>
            <tr className='w-[145px]'>
              <th className='text-[13px] w-[100px]'>Streams Live</th>
              <td className='text-[10px]'>{value.total_running_streams}</td>
            </tr>
            <tr className='w-[130px]'>
              <th className='text-[13px] w-[85px]'>Down</th>
              <td className='text-[10px]'>
                {(value.total_streams ?? 0) - (value.total_running_streams ?? 0)}
              </td>
            </tr>
            <tr className='w-1/2 flex justify-between items-center'>
              <th className='text-[13px] w-[45px] font-bold'>MEM</th>
              <td className='text-[10px] w-full'>
                <ProgressBar
                  value={value.memory_percent ?? 0}
                  color='linear-gradient(177.44deg, #6F45E9 2.14%, #63AAFD 72.98%)'
                />
              </td>
            </tr>
          </tbody>
        </table>
        <hr className='border border-solid border-card' />
        <table className='w-full h-10 flex items-center'>
          <tbody className='w-full py-0 px-[30px] flex flex-row justify-between items-center gap-[10px] text-left'>
            <tr className='w-[145px]'>
              <th className='text-[13px] w-[100px]'>Request /sec</th>
              <td className='text-[10px]'>{value.requests_per_sec}</td>
            </tr>
            <tr className='w-[130px]'>
              <th className='text-[13px] w-[85px]'>Uptime</th>
              <td className='text-[10px]'>{uptimeStr}</td>
            </tr>
            <tr className='w-1/2 flex justify-between items-center'>
              <th className='text-[13px] w-[45px] font-bold'>IO</th>
              <td className='text-[10px] w-full'>
                <ProgressBar
                  value={value.io_percent ?? 0}
                  color='linear-gradient(177.44deg, #B538F7 2.14%, #FB8CBD 72.98%)'
                />
              </td>
            </tr>
          </tbody>
        </table>
        <hr className='border border-solid border-card' />
        <table className='w-full h-10 flex items-center'>
          <tbody className='w-full py-0 px-[30px] flex flex-row justify-between items-center gap-[10px] text-left'>
            <tr className='w-[145px]'>
              <th className='text-[13px] w-[100px]'>Input (Mbps)</th>
              <td className='text-[10px]'>{value.input_speed}</td>
            </tr>
            <tr className='w-[130px]'>
              <th className='text-[13px] w-[85px]'>Output (Mbs)</th>
              <td className='text-[10px]'>{value.output_speed}</td>
            </tr>
            <tr className='w-1/2 flex justify-between items-center'>
              <th className='text-[13px] w-[45px] font-bold'>DISK</th>
              <td className='text-[10px] w-full'>
                <ProgressBar
                  value={value.disk_percent ?? 0}
                  color='linear-gradient(177.44deg, #FF2C2C 2.14%, #FFAB2D 72.98%)'
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ProgressBar = ({ value, color }: { value: number; color: string }) => {
  return (
    <div className='relative w-full h-[10px] rounded-[5px] bg-dark'>
      <div
        className='absolute top-0 left-0 h-[10px] rounded-[5px]'
        style={{
          width: value + '%',
          background: color,
        }}
      ></div>
    </div>
  );
};

export default StatusTableCard;
