import React from 'react';
import ReactModal from 'react-modal';
import { NextArrowSvg, PrevArrowSvg } from '../../assets/svg';
import { ColorableButton, FormButton } from './Buttons';
import { cn } from '../../utils/tw-merge';

interface Props {
  className?: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  hideCancel?: boolean;
  hideOk?: boolean;
  noIconOnCancel?: boolean;
  noIconOnOk?: boolean;
  prevButtonTitle?: string;
  nextButtonTitle?: string;
  handleCancel?: () => void;
  handleOk: (selectedItemId: string) => void;
  selectedItemId?: string;
}

const ModalWarning = ({
  className,
  children,
  isOpen,
  onClose,
  hideCancel = false,
  hideOk = false,
  noIconOnCancel = false,
  noIconOnOk = false,
  prevButtonTitle = 'Cancel',
  nextButtonTitle = 'OK',
  handleCancel,
  handleOk,
  selectedItemId,
}: Props) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={cn(
        'w-[840px] max-w-[85%] max-h-[85%] overflow-auto p-12 flex flex-col items-center card',
        className,
      )}
      ariaHideApp={false}
      overlayClassName='items-center justify-center bg-[rgba(0,0,0,0.75)] z-[1000] flex fixed inset-0'
    >
      <div className='text-[32px] py-12 px-4 text-center w-full'>{children}</div>
      <div className='my-3 mx-0 flex justify-center items-center gap-[10px]'>
        <FormButton onClick={handleCancel} className={`${hideCancel ? 'hidden-button' : ''}`}>
          {!noIconOnCancel && <PrevArrowSvg />}
          {prevButtonTitle}
        </FormButton>
        <ColorableButton
          bgColor={'#d34848'}
          onClick={() => handleOk(selectedItemId ?? '')}
          className={`${hideOk ? 'hidden-button' : ''}`}
        >
          {nextButtonTitle}
          {!noIconOnOk && <NextArrowSvg />}
        </ColorableButton>
      </div>
    </ReactModal>
  );
};

export default ModalWarning;
