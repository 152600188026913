import React from 'react';
import { userPhotoImage } from '../../assets/images';
import { DownArrowSvg } from '../../assets/svg';
import { cn } from '../../utils/tw-merge';

interface Props {
  className: string;
}

const UserInfo = ({ className }: Props) => {
  return (
    <div className={cn('flex flex-col justify-center items-center gap-2.5', className)}>
      <div className='flex flex-row justify-between items-center gap-2'>
        <img
          src={userPhotoImage}
          className='w-[45px] h-[45px] rounded-[50%] border-2 border-solid border-[#1e1e3f]'
          alt='User Photo'
        />
        <div className='flex flex-col justify-center items-start gap-2'>
          <div className='flex flex-row justify-between items-center gap-2'>
            <p className='text-[16px]'>Super Admin</p>
            <DownArrowSvg />
          </div>
          <div className='flex flex-row justify-start items-center gap-[5px]'>
            <div className='w-[7px] h-[7px] rounded-[50%] bg-online-state'></div>
            <p className='text-[10px]'>2023.12.10</p>
          </div>
        </div>
      </div>
      <div className='text-[11px] text-secondary'>TurboFlow Dashboard V1.0</div>
    </div>
  );
};

export default UserInfo;
