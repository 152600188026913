import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { EditSvg, ListSvg, XMarkCircleSvg } from '../../assets/svg';
import { IconSimpleButton } from './Buttons';
import ModalWarning from './ModalWarning';

export interface DndData {
  id: string;
  label: string;
  description: string;
}

interface Props {
  value: DndData[];
  onChange: (value: DndData[]) => void;
  deleteMessage: string;
  onDelete?: (selectedItemId: string) => void;
  onEdit?: (selectedItem: DndData) => void;
}

const DragAndDropCard = ({ value, onChange, deleteMessage, onDelete, onEdit }: Props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string>();

  const openDeleteModal = (item: DndData) => {
    setShowDeleteModal(true);
    setSelectedItemId(item.id);
  };

  const handleDeleteItem = (selectedItemId: string) => {
    onDelete && onDelete(selectedItemId);
    setShowDeleteModal(false);
  };

  const handleDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.index === source.index) return;

    const workValue = value.slice();
    const [deletedItem] = workValue.splice(source.index, 1);
    workValue.splice(destination.index, 0, deletedItem);
    onChange(workValue);
  };

  return (
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='ROOT'>
          {provided => (
            <div
              className='w-full flex flex-col gap-[1px]'
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {value.map((item, index) => (
                <Draggable draggableId={item.id} key={item.id} index={index}>
                  {provided => (
                    <div
                      className='w-full h-16 py-0 px-5 flex justify-between items-center bg-element-card'
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                    >
                      <div className='flex justify-center items-center gap-[22px]'>
                        <div
                          className='w-9 h-8 flex justify-center items-center bg-dark rounded-lg'
                          {...provided.dragHandleProps}
                        >
                          <ListSvg />
                        </div>
                        {item.label}
                      </div>
                      <div className='flex justify-center items-center gap-[22px]'>
                        <IconSimpleButton size={28} onClick={() => onEdit && onEdit(item)}>
                          <EditSvg style={{ height: '18px', width: '18px' }} />
                        </IconSimpleButton>
                        <IconSimpleButton size={28} onClick={() => openDeleteModal(item)}>
                          <XMarkCircleSvg />
                        </IconSimpleButton>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ModalWarning
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        noIconOnOk
        nextButtonTitle='Delete'
        handleCancel={() => setShowDeleteModal(false)}
        handleOk={selectedItemId => handleDeleteItem(selectedItemId)}
        selectedItemId={selectedItemId}
      >
        {deleteMessage}
      </ModalWarning>
    </div>
  );
};

export default DragAndDropCard;
