import React from 'react';
import { User } from '../../../types/model/user';
import FormFooter from '../../common/FormFooter';
import { Controller, useForm } from 'react-hook-form';
import SlideToggle from '../../common/SlideToggle';
import { PrimaryFormButton } from '../../common/Buttons';
import { TagsInput } from 'react-tag-input-component';
import AllowedInput from '../../common/AllowedInput';

interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
  formData?: User;
  setFormData?: React.Dispatch<React.SetStateAction<User>>;
  submitFormData?: (data: User) => void;
  isEdit?: boolean;
}

interface LineSecurityInput {
  is_asn_lock: boolean;
  max_asn: number;
  current_asns: string[];
  allowed_ips: string[];
  allowed_user_agents: string[];
}

const LineSecurity = ({
  className,
  setVisibleTab,
  formData,
  submitFormData,
  isEdit = false,
}: Props) => {
  const { handleSubmit, control, getValues, watch } = useForm<LineSecurityInput>({
    defaultValues: {
      is_asn_lock: formData?.is_asn_lock ?? false,
      max_asn: formData?.max_asn ?? 1,
      current_asns: formData?.current_asns?.map(asn => asn.asn_number.toString()) ?? [],
      allowed_ips: formData?.allowed_ips ?? [],
      allowed_user_agents: formData?.allowed_user_agents ?? [],
    },
  });

  const handlePrev = () => {
    // if (setVisibleTab) setVisibleTab(3);
    if (setVisibleTab) setVisibleTab(1);
  };
  const onSubmit = () => {
    const data = getValues();
    if (formData && submitFormData) {
      submitFormData({
        ...formData,
        is_asn_lock: data.is_asn_lock,
        current_asns: data.current_asns.map(a => {
          return {
            asn_number: Number(a),
            asn_name: formData.current_asns?.find(asn => asn.asn_number === Number(a))?.asn_name,
          };
        }),
        allowed_ips: data.allowed_ips,
        allowed_user_agents: data.allowed_user_agents,
        max_asn: Number(data.max_asn),
      });
    }
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name='is_asn_lock'
          render={({ field }) => (
            <div className='form-item'>
              <div className='form-item-name'>ASN Lock</div>
              <SlideToggle value={field.value} onChange={field.onChange} />
            </div>
          )}
        />
        <Controller
          control={control}
          name='max_asn'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Max ASN</span>
              <div className='form-item-content'>
                <input
                  type='number'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder='Max ASN...'
                  disabled={!watch('is_asn_lock')}
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='current_asns'
          render={({ field }) => {
            return (
              <div className='form-item !items-start'>
                <span className='form-item-name pt-1'>ASN&apos;s</span>
                <div className='form-item-content'>
                  <div className='flex gap-2 w-full'>
                    <TagsInput
                      value={field.value}
                      onChange={field.onChange}
                      onRemoved={tag => {
                        field.onChange(field.value.filter(val => val !== tag));
                      }}
                      disabled={!watch('is_asn_lock')}
                    />
                    <PrimaryFormButton
                      onClick={() => {
                        field.onChange([]);
                      }}
                      disabled={!watch('is_asn_lock')}
                      className='w-[5rem] my-auto h-fit'
                    >
                      Clear
                    </PrimaryFormButton>
                  </div>
                </div>
              </div>
            );
          }}
        />
        <Controller
          control={control}
          name='allowed_ips'
          render={({ field }) => {
            const ipv4Reg =
              /\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b/;

            return (
              <div className='form-item !items-start'>
                <span className='form-item-name pt-1'>Allowed Ips</span>
                <div className='form-item-content'>
                  <AllowedInput
                    values={field.value}
                    setValues={field.onChange}
                    validation={ipv4Reg}
                  />
                </div>
              </div>
            );
          }}
        />
        <Controller
          control={control}
          name='allowed_user_agents'
          render={({ field }) => {
            return (
              <div className='form-item !items-start'>
                <span className='form-item-name pt-1'>Allowed User Agents</span>
                <div className='form-item-content'>
                  <AllowedInput values={field.value} setValues={field.onChange} />
                </div>
              </div>
            );
          }}
        />
        <FormFooter
          noIconOnNext
          nextButtonTitle={isEdit ? 'Edit' : 'Add'}
          handlePrev={handlePrev}
        />
      </form>
    </div>
  );
};

export default LineSecurity;
