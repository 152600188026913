import { useSetAtom } from 'jotai';
import { categoriesAtom } from '../../store/jotai';
import useRequest from '../../lib/hooks/useRequest';
import { getAllCategories } from '../../lib/api';
import { Category } from '../../types/model/category';
import { useEffect, useState } from 'react';

export const useCategories = () => {
  const [_getAllCategories, loading, _getAllCategoriesRes, _getAllCategoriesErr] =
    useRequest(getAllCategories);
  const  setCategories = useSetAtom(categoriesAtom);
  const [completed, setCompleted] = useState(false);
  useEffect(() => {
    if (!_getAllCategoriesRes) return;
    const categories = (_getAllCategoriesRes.Records as Category[]) ?? [];
    setCategories(categories);
    setTimeout(() => {
      setCompleted(true);
    }, 100);
  }, [_getAllCategoriesRes, setCategories]);

  useEffect(() => {
    if (_getAllCategoriesErr) {
      console.log('_getAllCategoriesErr: ', _getAllCategoriesErr);
    }
  }, [_getAllCategoriesErr]);
  return {
    getAllCategories: _getAllCategories,
    getAllCategoriesRes: _getAllCategoriesRes,
    loading,
    completed,
  };
};
