import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AddBouquetForm from '../components/bouquet/AddBouquetForm';
import ManageBouquetForm from '../components/bouquet/ManageBouquetForm';
import PageTab from '../components/common/PageTab';
import { TabData } from '../components/common/Tab';

const BouquetsPage = () => {
  const tabData: TabData[] = [
    {
      id: 1,
      tabTitle: 'Add Bouquet',
      tabComponent: AddBouquetForm,
    },
    {
      id: 2,
      tabTitle: 'Manage Bouquets',
      tabComponent: ManageBouquetForm,
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = React.useState<number>();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const path = pathSegments[pathSegments.length - 1];
    if (path === 'bouquets') setCurrentTab(1);
    if (path === 'manage-bouquets') setCurrentTab(2);
  }, [location]);

  const onChangeTab = (id: number) => {
    setCurrentTab(id);
    if (id == 1) navigate('/management/bouquets');
    if (id == 2) navigate('/management/bouquets/manage-bouquets');
  };

  return (
    <>
      {currentTab && <PageTab data={tabData} currentTab={currentTab} onChangeTab={onChangeTab} />}
    </>
  );
};
export default BouquetsPage;
