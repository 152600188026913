import { useEffect } from 'react';
import { insertUser } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { isAxiosError } from 'axios';
import { useSetAtom } from 'jotai';
import { loadingAtom } from '../../store/jotai';

export const useInsertLine = () => {
  const [_insertUser, , insertUserRes, insertUserErr] = useRequest(insertUser);
  const setLoading = useSetAtom(loadingAtom);
  useEffect(() => {
    if (insertUserRes) {
      console.log('insertUserRes: ', insertUserRes);
    }
  }, [insertUserRes]);

  useEffect(() => {
    if (insertUserErr) {
      if (isAxiosError(insertUserErr)) {
        console.log('insertUserErrText: ', insertUserErr.response?.data.error);
        console.log('insertUserErrMessage: ', insertUserErr.response?.data.message);
      } else {
        console.log('insertUserErr: ', insertUserErr);
      }
    }
  }, [insertUserErr]);
  return {
    insertLine: (data: unknown) => {
      setLoading(true);
      return _insertUser(data);
    },
    insertLineRes: insertUserRes,
    insertLineErr: insertUserErr,
  };
};
