/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import { deleteStreams } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { useStreams } from './useStreams';
import { isAxiosError } from 'axios';
import { useCategories } from '../category/useCategories';
import { useBouquets } from '../bouquet/useBouquets';
import { useAtomValue, useSetAtom } from 'jotai';
import { loadingAtom, streamsAtom } from '../../store/jotai';

export const useDeleteStreams = () => {
  const { getAllStreams, loading: loadingStreams } = useStreams(false);
  const streams = useAtomValue(streamsAtom);
  const setLoading = useSetAtom(loadingAtom);
  const { getAllCategories, getAllCategoriesRes } = useCategories();
  const { getAllBouquets, getAllBouquetsRes } = useBouquets();
  const completed = useMemo(
    () => (getAllCategoriesRes && getAllBouquetsRes && !loadingStreams) as boolean,
    [getAllCategoriesRes, getAllBouquetsRes, loadingStreams],
  );
  const [_deleteStreams, loading, _deleteStreamsRes, _deleteStreamsErr] = useRequest(deleteStreams);

  useEffect(() => {
    if (_deleteStreamsRes) {
      getAllStreams(streams.CurrentPage, streams.CountPerPage);
      getAllCategories();
      getAllBouquets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_deleteStreamsRes]);
  useEffect(() => {
    if (_deleteStreamsErr) {
      setLoading(false);
      if (isAxiosError(_deleteStreamsErr)) {
        console.log('_deleteStreamErrText: ', _deleteStreamsErr.response?.data.error);
        console.log('_deleteStreamErrMessage: ', _deleteStreamsErr.response?.data.message);
      } else {
        console.log('_deleteStreamErr: ', _deleteStreamsErr);
      }
    }
  }, [_deleteStreamsErr]);

  useEffect(() => {
    if (completed) {
      setLoading(false);
    }
  }, [completed]);

  return {
    deleteStreams: (...args: unknown[]) => {
      setLoading(true);
      return _deleteStreams(...args);
    },
    completed,
    loading: loadingStreams || loading,
    deleteStreamsErr: _deleteStreamsErr,
  };
};
