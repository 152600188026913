import {
  DownRateSvg,
  FlowRateSvg,
  LiveSvg,
  OnlineConnectionsSvg,
  UpRateSvg,
  WarningSvg,
} from '../../assets/svg';
import { StateItem } from '../../types/stateData';

export const stateItems: StateItem[] = [
  {
    id: 'onlineConnections',
    title: 'Online Connections',
    state: true,
    icon: OnlineConnectionsSvg,
  },
  {
    id: 'activeLines',
    title: 'Active Lines',
    state: true,
    icon: FlowRateSvg,
  },
  {
    id: 'liveStreams',
    title: 'Live Streams',
    state: true,
    icon: LiveSvg,
  },
  {
    id: 'downStreams',
    title: 'Down Streams',
    state: false,
    icon: WarningSvg,
  },
  {
    id: 'networkInput',
    title: 'Network Input',
    unit: 'Mbps',
    state: true,
    icon: UpRateSvg,
  },
  {
    id: 'networkOutput',
    title: 'Network Output',
    unit: 'Mbps',
    state: false,
    icon: DownRateSvg,
  },
];
