/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import { CommonSelectItem } from '../../types/model/commonSelectItem';
import FormTitle from '../common/FormTitle';
import ManageTable from '../common/ManageTable';
import { CellProps, Column } from 'react-table';
import { headerSelectStyles } from '../../styles/reactSelect';
import Popup from 'reactjs-popup';
import Paginator from '../common/Paginator';
import { Connection, User } from '../../types/model/user';
import moment from 'moment';
import ModalWarning from '../common/ModalWarning';
import EditLineModal from './modal/EditLineModal';
import { PopupActions } from 'reactjs-popup/dist/types';
import DownloadPlaylistModal from './modal/DownloadPlaylistModal';
import { useEditLine } from '../../hooks/user/useEditLine';
import { usersAtom } from '../../store/jotai';
import { useAtomValue } from 'jotai';
import { useDeleteLine } from '../../hooks/user/useDeleteLine';
import { cn } from '../../utils/tw-merge';
import { useLines } from '../../hooks/user/useLines';
import FormTable from '../common/FormTable';
import { useBanLine } from '../../hooks/user/useBanLine';
import Tooltip from '../common/Tooltip';

const deleteMessage = 'Are you sure you want to delete this line? All connections will be deleted.';
const banMessage =
  "Are You sure you want to Ban this line? you'll not be able to connected to this line.";

const resetASNMessage =
  "Are you sure you want to Reset current ASN of this line? You'll not be able to have them back.";

const unbanMessage = 'Are You sure you want to unban this line?';

const filterOptions: CommonSelectItem[] = [
  { value: 'active', label: 'Active' },
  { value: 'expired', label: 'Expired' },
  { value: 'online', label: 'Online' },
  { value: 'banned', label: 'Banned' },
];

const showOptions: CommonSelectItem[] = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '250', label: '250' },
  { value: '500', label: '500' },
  { value: '1000', label: '1000' },
];

interface Props {
  className?: string;
}

const ManageLinesForm = ({ className }: Props) => {
  const users = useAtomValue(usersAtom);

  const [params, setParams] = useState<Record<string, string>>();

  const { getAllUsers } = useLines(false);

  const modifyParams = useCallback((param: string, value?: string, order?: 'asc' | 'desc') => {
    setParams(prevParams => {
      const updatedParams = { ...prevParams };
      if (order) {
        if (value === '' || value === undefined || (value === 'id' && order === 'asc')) {
          delete updatedParams.sortBy;
          delete updatedParams.order;
        } else {
          updatedParams.sortBy = value;
          updatedParams.order = order;
        }
      } else if (value === '0' || !value || value === '') {
        if (param === 'search' && value === '0') {
          updatedParams[param] = value;
        } else {
          delete updatedParams[param];
        }
      } else {
        updatedParams[param] = value;
      }
      return updatedParams;
    });
  }, []);

  const { editLine } = useEditLine();
  const { deleteLine } = useDeleteLine();
  const { banLine } = useBanLine();

  const [showNumber, setShowNumber] = useState<CommonSelectItem | null>(showOptions[0]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showBanLineModal, setShowBanLineModal] = useState(false);
  const [showResetASNModal, setShowResetASNModal] = useState(false);
  const [showUnBanLineModal, setShowUnBanLineModal] = useState(false);
  const [showDownloadPlaylistModal, setShowDownloadPlaylistModal] = useState(false);
  const selectedUserRef = useRef<User | null>(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const timer = setInterval(() => {
      getAllUsers(page, Number(showNumber?.value), params);
    }, 10000);

    getAllUsers(page, Number(showNumber?.value), params);
    setShowEditModal(false);

    return () => clearInterval(timer);
  }, [page, showNumber?.value, params]);

  const onEditUser = () => {
    setShowEditModal(true);
  };

  const onDownloadPlaylist = () => {
    setShowDownloadPlaylistModal(true);
  };

  // baning the line implementation
  const onBanLine = () => {
    setShowBanLineModal(true);
  };

  const oncloseBanLine = async () => {
    setShowBanLineModal(false);
    // call the banLine api
    selectedUserRef.current?.is_banned === false &&
      (await banLine({
        ...selectedUserRef.current,
        is_banned: true,
        outputs: [],
      }));
  };

  const onResetASN = () => {
    setShowResetASNModal(true);
  };

  const onCloseResetASNModal = async () => {
    await editLine({ ...selectedUserRef.current, current_asns: [] });
    setShowResetASNModal(false);
  };

  const onUnbanLine = () => {
    setShowUnBanLineModal(true);
  };
  const oncloseUnBanLine = async () => {
    setShowUnBanLineModal(false);

    // call the unbanLine api
    selectedUserRef.current?.is_banned === true &&
      (await banLine({
        ...selectedUserRef.current,
        is_banned: false,
        outputs: [],
      }));
  };

  const onDeleteUser = () => {
    // Show Confirm Modal to Delete User
    setShowDeleteModal(true);
  };

  const onConfirmDeleteUser = () => {
    setShowDeleteModal(false);
    // Call delete user api
    deleteLine(selectedUserRef.current?.username ?? '');
  };

  const onSubmitEditModal = async (user: User) => {
    await editLine(user);
    setShowEditModal(false);
  };

  const onCloseDownloadPlaylistModal = () => {
    setShowDownloadPlaylistModal(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns: Column<User>[] = useMemo(
    () =>
      getLinesHeader(
        (user: User) => {
          selectedUserRef.current = user;
        },
        onEditUser,
        onDownloadPlaylist,
        onDeleteUser,
        onBanLine,
        onUnbanLine,
        onResetASN,
      ),
    [],
  );

  return (
    <div className={cn('w-full max-w-[2500px] mt-[30px] mx-auto mb-0', className)}>
      <FormTitle>Manage Lines</FormTitle>
      <div className='my-10 mx-0 flex justify-between items-center gap-[25px]'>
        <div className='flex-auto max-w-[1000px] flex items-center gap-1'>
          <input
            type='search'
            className='grow max-w-[280px] py-[11px] px-3 rounded-[19px] bg-dark text-[14px] text-form-content'
            autoComplete='on'
            placeholder='Search Lines...'
            onChange={e => {
              modifyParams('search', e.target.value);
            }}
          />

          <Select<CommonSelectItem>
            isClearable
            className='grow max-w-[280px] min-w-[200px] text-[14px] text-form-content'
            options={filterOptions}
            styles={headerSelectStyles}
            placeholder='No filter'
            onChange={value => {
              modifyParams('filter', value?.value);
            }}
          />
        </div>
        <div className='flex items-center gap-4'>
          <span className='text-[14px] text-form-content'>Show</span>
          <Select<CommonSelectItem>
            className='shrink-0 w-[100px] text-[14px] text-form-content'
            options={showOptions}
            styles={headerSelectStyles}
            value={showNumber}
            onChange={value => {
              setShowNumber(value);
            }}
          />
        </div>
      </div>
      <div className='w-full overflow-auto'>
        <ManageTable<User>
          columns={columns}
          data={users.Records}
          onSort={(columnId, isDesc) => {
            modifyParams('sortBy', columnId, isDesc ? 'desc' : 'asc');
          }}
        />
      </div>
      <Paginator
        page={page}
        totalRecords={users.TotalRecords}
        pageSize={Number(showNumber?.value ?? 10)}
        setPage={setPage}
      />
      <ModalWarning
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        noIconOnOk
        nextButtonTitle='Delete'
        handleCancel={() => setShowDeleteModal(false)}
        handleOk={onConfirmDeleteUser}
      >
        {deleteMessage}
      </ModalWarning>
      <ModalWarning
        isOpen={showBanLineModal}
        onClose={oncloseBanLine}
        noIconOnOk
        noIconOnCancel
        nextButtonTitle='Ban Line'
        handleCancel={() => setShowBanLineModal(false)}
        handleOk={oncloseBanLine}
      >
        {banMessage}
      </ModalWarning>
      <ModalWarning
        isOpen={showUnBanLineModal}
        onClose={oncloseUnBanLine}
        noIconOnOk
        noIconOnCancel
        nextButtonTitle='Unban Line'
        handleCancel={() => setShowUnBanLineModal(false)}
        handleOk={oncloseUnBanLine}
      >
        {unbanMessage}
      </ModalWarning>
      <ModalWarning
        isOpen={showResetASNModal}
        onClose={onCloseResetASNModal}
        noIconOnOk
        noIconOnCancel
        nextButtonTitle='Reset ASN'
        handleCancel={() => {
          setShowResetASNModal(false);
        }}
        handleOk={onCloseResetASNModal}
      >
        {resetASNMessage}
      </ModalWarning>
      {selectedUserRef.current && (
        <EditLineModal
          user={selectedUserRef.current}
          isOpen={showEditModal}
          onSubmit={onSubmitEditModal}
          onClose={() => {
            setShowEditModal(false);
            selectedUserRef.current = null;
          }}
        />
      )}
      {selectedUserRef.current && (
        <DownloadPlaylistModal
          user={selectedUserRef.current}
          isOpen={showDownloadPlaylistModal}
          onClose={onCloseDownloadPlaylistModal}
        />
      )}
    </div>
  );
};

export default ManageLinesForm;

const renderStatus: React.FC<CellProps<User, boolean>> = cell => {
  const date = moment(new Date(cell.row.original.expiry_date * 1000));
  const currentDate = moment();
  const isExpired = cell.row.original.expiry_date > 0 && date.isBefore(currentDate);

  return (
    <div style={{ fontSize: '16px', color: cell.value ? '#4bd075' : '#f8cc6b' }}>
      {cell.row.original.is_banned ? (
        <span className='text-[#fefe00] text-sm font-semibold'>Banned</span>
      ) : isExpired ? (
        <span className=' text-[#ff7b7b] text-sm font-semibold'>Expired</span>
      ) : (
        <span className=' text-[#77efa1] text-sm font-semibold'>Active</span>
      )}
    </div>
  );
};

const RenderBoolean = (cell: CellProps<User, boolean>): JSX.Element => {
  const asns = cell.row.original?.current_asns;
  return (
    <Tooltip
      message={
        asns ? (
          <div className='flex flex-col'>
            {asns.map(asn => (
              <div key={asn.asn_number}>{`${asn.asn_number} / ${asn.asn_name}`}</div>
            ))}
          </div>
        ) : (
          ''
        )
      }
      disabled={!asns || asns.length === 0}
      trigger={
        <div>
          <div
            style={{
              margin: '0 auto',
              width: '28px',
              height: '28px',
              borderRadius: '8px',
              background: cell.value ? '#7bffa5' : 'white',
            }}
          ></div>
          {cell.column.Header === 'ASN' && cell.row.original.is_asn_lock && (
            <div>
              {cell.row.original?.current_asns?.length ?? 0}/{cell.row.original?.max_asn ?? 0}
            </div>
          )}
        </div>
      }
    />
  );
};

const RenderActive: React.FC<CellProps<User, number>> = cell => {
  const [openChannelDialog, setOpenChannelDialog] = useState(false);
  return (
    <>
      <ConnectionsModal
        openChannelDialog={openChannelDialog}
        setOpenChannelDialog={setOpenChannelDialog}
        connections={cell.row.original.connections}
      />
      <div
        style={{
          margin: '0 auto',
          height: '28px',
          width: 'fit-content',
          cursor: 'pointer',
          padding: '0 9px',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // background: cell.value ? '#7bffa5' : 'yellow',
          fontSize: '16px',
          color: '#white',
        }}
        onClick={() => {
          if (cell.row.original.connections?.length > 0) {
            setOpenChannelDialog(true);
          }
        }}
      >
        {`${cell.row.original?.connections?.length || 0}/${cell.value}`}
      </div>
    </>
  );
};

const ConnectionsModal = ({
  openChannelDialog,
  setOpenChannelDialog,
  connections,
}: {
  openChannelDialog: boolean;
  setOpenChannelDialog: (value: boolean) => void;
  connections: Connection[];
}) => {
  return (
    <ModalWarning
      isOpen={openChannelDialog}
      onClose={() => setOpenChannelDialog(false)}
      handleOk={() => setOpenChannelDialog(false)}
      handleCancel={() => setOpenChannelDialog(false)}
      className='items-end py-0 w-[1200px] max-w-auto'
      hideCancel
    >
      <div className='text-start w-full mb-5 text-[22px]'>Connections</div>
      <FormTable<Connection>
        columns={[
          {
            Header: 'IP',
            accessor: 'ip',
          },
          {
            Header: 'Name',
            accessor: 'channel_name',
          },
          {
            Header: 'Type',
            accessor: 'channel_type',
          },
          {
            Header: 'ISP',
            accessor: 'isp',
          },
          {
            Header: 'ASN',
            accessor: 'asn_number',
          },
          {
            Header: 'Country',
            accessor: 'country',
          },
          {
            Header: 'Watch Time',
            accessor: 'start_time',
            Cell: cell => {
              const duration = moment.utc(moment().diff(moment(cell.value * 1000)));
              return (
                <div>{`${duration.hours() > 0 ? `${duration.hours()}h ` : ''}${
                  duration.minutes() > 0 ? `${duration.minutes()}m ` : ''
                }${duration.seconds()}s`}</div>
              );
            },
          },
        ]}
        data={connections ?? []}
        className='text-[15px]'
      />
    </ModalWarning>
  );
};

const RenderChannels: React.FC<CellProps<User, Connection[]>> = cell => {
  const [openChannelDialog, setOpenChannelDialog] = useState(false);
  return (
    <>
      <ConnectionsModal
        openChannelDialog={openChannelDialog}
        setOpenChannelDialog={setOpenChannelDialog}
        connections={cell.row.original.connections}
      />
      <div
        style={{
          margin: '0 auto',
          height: '28px',
          cursor: 'pointer',
          width: 'fit-content',
          padding: '0 9px',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // background: cell.value ? '#7bffa5' : 'yellow',
          fontSize: '16px',
          color: '#white',
        }}
        onClick={() => {
          if (cell.row.original.connections?.length > 0) {
            setOpenChannelDialog(true);
          }
        }}
      >
        {cell.value ? cell.value[0]?.channel_name : ''}
      </div>
    </>
  );
};

const renderDate = (cell: CellProps<User, number>): JSX.Element => {
  const dateStr = moment(new Date(cell.value * 1000)).format('DD-MM-yyyy HH:mm:ss');
  const date = moment(new Date(cell.value * 1000));
  const currentDate = moment();
  const isExpired = date.isBefore(currentDate);
  return (
    <div>
      {cell.value === 0 ? (
        <h2 className='text-base text-[#77efa1]'>Never</h2>
      ) : (
        <>
          <h2
            className={`text-base ${
              cell.row.original.is_banned
                ? 'text-[#fefe00]'
                : isExpired
                ? 'text-[#ff7b7b]'
                : 'text-[#77efa1]'
            }`}
          >
            {dateStr.split(' ')[0]}
          </h2>
          <h2
            className={`${
              cell.row.original.is_banned
                ? 'text-[#fefe00]'
                : isExpired
                ? 'text-[#ff7b7b]'
                : 'text-[#77efa1]'
            }`}
          >
            {dateStr.split(' ')[1]}
          </h2>
        </>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const popupBody = (
  onEditUser: () => void,
  onDownloadPlaylist: () => void,
  onDeleteUser: () => void,
  onBanLine: () => void,
  onUnbanLine: () => void,
  onResetASN: () => void,
  user: User,
) => {
  return (
    <div className='popup-menu'>
      <div className='popup-menu-item' onClick={onEditUser}>
        Edit Line
      </div>
      <div className='popup-menu-item' onClick={onDownloadPlaylist}>
        Download Playlist
      </div>
      <div className='popup-menu-item' onClick={onDeleteUser}>
        Delete Line
      </div>
      {user.is_banned ? (
        <div className='popup-menu-item' onClick={onUnbanLine}>
          Unban Line
        </div>
      ) : (
        <div className='popup-menu-item' onClick={onBanLine}>
          Ban Line
        </div>
      )}
      {user.is_asn_lock && (
        <div className='popup-menu-item' onClick={onResetASN}>
          Reset ASN&apos;s
        </div>
      )}
    </div>
  );
};

const RenderPopup = (
  cell: CellProps<User, number>,
  onSelectUser: (user: User) => void,
  onEditUser: () => void,
  onDownloadPlaylist: () => void,
  onDeleteUser: () => void,
  onBanLine: () => void,
  onUnbanLine: () => void,
  onResetASN: () => void,
): JSX.Element => {
  const user = cell.row.original;
  const popupRef = useRef<PopupActions | null>(null);

  const _onEditUser = () => {
    popupRef.current?.close();
    onEditUser();
  };
  const _onDownloadPlaylist = () => {
    popupRef.current?.close();
    onDownloadPlaylist();
  };
  const _onDeleteUser = () => {
    popupRef.current?.close();
    onDeleteUser();
  };
  const _onBanLine = () => {
    popupRef.current?.close();
    onBanLine();
  };
  const _onUnbanLine = () => {
    popupRef.current?.close();
    onUnbanLine();
  };
  const _onResetASN = () => {
    popupRef.current?.close();
    onResetASN();
  };

  return (
    <Popup
      ref={popupRef}
      trigger={
        <div className='py-1.5 px-0 rounded-[16px] bg-dark text-[16px] [box-shadow:0px_2px_13px_1px_#1e1e3f] hover:[filter:brightness(2)] cursor-pointer focus:outline-offset-0 focus:[white_solid_2px] active:[box-shadow:0px_2px_13px_0px_#1e1e3f] active:opacity-70'>
          {' '}
          Actions{' '}
        </div>
      }
      position='left center'
      on='click'
      closeOnDocumentClick
      onOpen={() => onSelectUser(user)}
      closeOnEscape
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      arrow={false}
    >
      {popupBody(
        _onEditUser,
        _onDownloadPlaylist,
        _onDeleteUser,
        _onBanLine,
        _onUnbanLine,
        _onResetASN,
        user,
      )}
    </Popup>
  );
};

const getLinesHeader: (
  onSelectUser: (user: User) => void,
  onEditUser: () => void,
  onDownloadPlaylist: () => void,
  onDeleteUser: () => void,
  onBarnLine: () => void,
  onUnbanLine: () => void,
  onResetASN: () => void,
) => Column<User>[] = (
  onSelectUser,
  onEditUser,
  onDownloadPlaylist,
  onDeleteUser,
  onBanLine,
  onUnbanLine,
  onResetASN,
) => [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'UserName',
    accessor: 'username',
  },
  {
    Header: 'Password',
    accessor: 'password',
    disableSortBy: true,
  },
  // {
  //   Header: 'Owner',
  //   accessor: 'group',
  // },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: renderStatus,
  },
  {
    Header: 'Online',
    accessor: 'online',
    Cell: RenderBoolean,
    disableSortBy: true,
  },
  // {
  //   Header: 'Trial',
  //   accessor: 'trial',
  //   Cell: renderOnline,
  // },
  // {
  //   Header: 'Restreamer',
  //   accessor: 'restreamer',
  //   Cell: renderOnline,
  // },
  // {
  //   Header: 'Active',
  //   accessor: 'active',
  //   Cell: renderActive,
  // },
  {
    Header: 'Connections',
    accessor: 'max_connections',
    disableSortBy: true,
    Cell: RenderActive,
  },
  {
    Header: 'Channels',
    accessor: 'connections',
    Cell: RenderChannels,
    disableSortBy: true,
  },
  {
    Header: 'ASN',
    accessor: 'is_asn_lock',
    Cell: RenderBoolean,
    disableSortBy: true,
  },
  {
    Header: 'Expirations',
    accessor: 'expiry_date',
    Cell: renderDate,
    id: 'expire',
  },

  {
    Header: 'Actions',
    disableSortBy: true,
    Cell: (cell: CellProps<User, number>) =>
      RenderPopup(
        cell,
        onSelectUser,
        onEditUser,
        onDownloadPlaylist,
        onDeleteUser,
        onBanLine,
        onUnbanLine,
        onResetASN,
      ),
  },
];
