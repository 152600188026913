import React from 'react';
import { menuItems, settingMenuItems } from '../config/data/menuData';
import DownMenu from '../components/layout/DownMenu';
import UserInfo from '../components/layout/UserInfo';

const SideBar = () => {
  return (
    <nav className='[flex:0_0_217px] p-0 overflow-y-auto sm:flex hidden flex-col gap-5'>
      <UserInfo className='shrink-0 grow-0 h-[124px] w-full card' />
      <DownMenu menuItems={menuItems} className='shrink-0 grow-0 w-full card' />
      <DownMenu menuItems={settingMenuItems} className='shrink-0 grow-0 w-full card' />
    </nav>
  );
};

export default SideBar;
