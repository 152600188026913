import React from 'react';
import { Column, useSortBy, useTable } from 'react-table';
import { SortAscSvg, SortDescSvg, SortSvg } from '../../assets/svg';
import { cn } from '../../utils/tw-merge';

export interface Props<T extends object> {
  columns: Column<T>[];
  data: T[];
  onClickRow?: (id: unknown) => void;
  selectedId?: unknown[];
  headFontSize?: number;
  bodyFontSize?: number;
  rowHeight?: number;
  className?: string;
}

const FormTable = <T extends object>({
  columns,
  data,
  onClickRow,
  selectedId,
  headFontSize = 20,
  bodyFontSize = 20,
  rowHeight,
  className = '',
}: Props<T>) => {
  const isViewOnly = onClickRow ? false : true;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data },
    useSortBy,
  );

  return (
    <table
      {...getTableProps()}
      className={cn('my-3 mx-0 w-full text-center border-collapse', className)}
    >
      <thead
        className={`bg-card`}
        style={{
          fontSize: `${headFontSize}px`,
        }}
      >
        {headerGroups.map(headerGroup => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            key={headerGroup.getHeaderGroupProps()['key']}
            className='w-full border-b border-solid border-element-card rounded-lg [box-shadow:inset_0px_4px_4px_rgba(0,0,0,0.25)]'
          >
            {headerGroup.headers.map(column => {
              return (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.getHeaderProps()['key']}
                  className='whitespace-nowrap'
                >
                  <div className='py-[7px] px-0 flex justify-center items-center gap-1'>
                    {column.render('Header')}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <SortDescSvg />
                      ) : (
                        <SortAscSvg />
                      )
                    ) : (
                      <SortSvg />
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody
        {...getTableBodyProps()}
        style={{
          fontSize: `${bodyFontSize}px`,
        }}
        className="before:content-[''] before:block before:h-6"
      >
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              key={row.getRowProps()['key']}
              onClick={() => onClickRow && onClickRow(row.values.id)}
              style={{
                height: rowHeight ? `${rowHeight}px` : '',
              }}
              className={cn(
                `bg-card odd:bg-table-odd-row ${
                  isViewOnly ? '' : 'cursor-pointer hover:[filter:brightness(2)]'
                } `,
                `${
                  selectedId
                    ? selectedId.includes(row.values.id)
                      ? 'bg-combobox-on odd:bg-[#845bed]'
                      : ''
                    : ''
                }`,
              )}
            >
              {row.cells.map(cell => (
                <td {...cell.getCellProps()} key={cell.getCellProps()['key']} className='py-1 px-0'>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default FormTable;
