import React from 'react';
import Select from 'react-select';
import { settingSelectStyles } from '../../../styles/reactSelect';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';

const ffmpegSegmentTypeOptions: CommonSelectItem[] = [
  { value: 'his', label: '-f his' },
  { value: 'segment', label: '-f segment' },
];

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const FfmpegSegmentType = ({ className, value, onChange }: Props) => {
  return (
    <div className={className}>
      <Select<CommonSelectItem>
        className='form-item-select'
        options={ffmpegSegmentTypeOptions}
        styles={settingSelectStyles}
        value={
          ffmpegSegmentTypeOptions[
            ffmpegSegmentTypeOptions.findIndex(option => option.value === value)
          ]
        }
        onChange={newValue => onChange(newValue?.value ?? '')}
      />
    </div>
  );
};

export default FfmpegSegmentType;
