import React from 'react';
import { cn } from '../../utils/tw-merge';

interface CommonButtonProps {
  onClick?: () => void;
  type?: 'submit' | 'button';
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export const CommonButton = ({
  onClick,
  type = 'button',
  className,
  children,
  style = {},
  disabled = false,
}: CommonButtonProps) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={style}
      className={cn(
        'shrink-0 flex flex-row items-center justify-center cursor-pointer relative overflow-hidden bg-transparent disabled:bg-placeholder-dark shadow-[0px_2px_13px_1px_#1e1e3f] hover:opacity-80 focus:outline-offset-0 focus:[white_solid_1px] active:opacity-70 active:[box-shadow:0px_2px_13px_0px_#1e1e3f]',
        className,
      )}
    >
      {children}
    </button>
  );
};

export const RadiusButton = ({ className, ...props }: CommonButtonProps) => {
  return <CommonButton {...props} className={cn('rounded-lg p-[2px]', className)} />;
};

export const MiddleButton = ({
  className,
  bgColor,
  ...props
}: CommonButtonProps & { bgColor?: string }) => {
  return (
    <CommonButton
      {...props}
      style={{
        backgroundColor: bgColor || 'inherit',
      }}
      className={cn('py-[6px] px-[21px] text-[13px] rounded-lg ', className)}
    />
  );
};

export const ComboboxButton = ({ className, ...props }: CommonButtonProps) => {
  return (
    <RadiusButton {...props} className={cn('border-2 border-solid border-white', className)} />
  );
};

export const WhiteBorderButton = ({ className, ...props }: CommonButtonProps) => {
  return (
    <CommonButton
      {...props}
      className={cn(
        'h-[38px] py-0 px-[25px] rounded-lg border-2 border-solid border-[#d6d6ff] font-semibold text-[16px] text-form-content',
        className,
      )}
    />
  );
};

export const FormButton = ({ className, ...props }: CommonButtonProps) => {
  return (
    <CommonButton
      className={cn(
        'gap-[46px] py-[9px] px-4 rounded-lg bg-placeholder-dark font-semibold text-[16px] text-text-button',
        className,
      )}
      {...props}
    />
  );
};

export const ColorableButton = ({
  bgColor,
  ...props
}: CommonButtonProps & { bgColor?: string }) => {
  return (
    <FormButton
      {...props}
      style={{
        backgroundColor: bgColor || 'inherit',
      }}
    />
  );
};

export const PrimaryFormButton = ({ className, ...props }: CommonButtonProps) => {
  return <FormButton {...props} className={cn('bg-primary-button', className)} />;
};

export const SecondaryFormButton = ({ className, ...props }: CommonButtonProps) => {
  return <FormButton {...props} className={cn('bg-dark', className)} />;
};

export const SubmitFormButton = (props: CommonButtonProps) => {
  return <PrimaryFormButton {...props} type='submit' />;
};

export const IconButton = ({
  className,
  color,
  size,
  ...props
}: CommonButtonProps & { color?: string; size?: number }) => {
  return (
    <CommonButton
      {...props}
      style={{
        width: size ? size + 'px' : 'auto',
        height: size ? size + 'px' : 'auto',
        backgroundColor: color || 'inherit',
      }}
      className={cn('rounded-[50%]', className)}
    />
  );
};

export const IconSimpleButton = ({
  className,
  ...props
}: CommonButtonProps & { color?: string; size?: number }) => {
  return (
    <IconButton
      {...props}
      className={cn('shadow-none active:shadow-none focus:outline-none', className)}
    />
  );
};

export const ChipsButton = ({
  className,
  color,
  height,
  ...props
}: CommonButtonProps & { color?: string; height?: number }) => {
  return (
    <CommonButton
      {...props}
      style={{
        backgroundColor: color || 'inherit',
        height: height ? height + 'px' : '32px',
        borderRadius: height ? height / 2 + 'px' : '16px',
      }}
      className={cn('px-[10px] gap-[6px] font-semibold text-[12px]', className)}
    />
  );
};
