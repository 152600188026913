/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import FormFooter from '../common/FormFooter';
import { Bouquet } from '../../types/model/bouquet';

export interface BouquetsDetailsFormInput {
  bouquetsName: string;
}
interface Props {
  formData?: Bouquet;
  setFormData?: React.Dispatch<React.SetStateAction<Bouquet>>;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
}

const BouquetsDetailsForm = ({ formData, setFormData, setVisibleTab }: Props) => {
  const { handleSubmit, getValues, control, setFocus } = useForm<BouquetsDetailsFormInput>({
    defaultValues: {
      bouquetsName: formData?.name ?? '',
    },
  });

  useEffect(() => {
    return () => {
      if (setFormData)
        setFormData(currentLine => ({
          ...currentLine,
          name: getValues().bouquetsName,
        }));
    };
  }, []);

  useEffect(() => {
    setFocus('bouquetsName');
  }, [])

  const onSubmit: SubmitHandler<BouquetsDetailsFormInput> = data => {
    if (data.bouquetsName.length === 0) {
      setFocus('bouquetsName');
      return;
    }
    if (setVisibleTab) setVisibleTab(2);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name='bouquetsName'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Bouquet Name</span>
              <div className='form-item-content'>
                <input type='text' className='form-item-input' autoComplete='off' {...field} />
              </div>
            </div>
          )}
        />

        <FormFooter hidePrev noIconOnNext />
      </form>
    </div>
  );
};

export default BouquetsDetailsForm;
