import React from 'react';
import FormTab from '../common/FormTab';
import FormTitle from '../common/FormTitle';
import { TabData } from '../common/Tab';
import CategoriesMoviesForm from './CategoriesMoviesForm';
import CategoriesRadioForm from './CategoriesRadioForm';
import CategoriesSeriesForm from './CategoriesSeriesForm';
import CategoriesStreamsForm from './CategoriesStreamsForm';
import { cn } from '../../utils/tw-merge';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'Streams',
    tabComponent: CategoriesStreamsForm,
  },
  {
    id: 2,
    tabTitle: 'Movies',
    tabComponent: CategoriesMoviesForm,
  },
  {
    id: 3,
    tabTitle: 'Series',
    tabComponent: CategoriesSeriesForm,
  },
  {
    id: 4,
    tabTitle: 'Radio',
    tabComponent: CategoriesRadioForm,
  },
];

interface Props {
  className?: string;
}

const CategoriesForm = ({ className }: Props) => {
  return (
    <div className={cn('max-w-full w-[755px] my-[2px] mx-auto', className)}>
      <FormTitle>Categories</FormTitle>
      <FormTab data={tabData} currentTab={tabData[0].id} />
    </div>
  );
};

export default CategoriesForm;
