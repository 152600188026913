import React, { useEffect, useRef } from 'react';
import ModalForm from '../../common/ModalForm';
import Hls from 'hls.js';
import { getSessionId } from '../../../lib/api';

interface Props {
  sourceURL: string;
  isOpen: boolean;
  onClose: () => void;
}

const HLSPlayerModal = ({ sourceURL, isOpen, onClose }: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const config = {
    xhrSetup: (xhr: XMLHttpRequest) => {
      xhr.setRequestHeader('Authorization', getSessionId() || '');
    },
  };
  const hls = new Hls(config);
  useEffect(() => {
    const initializeHls = () => {
      if (!sourceURL) return;
      if (videoRef.current) {
        if (Hls.isSupported()) {
          console.log('HLS is supported init hls');
          hls?.attachMedia(videoRef.current);
          hls?.stopLoad();
          hls?.loadSource(sourceURL);
        } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
          videoRef.current.src = sourceURL;
        } else {
          console.error('HLS is not supported in this browser.');
        }
      }
    };

    initializeHls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceURL, videoRef.current]);

  return (
    <ModalForm
      isOpen={isOpen}
      onClose={() => {
        onClose();
        try {
          console.log('Destroying hls');
          hls?.stopLoad();
          hls?.detachMedia();
          hls?.destroy();
          videoRef.current?.pause();
          videoRef.current?.remove();
        } catch (e) {
          console.log('Error destroying hls', e);
        }
      }}
      title={'Player'}
    >
      <video style={{ width: '100%', height: '100%' }} ref={videoRef} controls autoPlay/>
    </ModalForm>
  );
};

export default HLSPlayerModal;
