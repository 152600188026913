import { useEffect } from 'react';
import { startStream } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { useStreams } from './useStreams';
import { isAxiosError } from 'axios';
import { useAtomValue } from 'jotai';
import { streamsAtom } from '../../store/jotai';

export const useStartStream = () => {
  const streams = useAtomValue(streamsAtom);
  const { getAllStreams } = useStreams(false);
  const [_startStream, , _startStreamRes, _startStreamErr] = useRequest(startStream);

  useEffect(() => {
    if (_startStreamRes) {
      console.log('_startStreamRes: ', _startStreamRes);
      getAllStreams(streams.CurrentPage, streams.CountPerPage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_startStreamRes]);
  useEffect(() => {
    if (_startStreamErr) {
      if (isAxiosError(_startStreamErr)) {
        console.log('_startStreamErrText: ', _startStreamErr.response?.data.error);
        console.log('_startStreamErrMessage: ', _startStreamErr.response?.data.message);
      } else {
        console.log('_startStreamErr: ', _startStreamErr);
      }
    }
  }, [_startStreamErr]);
  return { startStream: _startStream };
};
