import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import Multiselect from 'multiselect-react-dropdown';
import { StreamDetailsFormInput } from './StreamDetailsForm';
import { multiSelectStyle } from '../../../styles/reactMultiSelect';
import { Bouquet } from '../../../types/model/bouquet';
import { useAtomValue } from 'jotai';
import { bouquetsAtom } from '../../../store/jotai';

export const getBouquetsOptions = (bouquets: Bouquet[], bouquetIds: number[]): Bouquet[] => {
  return bouquets.filter(bouquet => bouquetIds?.includes(bouquet.id));
};
interface Props {
  className?: string;
  field: ControllerRenderProps<StreamDetailsFormInput, 'bouquets'>;
}

const Bouquets = ({ className, field }: Props) => {
  const bouquets = useAtomValue(bouquetsAtom);
  return (
    <div className={className}>
      <Multiselect
        id={'bouquets'}
        displayValue='name'
        className='form-item-select'
        options={bouquets}
        selectedValues={field.value}
        onSelect={field.onChange}
        onRemove={field.onChange}
        style={multiSelectStyle}
        showCheckbox
        avoidHighlightFirstOption
      />
    </div>
  );
};

export default Bouquets;
