interface TooltipMessage {
  [variableName: string]: string;
}

const lineAdvancedTooltipMessage: TooltipMessage = {
  forcedConnections:
    'Force this user to connect to a specific server. Otherwise, the server with the lowest load will be selected.',
  ministraPortal:
    'Select this option if you intend to use this account with your Ministra portal. Output formats, expiration and connections below will be ignored. Only MPEG-TS output is allowed.',
  restreamer: 'If selected, this user will not be blocked for restreaming channels.',
  accentToken:
    'Generate an access token that can be used in place of username and password. If you use this option, playlists generated will contain the access token as auth.',
  forcedCountry: 'Force user to connect to loadbalancer associated with the selected country.',
};

const streamAdvancedTooltipMessage: TooltipMessage = {
  generatePTS:
    'Allow FFmpeg to generate presentation timestamps for you to achieve better synchronization with the stream codecs. In some streams this can cause de-sync.',
  nativeFrames:
    'You should always read live streams as non-native frames, however if you are streaming static video files, set this to true otherwise the encoding process will fail.',
  streamAllCodecs:
    'This option will stream all codecs from your stream, Some streams, Some streams have more than one audio/video/subtitles channels.',
  directSource: "Don't run source through XUI, just redirect instead.",
  directStream: `When using direct source, hide the original URL by proxying the live stream through your servers via UDP. MPEG-TS and HLS is supported as an input format, however only MPEG-TS is supported as an output format to clients.

  Experimental! This may not work for all streams.`,
  restartOnFPSDrop: 'Enable restart on FPS drop, set the threshold accordingly.',
  fpsThreshold: "Stream will restart if it drops bellow x% of it's original FPS, Maximum of 100%.",
  customChannelSID:
    "Here you can specify the SID of the channel in order to work with the epg on the enigma2 devices. You have to specify the code with the ':' but without the first number, 1 or 4097 . Example: if we have this code:  '1:0:1:13f:157c:13e:820000:0:0:0:2097' then you have to add on this field:  ':0:1:13f:157c:13e:820000:0:0:0:",
  onDemandProbeSize:
    'Adjustable probesize for ondemand streams. Adjust this setting if you experience issues with no audio.',
  minuteDelay: 'Delay stream by X minutes. Will not work with on demand streams.',
  httpProxy: 'Format: ip:port',
  cookie: 'Format: key=value;',
  headers: 'FFmpeg -headers command.',
  transcodingProfile:
    'Sometimes, in order to make a stream compatible with most devices, it must be transcoded. Please note that the transcode will only be applied to the server(s) that take the stream directly from the source, all other servers attached to the transcoding server will not transcode the stream.',
  adaptiveLink:
    "Link multiple streams together when HLS is requested, the player will select a suitable stream based on the available bandwidth. The selected streams do not need to be in the line's bouquet, or any bouquet at all. MPEG-TS will play this stream normally.",
  syncTitle:
    'Synchronise stream title with a provider stream. You need to add your strema providers to XUI in order for this to work, the title will be updated every time the cron runs.',
};

const streamRTMPushTooltipMessage: TooltipMessage = {
  outputRtmp: 'Feed stream to the RTMP server for output to RTMP clients.',
};

const streamServersTooltipMessage: TooltipMessage = {
  lowLatencyOnDemand: `LLOD v2 - FFMPEG
  The first source is selected without probing and passed directly to FFMPEG for processing. If the first source is down, the stream will not start.

  LLOD v3 - PHP
  A bespoke segment parser developed by XUI.one to take incoming MPEG-TS streams and identify keyframes in order to segment the stream to deliver back to the client. Very fast, still experimental however. This method doesn't suffer from issues such as lost audio as ffmpeg isn't involved at all but does not support anything but MPEG-TS sources.`,
};

const settingGeneralTooltipMessage: TooltipMessage = {
  serverName: `The name of your streaming service.`,
  serverTimeZone: `Default timezone for the Admin & Reseller Interface, this will be the default for all users unless they change their profile timezone.`,
  messageOfDay: `Message to show in the player API. Used by some android apps.`,
  showEntries: `Number of table entries to show by default in the Admin & Reseller Interface.`,
  failsPerTime: `How long to track stream failures for on Streams view page. Fails per X seconds.`,
  dateFormat: `Default date format to use. Please look up PHP date formatting before changing this.`,
  datetimeFormat: `Default datetime format to use. Please look up PHP date formatting before changing this.`,
  groupStreamsTable: `Toggle to group multiple servers per stream into a single row, this will reduce the amount of rows to display.`,
  seamlessNavigation: `Enable seamless navigation by utilising javascript to load pages. Turned off on mobile devices.`,
  showTicketsIcon: `Show tickets icon in the top right of the navigation menu. Turning this off will move Tickets to the Management menu.`,
  disableRestartCounter: `Removes the restart count next to stream uptime.`,
  autoCleanupFiles: `Automatically clean up redundant files in the background. Recommended.`,
  checkVodCron: `Check that VOD exists periodically, if not set it to not-encoded. Not recommended if you have a lot of VOD.`,
  showImagesAndPicons: `Show channel logos and VOD images in the management pages.`,
  groupButtons: `Group action buttons into a drop-down list on compatible pages.`,
  quickEditModal: `When clicking Edit, open in a modal without navigating away from the page.`,
  mySqlSleepTimeout: `How long to allow mysql connections to remain in Sleep before killing them. Set to 0 to disable.`,
  showGraphs: `Enable dashboard statistic graphs for System Resources, Network and Connections.`,
  showConnectionsMap: `Show connection map on the dashboard.`,
  alternateServerView: `Display servers on the dashboard with an alternate layout, wide vs square layout.`,
  showHeaderStats: `Show server statistics in header menu.`,
  showServiceStatus: `Show warning information based on server stats.`,
  cpuThreshold: `When CPU usage is above this percentage it will show as a warning in the service status box.`,
  memoryThreshold: `When memory usage is above this percentage it will show as a warning in the service status box.`,
  diskThreshold: `When disk usage is above this percentage it will show as a warning in the service status box.`,
  networkThreshold: `When network usage is above this percentage it will show as a warning in the service status box.`,
  clientsThreshold: `When number of clients as a percent of max server clients is above this percentage it will show as a warning in the service status box.`,
  enableSearch: `Toggle the search box in the top right of the header and allow the cache engine to write search queries to the database.`,
  numberOfItems: `How many search results to display. Maximum of 100.`,
  disableTrials: `Use this option to temporarily disable generating trials for all lines.`,
  sslCustomDns: `Use HTTPS in playlist downloads if the main server has SSL on and the reseller has a custom DNS.`,
  debugMode: `Automatically clean up redundant files in the background. Recommended.`,
  autoSendLogsToXui: `Sends panel error logs automatically to be parsed and verified by the XUI team.`,
  enableReCaptcha: `Click here to show active domains for your servers and resellers that you should consider adding to reCAPTCHA.`,
  reCaptchaV2SiteKey: `Please visit https://google.com/recaptcha/admin to obtain your API keys.`,
  reCaptchaV2SecretKey: `Please visit https://google.com/recaptcha/admin to obtain your API keys.`,
  httpProxy: `Format: ip:port`,
  cookie: `Format: key=value;`,
  headers: `FFmpeg -headers command.`,
  onDemandProbeSize: `Adjustable probesize for ondemand streams. Adjust this setting if you experience issues with no audio.`,
};

const settingSecurityTooltipMessage: TooltipMessage = {
  matchSubnetOfIp: `Some IP's change quite often, however usually within the same /24 subnet. Enable this if you want to keep the IP security but loosen the IP matching a little. An example being IP 159.55.26.0 will verify as being the same as 159.55.26.255 instead of failing.`,
  logoutOnIpChange: `Enable to destroy sessions if the IP changes during use, this will safeguard you from cookie attacks.`,
  restrictToSameIp: `Tie HLS connections to their IP address. Turn this off if you're having issues with dynamic IP's.`,
  randomRtmpIp: `Use a random IP for RMTP connections.`,
  disallow2ndIp: `Disallow connection from different IP when a connection is in use.`,
  disallowIfConnections: `Maximum amount of connections a line can have before Disallow 2nd IP is disabled. If you set this to 3, any line with 3 or less connections will be disconnected if they connect from a different IP.`,
  XuiDetectDeny: `Deny connections from non-restreamers who are trying to use XUI.one to restream.`,
  XuiDetectBanLines: `Ban lines of non-restreamers who are trying to use XUI.one to restream.`,
  blockHostingServers: `Automatically block servers from server hosting providers. This won't affect allowed restreamers.`,
  blockProxiesVpns: `Automatically block proxies and VPN's based on their ASN. This won't affect allowed restreamers.`,
  floodLimit: `Number of attempts before IP is blocked. Enter 0 to disable flood detection.`,
  perSeconds: `Number of seconds between requests.`,
  authFloodLimit: `Number of attempts before connections are slowed down. Enter 0 to disable authorised flood detection.
  
  This is separate to the normal Flood Limit as it only affects legitimate clients with valid credentials. As an example you can set this up so that after 30 connections in 10 seconds, the requests for the next 10 seconds will sleep for 1 second first to slow them down.`,
  authFloodSeconds: `Number of seconds to calculate number of requests for.`,
  authFloodSleep: `How long to sleep for when when the limit has been reached. The request will continue after this sleep.`,
  floodIpExclusions: `Separate each IP with a comma.`,
  detectMacBruteforce: `Automatically detect and block IP addresses trying to bruteforce MAG / Enigma devices. Enter 0 attempts to disable.`,
  detectUsernameBruteforce: `Automatically detect and block IP addresses trying to bruteforce lines. Enter 0 attempts to disable.`,
  brueforceFrequency: `Time between attempts for MAC and Username bruteforce. X attempts per X seconds.`,
  maximumLoginAttempts: `How many login attempts are permitted before banning IP address. Use 0 for unlimited, or if you have other measures in place such as reCAPTCHA or access code.`,
  maxSimultaneousDownloads: `Max number of simultaneous EPG & Playlist downloads per user (restreamers aren't affected). Any additional requests will be served a 429 Too Many Requests error. Set this to 0 to disable.`,
};

const settingApiTooltipMessage: TooltipMessage = {
  tmdbKey: `Get your API key at https://www.themoviedb.org/settings/api`,
  tmdbLanguage: `Default language for TMDb requests, you can override this per movie or series.`,
  downloadImages: `If this option is set, images from TMDb for example will be downloaded to the main server.`,
  apiRedirect: `Redirect API stream requests using AES encrypted tokens instead of defaulting the app to user / pass requests. This will be more widely used in the future and can remain disabled for now.`,
  appendMovieYear: `Automatically append the movie year when using TMDb or watch folder.`,
  apiContainer: `Default container to use in Android / Smart TV apps.`,
  cachePlaylistsFor: `If this value is more than 0, playlists downloaded by clients will be cached to file for that many seconds. This can use a lot of disk space if you have a lot of clients, however will save a lot of resources in execution time.`,
  grabPlaylistFromMySql: `Enable this to read streams from MySQL instead of from the local cache. This may be faster when you have a significant amount of streams.`,
  forceEpgToUtcTimezone: `Ensure all EPG is generated as UTC and times shown in API's are UTC. This will change the timezone in player API to UTC also to sync with apps.`,
  keepRequestProtocol: `Keep the requested protocol (http or https) in playlists and streams.`,
  vodParser: `Whether to use GuessIt or PTN to parse filenames for Watch Folder etc. GuessIt is far better but uses more resources.`,
  enableCloudflare: `Allow Cloudflare IP's to connect to your service and relay the true client IP to XUI.`,
  legacyPlaylistUrl: `Rewrite get.php requests to the new playlist URL.`,
  legacyXmlTvUrl: `Rewrite xmltv.php requests to the new epg URL.`,
  legacyPanelApi: `Rewrite panel_api.php requests to the new XUI Player API.`,
  duplicateStreamsInLegacyApps: `XUI was the first to add multiple categories, which means most apps don't support it. The default behaviour of the API is to show the item once when ALL is requested, however apps tend to request all streams then filter them into categories themself. This option will change the default behaviour to show the stream duplicated for each additional category, therefore the stream shows correctly in each category. The downside is that when searching or displaying All category, the stream will be show up multiple times.`,
  enableEpgApi: `Enable EPG API in the Admin interface.`,
  appendExtraData: `Modify the incoming EPG data with extra data such as the name of the event.`,
  daysToFetch: `How many days to fetch when grabbing EPG data.`,
  daysToKeep: `How many days to keep when storing EPG data.`,
  adminStreamingIps: `Allowed IP's to access streaming using the Live Streaming Pass. Separate each IP with a comma.`,
  apiIps: `Allowed IP's to access the XUI Admin API. Separate each IP with a comma.`,
  apiPassword: `Password required to access the XUI Admin API. Leave blank to use IP whitelist only.`,
  disableEpgDownloadLine: `Enable to disallow EPG downloads in XMLTV format.`,
  disableEpgDownloadRestreamer: `Enable to disallow EPG downloads in XMLTV format.`,
  disablePlaylistDownloadLine: `Enable to remove the ability for lines to download their HLS / device playlists.`,
  disablePlaylistDownloadRestreamer: `Enable to remove the ability for lines to download their HLS / device playlists.`,
  disablePlayerApi: `Enable to stop Android Apps / Smart TV's from accessing the API.`,
  disableEnigma2Api: `Enable to stop Enigma devices from connecting.`,
  disableMinistraApi: `Enable to stop MAG devices from connecting.`,
  verifyHosts: `Verify domain names and IP's against allowed hosts in the database. This will include server IP's, domains and reseller DNS's.`,
};

const settingStreamingTooltipMessage: TooltipMessage = {
  enableIspLock: `Enable / Disable ISP lock globally.`,
  enableAsnLock: `Enable / Disable ASN lock globally.`,
  disableMpegTsOutput: `Disable MPEG-TS for all clients and devices.`,
  allowRestreamersMpegTs: `Override to allow restreamers to still use MPEG-TS while it is disabled.`,
  disableHlsOutput: `Disable HLS for all clients and devices.`,
  allowRestreamersHls: `Override to allow restreamers to still use HLS while it is disabled.`,
  disableRtmpOutput: `Disable RTMP for all clients and devices.`,
  allowRestreamersRtmp: `Override to allow restreamers to still use RTMP while it is disabled.`,
  caseSensitiveLines: `Case sensitive username and password.`,
  overrideCountryWithFirst: `Override country with first connected.`,
  encryptHlsSegments: `Encrypt all HLS streams with AES-256 while they are being watched. This will increase CPU usage but is more secure and packets cannot be analysed.`,
  disallowEmptyUa: `Don't allow connections from clients with no user-agent.`,
  vodBitrateBuffer: `Additional buffer when streaming VOD.`,
  vodLimitAt: `Limit VOD after x% has streamed. Use 0 to limit immediately and 100 to turn off entirely.`,
  autoKickHours: `Automatically kick connections that are online for more than X hours.`,
  useDomainNameInApi: `Use domain name in lists.`,
  encryptPlaylists: `Encrypt line credentials in playlist files.`,
  encryptRestreamerPlaylists: `Encrypt line credentials in restreamer playlist files.`,
  restrictionsOnPlaylistsAndEpg: `Verify user-agent, IP restrictions, ISP and country restrictions before allowing playlist / EPG download. If disabled the playlist can be downloaded from any IP but restrictions still apply to streams themselves.`,
  ignoreInvalidCredentials: `Enabling this option will make authentication completely ignore a connection if the username and password are incorrect, this means the flood limit won't activate but will also quickly close an invalid connection much faster without loading any XUI functions or classes. If you have a lot of throughput, enabling this may save you some CPU usage.`,
  clientPrebuffer: `How much data in seconds will be sent to the client when connecting to a stream. Larger values will create larger prebuffers.`,
  restreamerPrebuffer: `How much data in seconds will be sent to the client when connecting to a stream. Larger values will create larger prebuffers.`,
  loadBalancing: `Preferred method of load balancing connections.`,
  restreamerBypassProxy: `Route restreamers directly to load balancers instead of through proxies where proxy service has been enabled.`,
  channelSortingType: `Preferred method of channel sorting in playlists and apps.`,
  sortVodByDate: `Change default sorting for VOD to be by date added descending, showing newest first. This only works as expected if Channel Sorting Type is set to Bouquet, otherwise VOD order will be overwritten.`,
  useNginxBuffer: `Sets the proxy buffering for this connection. Setting this to “no” will allow unbuffered responses suitable for Comet and HTTP streaming applications. Setting this to “yes” will allow the response to be cached.`,
  logClientIsps: `Grab ISP information for each client that connects and store it in the database.`,
  onlineCapacityInterval: `Interval at which to check server activity for connection limits.`,
  monitorConnectionStatus: `Monitor PHP's connection_status() return while delivering stream and VOD content. This will abort the connection correctly when CONNECTION_NORMAL is not returned.`,
  autoRestartCrashedPhpFpm: `Run a cron that restarts PHP-FPM if it crashes and errors are found.`,
  killRogueFfmpegPids: `When enabled, ffmpeg PID's will be scanned every minute for streams that shouldn't be live and killed accordingly. This will also run when starting a stream to ensure any running instances are sufficiently removed.`,
  redirectExpiration: `How long in seconds before a redirect from the main server to a load balancer will expire. If you get a lot of TOKEN_EXPIRED errors in your logs, increase this.`,
  hlsReadNative: `Force Read Native on for all HLS streams. Turn this off if you'd rather set it manually for each applicable stream.`,
  readBufferSize: `Amount of buffer to use when reading files in chunks." class="tooltip text-secondary far fa-circle`,
  redisConnectionSyncTimer: `Time between runs of the Redis Connection Sync script.`,
  allowCdnForwarding: `Allow X-Forwarded-For to forward the correct IP to XUI and turn off path encryption in favour of token based encryption when streaming.<br/>To set up allowed IP's for forwarding, follow the CDN setup tutorial on the Billing Panel. Advanced usage only.`,
  maxFailures: `How many failures before exiting stream monitor. For example, if set to 3 then the stream monitor will allow 3 failures, break, then the monitor will be restarted by the streams Cron at the next minute marker. If set to 0 streams will continue to restart forever.`,
  installOff: `When a client disconnects from an on-demand stream, check the current total connections for that stream and turn it off if nobody is watching.`,
  exitOnFailure: `If an on-demand stream fails to start, do not retry, cancel the stream and disconnect the client. It will retry on the next connection but will ensure it doesn't sit forever trying to start and using source connections for example.`,
  waitTimeout: `How long should the client wait for an on-demand stream to start. After this time has elapsed, the connection will close. This will also apply to normal streams while they're starting or not yet available.`,
  requestPrebuffer: `When you request a stream on-demand, ask the provider to send a prebuffer so the stream starts quicker. This will only work if your provider is using XUI. The prebuffer will mean your source could be 10 seconds or so behind, but it will load significantly quicker.
  
  On - URL means automatically append ?prebuffer=1 to the URL, On - Header sends the prebuffer request as a header instead. Header requests are only accepted when requesting from XUI v1.4.4+`,
  balanceAsLive: `Treat on-demand servers equal to live servers when load balancing, this will mean an on-demand server will be started up to load balance even if there's already a server live for that stream.`,
  enableScanner: `Periodically probe on-demand streams to check their current status, resolution, codecs and FPS, as well as response time and log any errors incurred to the database. Streams will scan one at a time, per server, to avoid any connection issues.`,
  scanTime: `How often to scan a stream in seconds.`,
  maxProveTime: `How many seconds to probe the stream for before cancelling.`,
  keepLogsFor: `How many seconds to keep logs for. This will affect your Up and Down statistics in the logs page. Default is 604800, 1 week.`,
  maxMovieEncodes: `Maximum number of movies to encode at once, per server. If all of your content is symlinked, you can set this to a higher number, otherwise set it to how many encodes your servers can realistically perform at once without overloading.`,
  maxChannelEncodes: `Maximum number of created channels to encode at once, per server. It's best to set this to 1 unless you're symlinking all created channels.`,
  queueLoopTimer: `How long to wait between queue checks. If you're symlinking content you should set this to 1 second.`,
  segmentDuration: `Duration of individual segments when using HLS. This cannot be guaranteed due to keyframes, but should work on most streams.`,
  listSize: `Number of segments in the HLS playlist.`,
  deleteThreshold: `How many old segments to keep when generating HLS playlist. Lowering this will lower RAM usage but it's good to keep a buffer for connecting clients. A 30 second prebuffer for example would need 3 x 10 second segments to work.`,
  maxSegmentWaitTime: `Maximum amount of seconds to wait for a new segment to be created before exiting the clients connection due to having no new data that can be delivered.`,
  analysisDuration: `How long to analyse a stream, longer duration will increase sample accuracy. 5,000,000 microseconds = 5s.`,
  probeSize: `Amount of data to be probed in bytes.`,
  ffmpegVersion: `Which version of FFMPEG to use for movies, created channels and normal streams.
  
  v4.0 - Legacy version from 2018, shipped with XUI originally.
  v4.3.2 & v4.4 compiled by XUI.one with all libraries from v4.0 plus many more. Compatible with DASH and NVENC.`,
  ffmpegSegmentType: `Whether to use -f segment or -f hls when processing streams. -f hls is designed to be used with HLS streaming so should perform better. Using segment will disable delete threshold option. Segment will be used by default for on-demand streams to increase speed.`,
  ffmpegShowWarnings: `Instruct FFMPEG to save warnings to stream errors table. Turning this off will save only errors instead.`,
  ignoreKeyframes: `Allow segments to start on frames other than keyframes. This improves behavior on some players when the time between keyframes is inconsistent, but may make things worse on others, and can cause some oddities during startup with blank screen until video kicks in.`,
  dtsUseFfmpegV4: `Automatically switch to legacy FFMPEG v4.0 for streams with DTS audio, in some cases this has been known to fix desynchronised audio. Generate PTS needs to be turned off for this to function.`,
  loopbackStreamsViaPhp: `Don't use FFMPEG to handle loopback streams, have PHP read them directly and generate HLS.`,
  restartOnAudioLoss: `Restart stream periodically if no audio is detected.`,
  priorityBackup: `Switch back to the first source if it is detected as working again.`,
  probeDuration: `How long to wait after analyze duration before cancelling stream probe.`,
  streamFailureSleep: `How long to wait in seconds after a stream start failure before starting again.`,
  fpsStartDelay: `How long in seconds to wait before checking if FPS drops below threshold.`,
  fpsCheckType: `Whether to use progress info after the start delay to determine real FPS or probe the segment to return avg_frame_rate.`,
  probeViaApi: `Use API calls to probe sources from XUI servers.`,
  streamDownVideo: `Show this video when a stream isn't on air.`,
  bannedVideo: `Show this video when a banned line accesses a stream.`,
  expiredVideo: `Show this video when an expired line accesses a stream.`,
  expiringVideo: `Show this video once per day 7 days prior to a line expiring.`,
  secondIpConnectedVideo: `Show this video when a client connects but gets denied to already watching on another IP.`,
  allowedCountries: `Select individual countries to allow. This is a global geo-lock, selet All Countries to allow everyone.`,
};

const settingMAGTooltipMessage: TooltipMessage = {
  showAllCategories: `Show All category on MAG devices.`,
  alwaysEnabledSubtitles: `Force subtitles to be enabled at all times.`,
  playbackLimit: `Show warning message and stop stream after X hours of continuous playback.`,
  defaultAspectRatio: `Set the default aspect ratio of streams. Fit being the recommended option.`,
  defaultThemeType: `Whether to use Modern or Legacy theme by default for newly added devices.`,
  legacyTheme: `Default Ministra theme to be used by MAG devices.`,
  legacyUrlRedirect: `Redirect /c to Ministra folder using symlinks. This will allow legacy devices to access the Ministra portal using the old address, however it isn't recommended for security purposes. Root access is required so this will action within the next minute during the cron run.`,
  keepUrlExtension: `Keep extension of live streams, timeshift and VOD. Some older devices can't determine it for themselves and use the extension to select the playback method.`,
  disableSsl: `Force MAG's to use non-SSL URL's, you should think about removing support for old MAG devices that don't support newer SSL protocols rather than disabling this.`,
  loadChannelsOnStartup: `Load all channel listings on startup instead of when selecting a category. This may be useful for some legacy devices that don't adhere to Ministra standards.`,
  disableMagToken: `Disable verification of MAG token when streaming, reduces security but can have better compatibility.`,
  speedTestUrl: `URL to a file to download during speedtest on MAG devices.`,
  informationMessage: `Message to display when a user selects Information in My Account tab. Text entered should be in HTML format, although newlines will be converted to.`,
};

const settingWebPlayerTooltipMessage: TooltipMessage = {
  allowPlaylistDownload: `Allow clients to generate playlist URL's from the web player.`,
  allowBouquetOrdering: `Allow clients to reorder their bouquets from the web player.`,
  hideIncompatibleStreams: `Hide streams that aren't compatible with most browsers, this will limit streams to H264 and AV1 mostly. This option will also hide streams and movies that aren't available.`,
  markHevcAsCompatible: `Mark HEVC as compatible, there are some browsers such as Edge and Safari that support HEVC, however most mainstream browsers such as Firefox and Chrome do not.`,
  backgroundBlurPx: `Blur the background images by X pixels.`,
  backgroundOpacity: `Adjust the background image opacity. Default is 10%.`,
  extractSubtitles: `Automatically extract subtitles from movies and episodes while they're being processed. Allows for subtitles to be used in Web Player.`,
};

const settingLogsTooltipMessage: TooltipMessage = {
  activityLogs: `Activity logs are saved when an active connection is closed. This is useful information to keep and should be kept for as long as possible, however can build up if you have high throughput.`,
  clientLogs: `Activity logs are saved when an active connection is closed. This is useful information to keep and should be kept for as long as possible, however can build up if you have high throughput.`,
  loginLogs: `Activity logs are saved when an active connection is closed. This is useful information to keep and should be kept for as long as possible, however can build up if you have high throughput.`,
  streamErrorLogs: `Activity logs are saved when an active connection is closed. This is useful information to keep and should be kept for as long as possible, however can build up if you have high throughput.`,
  streamRestartLogs: `Activity logs are saved when an active connection is closed. This is useful information to keep and should be kept for as long as possible, however can build up if you have high throughput.`,
};

export {
  lineAdvancedTooltipMessage,
  streamAdvancedTooltipMessage,
  streamRTMPushTooltipMessage,
  streamServersTooltipMessage,
  settingGeneralTooltipMessage,
  settingSecurityTooltipMessage,
  settingApiTooltipMessage,
  settingStreamingTooltipMessage,
  settingMAGTooltipMessage,
  settingWebPlayerTooltipMessage,
  settingLogsTooltipMessage,
};
