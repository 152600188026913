/* eslint-disable react-hooks/exhaustive-deps */
import useRequest from '../../lib/hooks/useRequest';
import { deleteBouquet } from '../../lib/api';
import { useEffect } from 'react';
import { useBouquets } from './useBouquets';
import { useSetAtom } from 'jotai';
import { loadingAtom } from '../../store/jotai';

export const useDeleteBouquet = () => {
  const [_deleteBouquet, loading, _deleteBouquetRes, _deleteBouquetErr] = useRequest(deleteBouquet);
  const { getAllBouquets, getAllBouquetsRes } = useBouquets();
  const setLoading = useSetAtom(loadingAtom);

  useEffect(() => {
    if (_deleteBouquetRes) {
      setLoading(false);
      getAllBouquets();
    }
  }, [_deleteBouquetRes, getAllBouquets]);

  useEffect(() => {
    if (_deleteBouquetErr) {
      setLoading(false);
      console.log('_getAllBouquetsErr: ', _deleteBouquetErr);
    }
  }, [_deleteBouquetErr]);
  return {
    deleteBouquet: (data: unknown) => {
      setLoading(true);
      return _deleteBouquet(data);
    },
    deleteBouquetRes: getAllBouquetsRes,
    loading,
  };
};
