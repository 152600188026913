/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { banUser } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { useLines } from './useLines';
import { isAxiosError } from 'axios';

export const useBanLine = () => {
  const { getAllUsers } = useLines(false);
  const [_banUser, , _banUserRes, _banUserErr] = useRequest(banUser);

  // success banning of the user
  useEffect(() => {
    if (_banUserRes) {
      getAllUsers();
    }
  }, [_banUserRes]);

  // checking for the error
  useEffect(() => {
    if (_banUserErr) {
      if (isAxiosError(_banUserErr)) {
        console.log('_banUserErrText: ', _banUserErr.response?.data.error);
        console.log('_banUserErrMessage: ', _banUserErr.response?.data.message);
      } else {
        console.log('_banUserErr: ', _banUserErr);
      }
    }
  }, [_banUserErr]);

//   return the banline function that recieves the username of the user
  return {
    banLine:_banUser,
    banUserError: _banUserErr,
    banUserRes: _banUserRes,
  };
};
