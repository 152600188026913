import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import { SettingsStreamingFormInput } from './SettingsStreamingForm';

interface Props {
  control: Control<SettingsStreamingFormInput>;
  watchedName: keyof SettingsStreamingFormInput;
  value: string;
  onChange: (value: string) => void;
}

const CustomVideo = ({ control, watchedName, value, onChange }: Props) => {
  const isEnabled = useWatch({ control, name: watchedName });
  return (
    <div className='form-item-two-col-card-item'>
      <input
        type='text'
        className='form-item-two-col-card-item-input w-full text-[16px]'
        autoComplete='off'
        placeholder='Leave blank to use default XUI video'
        disabled={!isEnabled}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  );
};

export default CustomVideo;
