/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import FormTab from '../common/FormTab';
import FormTitle from '../common/FormTitle';
import { TabData } from '../common/Tab';
import LineBouquetsForm from './bouquets/LineBouquetsForm';
import LineDetailsForm from './details/LineDetailsForm';
import { User } from '../../types/model/user';
import { useNavigate } from 'react-router-dom';
import { useInsertLine } from '../../hooks/user/useInsertLine';
import { cn } from '../../utils/tw-merge';
import { useSetAtom } from 'jotai';
import { loadingAtom } from '../../store/jotai';
import LineSecurity from './security/LineSecurityTab';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'Details',
    tabComponent: LineDetailsForm,
  },
  {
    id: 2,
    tabTitle: 'Bouquets',
    tabComponent: LineBouquetsForm,
  },
  {
    id: 3,
    tabTitle: 'Security',
    tabComponent: ({ ...props }) => LineSecurity({ ...props }),
  },
];

interface Props {
  className?: string;
}

const AddLineForm = ({ className }: Props) => {
  const navigate = useNavigate();
  const { insertLine, insertLineRes, insertLineErr } = useInsertLine();
  const [lineData, setLineData] = useState<User>();
  const setLoading = useSetAtom(loadingAtom);

  useEffect(() => {
    if (insertLineErr || insertLineRes) {
      setLoading(false);
    }
    if (insertLineRes) {
      navigate('/user/manage-lines');
    }
  }, [insertLineRes, navigate, insertLineErr]);

  const submitLineData = async (data: User) => {
    insertLine(data);
  };

  return (
    <div className={cn('pt-[50px] w-[660px] max-w-full my-[2px] mx-auto', className)}>
      <FormTitle>Add Line</FormTitle>
      <FormTab
        data={tabData}
        currentTab={tabData[0].id}
        formData={lineData}
        setFormData={setLineData}
        submitFormData={submitLineData}
      />
    </div>
  );
};

export default AddLineForm;
