import { AttentionSvg } from '../../assets/svg';
import { DashboardSettingItem } from '../../types/settingData';

export const dashboardSettingItems: DashboardSettingItem[] = [
  {
    title: 'Need Your Aention!',
    content: 'Find breaked link...',
    icon: AttentionSvg,
    background: 'linear-gradient(90deg, #6f45e9 0%, #b538f7 100%)',
    color: '#5c32c2',
  },
  {
    title: 'Need Your Aention!',
    content: 'Find breaked link...',
    icon: AttentionSvg,
    background: 'linear-gradient(90deg, #5AE485 0%, #05B98A 100%)',
    color: '#199B47',
  },
  {
    title: 'Need Your Aention!',
    content: 'Find breaked link...',
    icon: AttentionSvg,
    background: 'linear-gradient(90deg, #1A7CFF 0%, #63AAFD 100%)',
    color: '#1962C1',
  },
  {
    title: 'Need Your Aention!',
    content: 'Find breaked link...',
    icon: AttentionSvg,
    background: 'linear-gradient(90deg, #FD79B1 0%, #FCB979 100%)',
    color: '#C66788',
  },
];
