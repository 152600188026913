/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import { deleteStream } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { useStreams } from './useStreams';
import { isAxiosError } from 'axios';
import { useCategories } from '../category/useCategories';
import { useBouquets } from '../bouquet/useBouquets';
import { useAtomValue, useSetAtom } from 'jotai';
import { loadingAtom, streamsAtom } from '../../store/jotai';

export const useDeleteStream = () => {
  const { getAllStreams, loading: loadingStreams } = useStreams(false);
  const streams = useAtomValue(streamsAtom);
  const setLoading = useSetAtom(loadingAtom);

  const { getAllCategories, getAllCategoriesRes } = useCategories();
  const { getAllBouquets, getAllBouquetsRes } = useBouquets();
  const completed = useMemo(
    () => (getAllCategoriesRes && getAllBouquetsRes && !loadingStreams) as boolean,
    [getAllCategoriesRes, getAllBouquetsRes],
  );
  const [_deleteStream, , _deleteStreamRes, _deleteStreamErr] = useRequest(deleteStream);

  useEffect(() => {
    if (_deleteStreamRes) {
      getAllStreams(streams.CurrentPage, streams.CountPerPage);
      getAllCategories();
      getAllBouquets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_deleteStreamRes]);
  useEffect(() => {
    if (_deleteStreamErr) {
      setLoading(false);
      if (isAxiosError(_deleteStreamErr)) {
        console.log('_deleteStreamErrText: ', _deleteStreamErr.response?.data.error);
        console.log('_deleteStreamErrMessage: ', _deleteStreamErr.response?.data.message);
      } else {
        console.log('_deleteStreamErr: ', _deleteStreamErr);
      }
    }
  }, [_deleteStreamErr]);
  useEffect(() => {
    if (completed) {
      setLoading(false);
    }
  }, [completed]);
  return {
    deleteStream: (data: unknown) => {
      setLoading(true);
      return _deleteStream(data);
    },
    completed,
  };
};
