import React, { useEffect, useState } from 'react';
import { Column, useSortBy, useTable } from 'react-table';
import { SortAscSvg, SortDescSvg, SortSvg } from '../../assets/svg';
import { Popover } from 'react-tiny-popover';
import CheckBox from './CheckBox';
import ModalWarning from './ModalWarning';
import { FaCaretDown } from 'react-icons/fa';

export interface Props<T extends object> {
  columns: Column<T>[];
  data: T[];
  totalRecords?: number;
  checks?: string[];
  initChecks?: boolean;
  options?: string[];
  setSelectedItemsCount?: React.Dispatch<React.SetStateAction<number>>;
  getRequestHandlerByOption?: (option: string) => (ids: number[], checkAll: boolean) => void;
  type?: 'Stream' | 'User' | 'Connection';
  onSort?: (columnId: string, isDesc: boolean) => void;
}

const ManageTable = <T extends object>({
  columns,
  data,
  totalRecords,
  checks,
  initChecks = false,
  options,
  setSelectedItemsCount,
  getRequestHandlerByOption,
  type,
  onSort,
}: Props<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      manualSortBy: !!onSort,
    },
    useSortBy,
  );

  useEffect(() => {
    onSort && onSort(sortBy[0]?.id, sortBy[0]?.desc ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [isOptionsPopoverOpen, setIsOptionsPopoverOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>();
  const [selected, setSelected] = useState<number[]>([]);
  const [checkAll, setCheckAll] = useState<boolean>(false);

  useEffect(() => {
    if (initChecks) {
      setSelected([]);
      setCheckAll(false);
    }
  }, [initChecks]);
  useEffect(() => {
    setSelectedItemsCount && setSelectedItemsCount(checkAll ? totalRecords ?? 0 : selected.length);
  }, [checkAll, selected.length, setSelectedItemsCount, totalRecords]);

  return (
    <div>
      {checks && (
        <>
          <div className='flex gap-[5px] items-center'>
            <Popover
              isOpen={isPopoverOpen}
              positions={['bottom']}
              onClickOutside={() => {
                setIsPopoverOpen(false);
              }}
              containerClassName='left-[74px]'
              content={
                <div className='bg-dark p-[3px] [box-shadow:0px_4px_4px_rgba(0,0,0,0.25)]'>
                  {checks.map(check => (
                    <div
                      className='cursor-pointer p-1 hover:bg-table-odd-row'
                      onClick={() => {
                        switch (check) {
                          case 'Check All':
                            setCheckAll(true);
                            break;
                          case 'Uncheck All':
                            setCheckAll(false);
                            setSelected([]);
                            break;
                          case 'Check All On Page':
                            setCheckAll(false);
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            setSelected(rows.map((row: any) => row.original.id));
                            break;
                          default:
                            break;
                        }
                        setIsPopoverOpen(false);
                      }}
                      key={check}
                    >
                      {check}
                    </div>
                  ))}
                </div>
              }
            >
              <div
                className='w-fit flex items-center'
                onClick={() => {
                  setIsPopoverOpen(!isPopoverOpen);
                }}
              >
                <CheckBox
                  value={checkAll || selected.length > 0}
                  className={`checkbox ${checkAll || selected.length > 0 ? 'selected' : ''}`}
                  onClick={() => {
                    return;
                  }}
                />
                <FaCaretDown />
              </div>
            </Popover>
            {(selected.length > 0 || checkAll) && (
              <Popover
                isOpen={isOptionsPopoverOpen}
                positions={['bottom']}
                containerStyle={{
                  top: '-58px',
                  left: '98px',
                }}
                onClickOutside={() => {
                  setIsOptionsPopoverOpen(false);
                }}
                content={
                  <div className='bg-dark p-[3px] [box-shadow:0px_4px_4px_rgba(0,0,0,0.25)]'>
                    {options &&
                      options.map(option => (
                        <div
                          className='cursor-pointer p-1 hover:bg-table-odd-row'
                          onClick={() => {
                            setSelectedOption(option);
                          }}
                          key={option}
                        >
                          {option}
                        </div>
                      ))}
                  </div>
                }
              >
                <button
                  className='py-[6px] px-[30px] rounded-2xl bg-dark text-[16px] cursor-pointer [box-shadow:0px_2px_13px_1px_#1e1e3f]'
                  onClick={() => {
                    setIsOptionsPopoverOpen(!isOptionsPopoverOpen);
                  }}
                >
                  Options
                </button>
              </Popover>
            )}
          </div>
          <ModalWarning
            isOpen={!!selectedOption}
            onClose={() => {
              setSelectedOption(undefined);
            }}
            handleCancel={() => {
              setSelectedOption(undefined);
            }}
            handleOk={async () => {
              if (!selectedOption) return;
              setSelectedOption(undefined);
              getRequestHandlerByOption &&
                getRequestHandlerByOption(selectedOption)(selected, checkAll);
            }}
          >
            <div>
              Are you sure you want to {selectedOption}{' '}
              {selected.length > 1 || checkAll
                ? `these ${type?.toLowerCase()}s`
                : `this ${type?.toLowerCase()}`}
              ?<br />A number of {checkAll ? totalRecords : selected.length} streams are affected.
            </div>
          </ModalWarning>
        </>
      )}
      <div className='flex h-fit gap-[15px]'>
        {checks && (
          <div className='flex flex-col justify-between mt-[90px] mb-7'>
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              rows.map((row: any) => {
                return (
                  <CheckBox
                    className={`${
                      selected.includes(row.original.id) || checkAll ? 'selected' : ''
                    } checkbox`}
                    key={row.id}
                    onClick={() => {
                      setSelected(selected => {
                        if (selected.includes(row.original.id)) {
                          return selected.filter(id => id !== row.original.id);
                        } else {
                          return [...selected, row.original.id];
                        }
                      });
                    }}
                    value={checkAll || selected.includes(row.original.id)}
                  />
                );
              })
            }
          </div>
        )}
        <table
          {...getTableProps()}
          className='my-3 mx-0 w-full min-w-[900px] text-center border-collapse'
        >
          <thead className='bg-card'>
            {headerGroups.map(headerGroup => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps()['key']}
                className='w-full border-b border-solid border-element-card rounded-lg [box-shadow:inset_0px_4px_4px_rgba(0,0,0,0.25)]'
              >
                {headerGroup.headers.map(column => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={column.getHeaderProps()['key']}
                    >
                      <div className='py-[7px] px-0 flex justify-center gap-1 text-[18px]'>
                        {column.render('Header')}
                        {column.id !== 'actions' && !column.disableSortBy && (
                          <>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <SortDescSvg />
                              ) : (
                                <SortAscSvg />
                              )
                            ) : (
                              <SortSvg />
                            )}
                          </>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="before:content-[''] before:block before:h-4">
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={row.getRowProps()['key']}
                  className='bg-card odd:bg-table-odd-row'
                >
                  {row.cells.map(cell => (
                    <td
                      {...cell.getCellProps()}
                      key={cell.getCellProps()['key']}
                      className='h-16 text-[13px]'
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageTable;
