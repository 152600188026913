import React, { useEffect } from 'react';

interface Props {
  data: TabData[];
  currentTab?: number;
  onChangeTab?: (id: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFormData?: React.Dispatch<React.SetStateAction<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitFormData?: (data: any) => void;
  isAllowTabClick?: boolean;
  role?: 'add' | 'edit';
  className?: string;
  tabTitleBarStyle?: string;
  tabTitleStyle?: string;
  tabTitleActiveStyle?: string;
  tabContentStyle?: string;
}

export interface TabData {
  id: number;
  tabTitle: string;
  tabComponent: ({
    className,
    setVisibleTab,
    formData,
    setFormData,
    submitFormData,
    role,
  }: {
    className?: string;
    setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formData?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFormData?: React.Dispatch<React.SetStateAction<any>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submitFormData?: (data: any) => void;
    role?: 'add' | 'edit';
  }) => JSX.Element;
}

const Tab = ({
  data,
  className,
  currentTab,
  onChangeTab,
  formData,
  setFormData,
  submitFormData,
  isAllowTabClick = true,
  role = 'add',
  tabTitleBarStyle,
  tabTitleStyle,
  tabTitleActiveStyle,
  tabContentStyle,
}: Props) => {
  const [visibleTab, setVisibleTab] = React.useState(currentTab ?? data[0].id);

  const handleChangeTab = (id: number) => {
    if (isAllowTabClick) {
      setVisibleTab(id);
      if (onChangeTab) onChangeTab(id);
    }
  };

  useEffect(() => {
    currentTab && setVisibleTab(currentTab);
  }, [currentTab]);

  return (
    <div className={className}>
      <ul className={tabTitleBarStyle}>
        {data.map(item => (
          <li
            key={item.id}
            onClick={() => handleChangeTab(item.id)}
            className={
              visibleTab === item.id ? `${tabTitleStyle} + ${tabTitleActiveStyle}` : tabTitleStyle
            }
          >
            {item.tabTitle}
          </li>
        ))}
      </ul>
      {data.map(
        item =>
          visibleTab === item.id && (
            <item.tabComponent
              key={item.id}
              className={tabContentStyle}
              setVisibleTab={setVisibleTab}
              formData={formData}
              setFormData={setFormData}
              submitFormData={submitFormData}
              role={role}
            />
          ),
      )}
    </div>
  );
};

export default Tab;
