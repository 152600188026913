import { intervalToDuration } from 'date-fns';

const intervalToDurationStr = (startInterval: number) => {
  let durationStr = '';

  const duration = intervalToDuration({ start: startInterval * 1000, end: new Date() });
 

  if (duration.years) durationStr += `${duration.years}y `;
  if (duration.months) durationStr += `${duration.months}mo `;
  if (duration.days) durationStr += `${duration.days}d `;
  if (duration.hours) durationStr += `${duration.hours}h `;
  if (duration.minutes) durationStr += `${duration.minutes ?? 0}m `;
  durationStr += `${duration.seconds ?? 0}s `;

  return durationStr.trim();
};

export default intervalToDurationStr;

