import React from 'react';
import { useNavigate } from 'react-router-dom';
import CategoriesForm from '../components/categories/CategoriesForm';
import PageTab from '../components/common/PageTab';
import { TabData } from '../components/common/Tab';
import AddCategoriesForm from '../components/categories/AddCategoriesForm';

  const tabData: TabData[] = [
    {
      id: 1,
      tabTitle: 'Add Categories',
      tabComponent: AddCategoriesForm,
    },
    {
      id: 2,
      tabTitle: 'Categories',
      tabComponent: CategoriesForm,
    },
  ];

const CategoriesPage = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = React.useState<number>(tabData[0].id);

  const onChangeTab = (id: number) => {
    setCurrentTab(id);
    if (id == 1) navigate('/management/categories');
  };

  return <PageTab data={tabData} currentTab={currentTab} onChangeTab={onChangeTab} />;
};
export default CategoriesPage;
