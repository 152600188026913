/* eslint-disable react-hooks/exhaustive-deps */
import { useAtomValue } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { loadingAtom } from '../store/jotai';

const LoadingLayout = ({ children }: { children: React.ReactNode }) => {
  const ref = useRef(null);
  const [progress, setProgress] = useState(0);
  const loading = useAtomValue(loadingAtom);
  const [wasLoading, setWasLoading] = useState(false);
  const [hideLoader, setHideLoader] = useState(false);
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (loading) {
      setWasLoading(true);
      setProgress(10);
      intervalId = setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress >= 90) {
            clearInterval(intervalId);
            return 90;
          }
          return oldProgress + 10;
        });
      }, 800);
    } else {
      if (wasLoading) {
        setWasLoading(false);
        setProgress(100);
        setTimeout(() => {
          setHideLoader(true);
          setTimeout(() => {
            setProgress(0);
            setHideLoader(false);
          }, 500);
        }, 500);
      } else {
        setProgress(0);
      }
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [loading]);
  return (
    <div>
      {!hideLoader && (
        <LoadingBar
          color='red'
          className='h-5'
          ref={ref}
          progress={progress}
          style={{
            width: `${progress}%`,
          }}
        />
      )}
      {children}
    </div>
  );
};

export default LoadingLayout;
