import React from 'react';
import { StateItem } from '../../types/stateData';
import { useAtomValue } from 'jotai';
import { serverStatsAtom } from '../../store/jotai';

interface Props {
  data: StateItem;
  borderColor: [string, string];
}

const DashboardStateItem = ({ data, borderColor }: Props) => {
  const serverStats = useAtomValue(serverStatsAtom);

  let value: number;
  switch (data.id) {
    case 'onlineConnections':
      value = serverStats.total_connections ?? -1;
      break;
    case 'activeLines':
      value = serverStats.connected_users ?? -1;
      break;
    case 'liveStreams':
      value = serverStats.total_running_streams ?? -1;
      break;
    case 'downStreams':
      value = (serverStats.total_streams ?? 0) - (serverStats.total_running_streams ?? 0);
      break;
    case 'networkInput':
      value = serverStats.input_speed ?? -1;
      break;
    case 'networkOutput':
      value = serverStats.output_speed ?? -1;
      break;
    default:
      value = -1;
  }

  return (
    <div
      className='w-full h-[189px] rounded-lg border-3 border-solid border-transparent flex flex-col justify-center items-center gap-5'
      style={{
        background: `padding-box linear-gradient(var(--middle-grey), var(--middle-grey)),
      border-box linear-gradient(to bottom, ${borderColor[0]}, ${borderColor[1]})
      `,
      }}
    >
      <div className='relative h-[101px] min-w-[123px] py-2 px-[11px] rounded-[100px] bg-placeholder-dark border-4 border-solid border-[#515a8b] flex justify-center items-center'>
        <p className='text-[64px]'>{value}</p>
        <p className='absolute bottom-2 text-[10px]'>{data.unit ?? ''}</p>
      </div>
      <div className='h-7 flex flex-row justify-start items-center gap-2'>
        <data.icon />
        <div
          className={`shrink-0 w-[6px] h-[6px] rounded-[50%] ${
            data.state ? 'bg-online-state-1' : 'bg-offline-state-1'
          }`}
        ></div>
        <div className='w-[79px] text-[14px]'>{data.title}</div>
      </div>
    </div>
  );
};

export default DashboardStateItem;
