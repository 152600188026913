import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageTab from '../components/common/PageTab';
import { TabData } from '../components/common/Tab';
import SettingsForm from '../components/settings/SettingsForm';

const SettingsPage = () => {
  const tabData: TabData[] = [
    {
      id: 1,
      tabTitle: 'Settings',
      tabComponent: SettingsForm,
    },
  ];

  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = React.useState<number>(tabData[0].id);

  const onChangeTab = (id: number) => {
    setCurrentTab(id);
    if (id == 1) navigate('/settings');
  };

  return <PageTab data={tabData} currentTab={currentTab} onChangeTab={onChangeTab} />;
};
export default SettingsPage;
