import {
  AddSvg,
  DashboardSvg,
  EditSvg,
  ExitSvg,
  ManagementSvg,
  SettingSvg,
  StreamsSvg,
  UserSvg,
} from '../../assets/svg';
import { MenuItem } from '../../types/menuData';
import { MdConnectWithoutContact } from "react-icons/md";

export const menuItems: MenuItem[] = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    icon: DashboardSvg,
    route: '/dashboard',
    type: 'link',
    subMenuItems: [
      {
        id: "connections",
        name: "Connections",
        route: '/dashboard/connections',
        type: 'link',
        icon: MdConnectWithoutContact,  
      }
    ]
  },
  {
    id: 'user',
    name: 'User',
    icon: UserSvg,
    route: '/user',
    type: 'sub',
    subMenuItems: [
      {
        id: 'addLine',
        name: 'Add Line',
        icon: AddSvg,
        route: '/user/add-line',
        type: 'link',
      },
      {
        id: 'manageLines',
        name: 'Manage Lines',
        icon: EditSvg,
        route: '/user/manage-lines',
        type: 'link',
      },
    ],
  },
  {
    id: 'streams',
    name: 'Streams',
    icon: StreamsSvg,
    route: '/streams',
    type: 'sub',
    subMenuItems: [
      {
        id: 'addStream',
        name: 'Add Stream',
        icon: AddSvg,
        route: '/streams/add-stream',
        type: 'link',
      },
      {
        id: 'importStreams',
        name: 'Import Stream',
        icon: AddSvg,
        route: '/streams/import-streams',
        type: 'link',
      },
      {
        id: 'manageStreams',
        name: 'Manage Streams',
        icon: EditSvg,
        route: '/streams/manage-streams',
        type: 'link',
      },
    ],
  },
  {
    id: 'management',
    name: 'Management',
    icon: ManagementSvg,
    route: '/management',
    type: 'sub',
    subMenuItems: [
      {
        id: 'categories',
        name: 'Categories',
        icon: AddSvg,
        route: '/management/categories',
        type: 'link',
      },
      {
        id: 'bouquets',
        name: 'Bouquets',
        icon: EditSvg,
        route: '/management/bouquets',
        type: 'link',
      },
    ],
  },
];

export const settingMenuItems: MenuItem[] = [
  {
    id: 'settings',
    name: 'Settings',
    icon: SettingSvg,
    route: '/settings',
    type: 'link',
  },
  {
    id: 'logout',
    name: 'Log Out',
    icon: ExitSvg,
    route: '/',
    type: 'link',
  },
];
