import React from 'react';
import DateTime from 'react-datetime';

interface Props {
  className?: string;
  disabled?: boolean;
  value: Date;
  onChange: (value: Date) => void;
}

const DateTimePicker = ({ className, disabled = false, value, onChange }: Props) => {
  return (
    <div className={className}>
      <DateTime
        inputProps={{
          className:
            'w-[250px] bg-dark text-[20px] py-[7px] px-5 rounded-lg text-form-content disabled:text-secondary disabled:bg-dark-grey',
          disabled,
        }}
        // YYYY-MM-DD
        dateFormat='DD-MM-YYYY'
        value={value}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(data: any) => onChange(data._d)}
      />
    </div>
  );
};

export default DateTimePicker;
