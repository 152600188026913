import React, { useEffect, useState } from 'react';
import FormTab from '../common/FormTab';
import FormTitle from '../common/FormTitle';
import { TabData } from '../common/Tab';
import CategoriesDetailsForm from './CategoriesDetailsForm';
import { ICategory as Category } from '../../types/model/category';
import { useInsertCategory } from '../../hooks/category/useInsertCategory';
import { cn } from '../../utils/tw-merge';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'Details',
    tabComponent: CategoriesDetailsForm,
  },
];

interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
}

const AddCategoryForm = ({ className, setVisibleTab }: Props) => {
  const [categoryData] = useState<Category>();

  const { insertCategory, completed } = useInsertCategory();

  useEffect(() => {
    if (completed) {
      setVisibleTab && setVisibleTab(2);
    }
  }, [completed, setVisibleTab]);
  const submitCategoryData = async (data: Category) => {
    insertCategory(data);
  };

  return (
    <div className={cn('w-[755px] max-w-full my-[2px] mx-auto', className)}>
      <FormTitle>Add Category</FormTitle>
      <FormTab
        data={tabData}
        formData={categoryData}
        currentTab={tabData[0].id}
        submitFormData={submitCategoryData}
      />
    </div>
  );
};

export default AddCategoryForm;
