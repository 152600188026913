/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { PrimaryFormButton } from '../common/Buttons';
import { useAtom } from 'jotai';
import { categoriesAtom } from '../../store/jotai';
import { getAllCategories, getChannelsByBouquet, getChannelsByCategory } from '../../lib/api';
import { Category } from '../../types/model/category';
import { cn } from '../../utils/tw-merge';
import { Stream } from '../../types/model/stream';
import FormFooter from '../common/FormFooter';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

interface Bouquet {
  id?: number;
  name?: string;
  streams: number[];
}

interface Props {
  isEdit?: boolean;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
  formData?: Bouquet;
  setFormData?: React.Dispatch<React.SetStateAction<Bouquet>>;
  submitFormData?: (data: Bouquet) => void;
}

const BouquetChannelsForm = ({
  isEdit = false,
  setVisibleTab,
  formData,
  setFormData,
  submitFormData,
}: Props) => {
  const { handleSubmit, getValues, setValue } = useForm<Bouquet>({
    defaultValues: {
      streams: formData?.streams || [],
    },
  });

  const [categories, setCategories] = useAtom(categoriesAtom);
  const [currentCategory, setCurrentCategory] = useState<Category>(categories[0]);
  useEffect(() => {
    getAllCategories().then(({ data }) => {
      setCategories(data.Records);
    });
  }, []);

  const handlePrev = () => {
    if (setVisibleTab) setVisibleTab(1);
  };
  // currently rendered channels in left side
  const [leftChannels, setLeftChannels] = useState<Stream[]>([]);
  // currently selected channels in left side
  const [lSelectChannels, setLSelectChannels] = useState<Stream[]>([]);

  useEffect(() => {
    if (currentCategory)
      getChannelsByCategory(currentCategory.id).then(({ data }) => {
        setLeftChannels(data.streams);
      });
  }, [currentCategory]);
  // currently rendered channels in right side
  const [rightChannels, setRightChannels] = useState<Stream[]>([]);
  // currently selected channels in right side
  const [rSelectChannels, setRSelectChannels] = useState<Stream[]>([]);

  useEffect(() => {
    if (formData?.id)
      getChannelsByBouquet(formData.id).then(({ data }) => {
        if (data.streams?.length > 0) {
          setRightChannels(data.streams);
        }
      });
  }, []);

  useEffect(() => {
    return () => {
      const data = getValues();
      if (setFormData) {
        setFormData(currentLine => ({
          ...currentLine,
          streams: data.streams,
        }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<Bouquet> = data => {
    if (submitFormData) {
      if (formData?.name?.length === 0) {
        toast.error('Please enter a name for the bouquet');
        setVisibleTab && setVisibleTab(1);
        return;
      }
      if (data.streams.length === 0) {
        toast.error('Please add channels to the bouquet');
        return;
      }
      // remove duplicates data.streams
      // data.streams = Array.from(new Set(data.streams));
      submitFormData({ ...formData, streams: data.streams });
    }
  };

  useEffect(() => {
    if (rightChannels.length > 0) {
      setValue(
        'streams',
        rightChannels.map(channel => Number(channel.id)),
      );
    }
  }, [rightChannels]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex gap-4 w-full my-4'>
        <div className='flex flex-col gap-2 w-1/4'>
          <div>Categories</div>
          <div className='h-[50vh] bg-white p-1 flex flex-col gap-1 overflow-y-auto'>
            {categories.map(category => (
              <div
                key={category.id}
                className={cn(
                  'text-black cursor-pointer hover:bg-[#99d9ea]',
                  currentCategory?.id === category.id ? 'bg-[#99d9ea]' : '',
                )}
                onClick={() => {
                  setCurrentCategory(category);
                }}
              >
                {category.name}
              </div>
            ))}
          </div>
        </div>
        <div className='flex flex-col gap-2 w-1/4'>
          <div>Channels of Category</div>
          <div className='h-[50vh] bg-white p-1 flex flex-col gap-1 overflow-y-auto'>
            {leftChannels.map(channel => (
              <div
                key={channel.id}
                className={cn(
                  'text-black cursor-pointer hover:bg-[#99d9ea]',
                  lSelectChannels.includes(channel) ? 'bg-[#99d9ea]' : '',
                )}
                onClick={() => {
                  if (lSelectChannels.includes(channel)) {
                    setLSelectChannels(lSelectChannels.filter(s => s.id !== channel.id));
                  } else {
                    if (!rightChannels.includes(channel))
                      setLSelectChannels([...lSelectChannels, channel]);
                    else toast.error('Channel already added to bouquet');
                  }
                }}
              >
                {channel.name}
              </div>
            ))}
          </div>
        </div>
        <div className='ml-2 flex flex-col justify-center gap-7'>
          <div className='border border-white rounded-xl p-4 flex flex-col gap-10'>
            <PrimaryFormButton
              className='w-full whitespace-nowrap'
              onClick={() => {
                setLSelectChannels(
                  lSelectChannels.length === leftChannels.length ? [] : leftChannels,
                );
              }}
            >
              {lSelectChannels.length === leftChannels.length ? 'Deselect All' : 'Select All'}
            </PrimaryFormButton>
            <PrimaryFormButton
              className='w-full'
              onClick={() => {
                if (lSelectChannels.length === leftChannels.length) {
                  // Move all
                  setRightChannels(lSelectChannels);
                } else {
                  // Move only selected
                  setRightChannels([...rightChannels, ...lSelectChannels.filter(s => !rightChannels.map(i => i.id).includes(s.id))]);
                }
                setLSelectChannels([]);
              }}
            >
              &gt;&gt;
            </PrimaryFormButton>
          </div>
          <div className='border border-white rounded-xl p-4 flex flex-col gap-10'>
            <PrimaryFormButton
              className='w-full'
              onClick={() => {
                setRightChannels(
                  rightChannels.filter(
                    _stream => !rSelectChannels.includes(_stream),
                  ),
                );
                setRSelectChannels([]);
              }}
            >
              Delete
            </PrimaryFormButton>
            <PrimaryFormButton
              className='w-full whitespace-nowrap'
              onClick={() => {
                setRightChannels([]);
                setRSelectChannels([]);
              }}
            >
              Delete All
            </PrimaryFormButton>
          </div>
        </div>
        <div className='flex flex-col gap-2 w-1/4'>
          <div>Channels of Bouquet</div>
          <div className='h-[50vh] bg-white p-1 flex flex-col gap-1 overflow-y-auto'>
            {rightChannels.map(channel => (
              <div
                key={channel.id}
                className={cn(
                  'text-black cursor-pointer hover:bg-[#99d9ea]',
                  rSelectChannels.includes(channel) ? 'bg-[#99d9ea]' : '',
                )}
                onClick={() => {
                  if (rSelectChannels.includes(channel)) {
                    setRSelectChannels(rSelectChannels.filter(s => s.id !== channel.id));
                  } else {
                    setRSelectChannels([...rSelectChannels, channel]);
                  }
                }}
              >
                {channel.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <FormFooter handlePrev={handlePrev} nextButtonTitle={isEdit ? 'Edit' : 'Add'} />
    </form>
  );
};

export default BouquetChannelsForm;
