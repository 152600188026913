import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import Multiselect from 'multiselect-react-dropdown';
import { StreamDetailsFormInput } from './StreamDetailsForm';
import { multiSelectStyle } from '../../../styles/reactMultiSelect';
import { useAtomValue } from 'jotai';
import { categoriesAtom } from '../../../store/jotai';
import { Category } from '../../../types/model/category';

export const getCategoriesOptions = (
  categories: Category[],
  categoryIds?: number[],
): Category[] => {
  return categories.filter(category => categoryIds?.includes(category.id));
};
interface Props {
  className?: string;
  field: ControllerRenderProps<StreamDetailsFormInput, 'categories'>;
}

const Categories = ({ className, field }: Props) => {
  const categories = useAtomValue(categoriesAtom);
  return (
    <div className={className}>
      <Multiselect
        id={'categories'}
        displayValue='name'
        className='form-item-select'
        options={categories}
        selectedValues={field.value}
        onSelect={field.onChange}
        onRemove={field.onChange}
        style={multiSelectStyle}
        showCheckbox
        avoidHighlightFirstOption
      />
    </div>
  );
};

export default Categories;
