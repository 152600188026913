import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from './SideBar';
import TopBar from './TopBar';
import { useCategories } from '../hooks/category/useCategories';
import { useBouquets } from '../hooks/bouquet/useBouquets';
import { useServerStats } from '../hooks/status/useServerStats';

const MainLayout = () => {
  const { getAllCategories } = useCategories();
  const { getAllBouquets } = useBouquets();
  useServerStats();

  useEffect(() => {
    getAllCategories();
    getAllBouquets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <TopBar />
      <div className='absolute inset-0 pt-[96px] pr-[0px] pb-[55px] pl-5 flex flex-row justify-between gap-6'>
        <SideBar />
        <div className='flex-auto overflow-y-auto [scrollbar-gutter:stable]'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
