import React from 'react';
import { cn } from '../../utils/tw-merge';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const FormTitle = ({ className, children }: Props) => {
  return <div className={cn('font-[500] text-[32px]', className)}>{children}</div>;
};

export default FormTitle;
