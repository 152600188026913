import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import moment from 'moment-timezone';
import { selectStyles } from '../../../styles/reactSelect';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const ServerTimeZone = ({ className, value, onChange }: Props) => {
  const [timezoneOptions, setTimezoneOptions] = useState<CommonSelectItem[]>([]);

  useEffect(() => {
    const timezones = moment.tz.names();
    const formattedOptions = timezones.map(tz => {
      const offset = moment.tz(tz).format('Z');
      const timezoneLabel = `${tz} [${moment.tz(tz).zoneAbbr()} ${offset}]`;
      return { value: tz, label: timezoneLabel };
    });

    setTimezoneOptions(formattedOptions);
  }, []);
  return (
    <div className={className}>
      <Select<CommonSelectItem>
        className='form-item-select'
        options={timezoneOptions}
        styles={selectStyles}
        value={timezoneOptions[timezoneOptions.findIndex(option => option.value === value)]}
        onChange={newValue => onChange(newValue?.value ?? '')}
      />
    </div>
  );
};

export default ServerTimeZone;
