import React from 'react';
import { CloudArrowDownSvg } from '../../assets/svg';
import { ChipsButton } from '../common/Buttons';

const DashboardHeader = () => {
  return (
    <div className='flex flex-row justify-between items-center'>
      <div className='w-full flex flex-row justify-end items-center gap-2'>
        <ChipsButton
          onClick={() => {
            console.log('clicked');
          }}
          height={32}
          color='#55556f'
        >
          <CloudArrowDownSvg />
          <p className='font-semibold text-[12px]'>.xlsx</p>
        </ChipsButton>
      </div>
    </div>
  );
};

export default DashboardHeader;
